define('es-frontend/components/photo-share-popup', ['exports', 'es-frontend/utils/get-error-msg', 'es-frontend/utils/twitter-text', 'es-frontend/utils/crop-img', 'es-frontend/components/abstract-share', 'es-frontend/utils/constants', 'moment'], function (exports, _getErrorMsg, _twitterText, _cropImg, _abstractShare, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SERVER_DATE = _constants.default.SERVER_DATE;


  var MAX_WIDTH = 580;

  exports.default = _abstractShare.default.extend({

    previewWidth: MAX_WIDTH,
    store: Ember.inject.service('store'),
    facebookService: Ember.inject.service('facebook'),
    segment: Ember.inject.service('segment'),
    ajaxService: Ember.inject.service('es-ajax'),
    img: null,
    imgURL: null,
    left: null,
    top: null,
    width: null,
    height: null,
    scale: null,
    textFocus: false,
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    selectedSocialQueueIds: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    queues_with_facebook: Ember.computed('social_qeueues', function () {
      var ret = this.get('social_queues');
      ret = ret ? ret.toArray() : [];
      ret.push(Ember.Object.create({ id: "-1", service: 'facebook', profile_name: 'Facebook' }));
      return ret;
    }),

    isTwitterOnly: Ember.computed('queues_with_facebook', 'selectedSocialQueueIds', function () {
      var selectedSocialQueueIds = this.get('selectedSocialQueueIds');
      if (selectedSocialQueueIds.length != 1) {
        return false;
      }
      var queue = this.get('queues_with_facebook').findBy('id', '' + selectedSocialQueueIds.get('firstObject'));
      return queue && (queue.get('service') == 'twitter' ? queue : null);
    }),

    hasLinkedIn: Ember.computed('queues_with_facebook', 'selectedSocialQueueIds', function () {
      var selectedSocialQueueIds = this.get('selectedSocialQueueIds');
      for (var i = 0; i < selectedSocialQueueIds.length; i++) {
        var queue = this.get('queues_with_facebook').findBy('id', '' + Ember.get(selectedSocialQueueIds, i));
        if (queue && queue.get('service') == 'linkedin') {
          return true;
        }
      }
      return false;
    }),

    //Only twitter and facebook are valid for this control
    validSocialQueues: Ember.computed('queues_with_facebook', function () {
      return this.get('queues_with_facebook').filter(function (queue) {
        var service = queue.get('service');
        return !queue.get('invalid_token') && (service == 'twitter' || service == 'facebook' || service == 'linkedin');
      });
    }),

    placeholder: Ember.computed('isTwitterOnly', function () {
      return 'Add your thoughts here' + (this.get('isTwitterOnly') ? ', use @ to tag a friend or enter a #hashtag.' : '');
    }),

    imgObserver: Ember.observer('img', function () {
      var _this = this;

      Ember.run.later(function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        Ember.$('#' + _this.elementId + 'text textarea').focus();
      });
    }),

    remaining: Ember.computed('queues_with_facebook', 'selectedSocialQueueIds', 'user_commentary', function () {
      var _this2 = this;

      var ret = Number.POSITIVE_INFINITY;
      var selectedSocialQueueIds = this.get('selectedSocialQueueIds');
      this.get('queues_with_facebook').forEach(function (socialQueue) {
        var id = parseInt(socialQueue.id);
        if (id <= 0 || selectedSocialQueueIds.indexOf(id) < 0) {
          return;
        }
        if (socialQueue.get('service') == 'twitter') {
          ret = Math.min(ret, 280 - _twitterText.default.getTweetLength(_this2.get('user_commentary') || ''));
        } else {
          ret = Math.min(ret, 1300 - _this2.get('user_commentary.length'));
        }
      });
      return Number.isFinite(ret) ? ret : '';
    }),

    reconnections: Ember.computed('queues_with_facebook', function () {
      var ret = [];
      this.get('queues_with_facebook').forEach(function (queue) {
        var service = queue.get('service');
        if (queue.get('invalid_token') && (service == 'twitter' || service == 'facebook' || service == 'linkedin')) {
          ret.push({
            service: queue.get('service'),
            profile_name: queue.get('profile_name')
          });
        }
      });
      return ret;
    }),

    maxLength: Ember.computed('queues_with_facebook', 'selectedSocialQueueIds', function () {
      var queues_with_facebook = this.get('queues_with_facebook');
      return this.get('selectedSocialQueueIds').reduce(function (maxLength, id) {
        var queue = queues_with_facebook.find(function (queue) {
          return queue.get('id') == id;
        });
        if (!queue) {
          return maxLength;
        }
        return queue.get('service') == 'twitter' ? 280 : maxLength;
      }, 1300);
    }),

    aspectRatio: Ember.computed('hasLinkedIn', function () {
      return this.get('hasLinkedIn') ? 1.86335 : 0;
    }),

    shareNative: function shareNative(socialQueue, user_commentary, photo) {
      return this.get('ajaxService').ajax({
        method: 'POST',
        url: '/v1/social_queues/' + socialQueue.id + '/queued_posts/share_photo.json',
        data: {
          queued_post: {
            user_commentary: user_commentary,
            photo: photo
          }
        }
      });
    },
    shareFacebook: function shareFacebook(user_commentary, photo) {
      var _this3 = this;

      var post = this.get('ajaxService').ajax({
        method: 'POST',
        url: '/other_shares',
        contentType: 'application/json',
        data: JSON.stringify({
          //image_url: photo,
          uploaded_image: photo,
          post_at: (0, _moment.default)().add(1, 'hours').format(SERVER_DATE),
          title: 'Photo Post',
          summary: user_commentary,
          network: 'facebook'
        })
      });
      return new Ember.RSVP.Promise(function (resolve, reject) {
        post.then(function (result) {
          _this3.get('facebookService').share(result.url, user_commentary).then(resolve, reject);
        }, reject);
      });
    },


    actions: {
      loadSocialQueues: function loadSocialQueues() {
        var _this4 = this;

        return this.get('store').findAll('social_queue').then(function (social_queues) {
          _this4.set('social_queues', social_queues);
        });
      },
      clearUpload: function clearUpload() {
        var _this5 = this;

        Ember.run.later(function () {
          if (_this5.isDestroying || _this5.isDestroyed) {
            return;
          }
          _this5.setProperties({
            scale: null,
            imgURL: null,
            img: null
          });
        });
      },
      shareNow: function shareNow() {
        var _this6 = this;

        Ember.run.later(function () {
          var user_commentary = _this6.get('user_commentary') || '';
          if (_this6.get('remaining') < 0) {
            _this6.get('toast').error('Message is too long');
            _this6.set('textFocus', true);
            return;
          }
          if (!_this6.get('selectedSocialQueueIds.length')) {
            _this6.get('toast').error('Please select a network to which to share.');
            return;
          }

          var img = _this6.get('img');
          var croppedImg = (0, _cropImg.default)(img, _this6.get('left'), _this6.get('top'), _this6.get('width'), _this6.get('height'), _this6.get('scale'), _this6.get('width') / _this6.get('scale'), _this6.get('height') / _this6.get('scale'));

          _this6.actions.close.call(_this6);

          var promises = [];
          _this6.get('selectedSocialQueueIds').forEach(function (socialQueueId) {
            var socialQueue = _this6.get('queues_with_facebook').findBy('id', socialQueueId.toString());
            if (socialQueue.get('service') == 'facebook') {
              promises.push(_this6.shareFacebook(user_commentary, croppedImg));
            } else {
              promises.push(_this6.shareNative(socialQueue, user_commentary, croppedImg));
            }
          });
          Ember.RSVP.all(promises).then(function () {
            _this6.get('toast').info('Photo Shared');
            _this6.get('segment').track('Photo Share', {
              user_email: _this6.get('user.email'),
              action: 'PhotoShare',
              social_queue_ids: _this6.get('selectedSocialQueueIds'),
              user_commentary: user_commentary
            });
          }, function (error) {
            _this6.get('toast').error((0, _getErrorMsg.default)(error) || 'Photo Share Failed');
          });
        });
      },
      close: function close() {
        this.setProperties({
          open: false,
          img: null,
          imgURL: null,
          left: null,
          top: null,
          width: null,
          height: null,
          scale: null,
          user_commentary: null
        });
      },
      callback: function callback() {
        return encodeURIComponent('?hash=' + encodeURIComponent('/client/share?url=' + encodeURIComponent(this.get('url'))));
      }
    }
  });
});