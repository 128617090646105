define('es-frontend/controllers/client/leaderboard', ['exports', 'moment', 'es-frontend/utils/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['leaderboardId', 'start', 'end', 'userId', 'tab', 'query'],
    esAjax: Ember.inject.service('es-ajax'),
    widthService: Ember.inject.service('width'),
    leaderboardId: null,
    start: null,
    end: null,
    userId: null,
    tab: 'points',
    query: null,
    showAll: false,
    maxResults: 100,

    selectedLeaderboard: null,
    scorecard: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.onScroll = this.onScroll.bind(this);
    },
    activate: function activate() {
      //I dunno if this is required - Cameron has asked that we do not create extra html elements when not needed.
      Ember.$(window).on('scroll', this.onScroll);
    },
    deactivate: function deactivate() {
      Ember.$(window).off('scroll', this.onScroll);
    },
    onScroll: function onScroll() {
      var _this = this;

      if (window.innerHeight + window.pageYOffset + 500 >= document.body.offsetHeight) {
        var maxResults = this.get('maxResults');
        var numPaged = this.get('pagedScorecards.length');
        var numScorecards = this.get('scorecards.length');
        if (numPaged < numScorecards && maxResults == numPaged) {
          window.clearTimeout(this.get('timeout'));
          this.set('timeout', window.setTimeout(function () {
            _this.set('maxResults', maxResults + 100);
          }, 100));
        }
      }
    },


    filteredScorecards: Ember.computed('scorecards', 'query', function () {
      var scorecards = this.get('scorecards');
      var query = (this.get('query') || '').toLowerCase();
      if (scorecards && query) {
        scorecards = scorecards.filter(function (scorecard) {
          return scorecard.name && scorecard.name.toLowerCase().indexOf(query) >= 0;
        });
      }
      return scorecards;
    }),

    pagedScorecards: Ember.computed('filteredScorecards', 'showAll', 'maxResults', function () {
      var filteredScorecards = this.get('filteredScorecards');
      if (!filteredScorecards) {
        return filteredScorecards;
      }
      if (this.get('showAll')) {
        //apply paging here - we only show up to a max value increase the max every time we scroll to the bottom...
        //step one is to detect hit bottom and increment the max value
        //we reset this value when things change too.
        return filteredScorecards.slice(0, this.get('maxResults'));
      }
      var index = filteredScorecards.indexOf(this.get('currentUserScorecard'));
      if (index < 6) {
        return filteredScorecards.slice(0, 8);
      }
      var a = 3;
      var b = index - 2;
      var c = index + 3;
      if (c > filteredScorecards.length) {
        a += c - filteredScorecards.length;
        c = filteredScorecards.length;
      }

      var ret = filteredScorecards.slice(0, a);
      ret.push.apply(ret, filteredScorecards.slice(b, c));
      return ret;
    }),

    rangeStr: Ember.computed('start', 'end', function () {
      var start = (0, _moment.default)(this.get('start'), _constants.default.VISIBLE_DATE);
      var end = (0, _moment.default)(this.get('end'), _constants.default.VISIBLE_DATE);
      return start.format('MMM DD YYYY') + ' - ' + end.format('MMM DD YYYY');
    }),

    leaderboardIdObserver: Ember.observer('leaderboardId', function () {
      var leaderboards = this.get('model.leaderboards');
      if (leaderboards && leaderboards.length) {
        this.set('selectedLeaderboard', leaderboards.findBy('id', this.get('leaderboardId')));
      }
    }),

    selectedLeaderboardObserver: Ember.observer('selectedLeaderboard', function () {
      if (!this.get('start')) {
        this.set('start', (0, _moment.default)().add(-30, 'day').format(_constants.default.VISIBLE_DATE));
      }
      if (!this.get('end')) {
        this.set('end', (0, _moment.default)().format(_constants.default.VISIBLE_DATE));
      }
      if (!this.get('selectedLeaderboard') && this.get('model.leaderboards.length')) {
        this.set('selectedLeaderboard', this.get('model.leaderboards.firstObject'));
        this.loadMetrics();
        return;
      }
      this.setProperties({
        leaderboardId: this.get('selectedLeaderboard.id') || null,
        scorecards: null,
        scorecard: null
      });
      if (this.get('model.leaderboards.length')) {
        this.loadMetrics();
      }
    }),

    userIdObserver: Ember.observer('userId', function () {
      var scorecards = this.get('scorecards');
      if (!scorecards) {
        return;
      }
      var userId = parseInt(this.get('userId'));
      var scorecard = userId == this.get('currentUserScorecard.user_id') ? this.get('currentUserScorecard') : scorecards.findBy('user_id', userId);
      this.set('scorecard', scorecard);
    }),

    scorecardObserver: Ember.observer('scorecard', function () {
      var userId = this.get('scorecard.user_id');
      this.set('userId', userId ? userId.toString() : null);
    }),

    loadMetrics: function loadMetrics() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        document.title = _this2.get('selectedLeaderboard.name') + ' Leaderboard - EveryoneSocial';
        var id = _this2.get('selectedLeaderboard.id');
        if (!id) {
          reject('No id specified!');
        }
        _this2.get('esAjax').ajax({
          url: '/leaderboard_v2/' + id + '.json',
          data: {
            start_date: _this2.get('start'),
            end_date: (0, _moment.default)(_this2.get('end'), _constants.default.VISIBLE_DATE).add(1, 'day').format(_constants.default.VISIBLE_DATE)
          }
        }).then(function (metrics) {
          var user = _this2.get('model.user');
          var currentUserId = parseInt(user.get('id'));
          var scorecards = metrics.scorecards || [];
          var currentUserScorecard = scorecards.findBy('user_id', currentUserId) || _this2.blankUserScorecard(user);
          var userId = _this2.get('userId') || (currentUserScorecard.score ? currentUserId.toString() : scorecards.length ? scorecards[0].user_id.toString() : null);
          var scorecard = userId == currentUserId ? currentUserScorecard : scorecards.findBy('user_id', parseInt(userId));
          _this2.setProperties({
            scorecards: scorecards,
            userId: userId,
            currentUserScorecard: currentUserScorecard,
            branded_pricelist: metrics.branded_pricelist,
            third_party_pricelist: metrics.third_party_pricelist,
            scorecard: scorecard,
            maxResults: 100
          });

          resolve();
        }, reject);
      });
    },
    blankUserScorecard: function blankUserScorecard(user) {
      return {
        accepted_content: 0,
        accepted_content_points: 0,
        email_clicks: 0,
        email_clicks_points: 0,
        email_shares: 0,
        email_shares_points: 0,
        //enterprise_client_id: enterprise_client_id,
        fb_clicks: 0,
        fb_clicks_points: 0,
        fb_comments: 0,
        fb_comments_points: 0,
        fb_groups_clicks: 0,
        fb_groups_clicks_points: 0,
        fb_groups_shares: 0,
        fb_groups_shares_points: 0,
        fb_likes: 0,
        fb_likes_points: 0,
        fb_reshares: 0,
        fb_reshares_points: 0,
        fb_shares: 0,
        fb_shares_points: 0,
        gp_clicks: 0,
        gp_clicks_points: 0,
        gp_shares: 0,
        gp_shares_points: 0,
        li_clicks: 0,
        li_clicks_points: 0,
        li_comments: 0,
        li_comments_points: 0,
        li_groups_clicks: 0,
        li_groups_clicks_points: 0,
        li_groups_shares: 0,
        li_groups_shares_points: 0,
        li_likes: 0,
        li_likes_points: 0,
        li_shares: 0,
        li_shares_points: 0,
        name: user.get('name'),
        profile_image_url: user.get('profile_image_url'),
        rank: 'Unranked',
        score: 0,
        suggested_content: 0,
        suggested_content_points: 0,
        tw_clicks: 0,
        tw_clicks_points: 0,
        tw_favorites: 0,
        tw_favorites_points: 0,
        tw_retweets: 0,
        tw_retweets_points: 0,
        tw_shares: 0,
        tw_shares_points: 0,
        user_id: user.get('id'),
        xi_clicks: 0,
        xi_clicks_points: 0,
        xi_comments: 0,
        xi_comments_points: 0,
        xi_likes: 0,
        xi_likes_points: 0,
        xi_shares: 0,
        xi_shares_points: 0
      };
    },


    actions: {
      dateRangeChanged: function dateRangeChanged(start, end) {
        if (!start) {
          this.get('toast').error('Please select a date range');
          return;
        }
        if (!end) {
          end = start;
        }
        this.setProperties({
          start: start,
          end: end,
          dateRangeOpen: false
        });
        this.loadMetrics();
      }
    }
  });
});