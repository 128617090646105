define('es-frontend/initializers/polyfill', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function (searchString) {
        return this.lastIndexOf(searchString, 0) === 0;
      };
    }
    if (!String.prototype.endsWith) {
      String.prototype.endsWith = function (searchString) {
        var index = this.length - searchString.length;
        return index >= 0 && this.indexOf(searchString, index) == index;
      };
    }
    if (!Object.assign) {
      Object.assign = function (target) {
        if (target === undefined || target === null) {
          throw new TypeError('Cannot convert first argument to object');
        }

        var to = Object(target);
        for (var i = 1; i < arguments.length; i++) {
          var nextSource = arguments[i];
          if (nextSource === undefined || nextSource === null) {
            continue;
          }
          nextSource = Object(nextSource);

          var keysArray = Object.keys(Object(nextSource));
          for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
            var nextKey = keysArray[nextIndex];
            var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
            if (desc !== undefined && desc.enumerable) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
        return to;
      };
    }
    if (!Array.prototype.findIndex) {
      Array.prototype.findIndex = function (callback) {
        if (typeof callback !== 'function') {
          throw new TypeError('callback must be a function');
        }
        var list = Object(this);
        // Makes sures is always has an positive integer as length.
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        for (var i = 0; i < length; i++) {
          if (callback.call(thisArg, list[i], i, list)) {
            return i;
          }
        }
        return -1;
      };
    }
    if (typeof Number.isFinite !== 'function') {
      Number.isFinite = function isFinite(value) {
        // 1. If Type(number) is not Number, return false.
        if (typeof value !== 'number') {
          return false;
        }
        // 2. If number is NaN, +∞, or −∞, return false.
        if (value !== value || value === Infinity || value === -Infinity) {
          return false;
        }
        // 3. Otherwise, return true.
        return true;
      };
    }
  }

  exports.default = {
    initialize: initialize
  };
});