define('es-frontend/components/search-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    queryService: Ember.inject.service('query'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('query', this.get('queryService.q'));
    },


    queryObserver: Ember.observer('queryService.q', function () {
      this.set('query', this.get('queryService.q'));
    }),

    openObserver: Ember.observer('open', function () {
      var _this = this;

      if (!this.get('open')) {
        return;
      }
      Ember.run.later(function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        Ember.$('.search-popup input').focus();
      });
    }),

    actions: {
      search: function search(event) {
        if (event) {
          event.preventDefault();
          var query = event.target.querySelector('input').value;
          this.set('query', query);
        }
        this.set('queryService.q', this.get('query'));
        this.set('open', false);
      },
      close: function close() {
        this.set('open', false);
      }
    }
  });
});