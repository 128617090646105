define('es-frontend/components/img-bounds-select', ['exports', 'es-frontend/utils/ddau', 'es-frontend/utils/crop-img'], function (exports, _ddau, _cropImg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    img: null,
    aspectRatio: 0,
    scale: null,
    left: 0,
    top: 0,
    width: 0,
    height: 0,

    actions: {
      scaleChanged: _ddau.default.attr('scale'),
      leftChanged: _ddau.default.attr('left'),
      topChanged: _ddau.default.attr('top'),
      widthChanged: _ddau.default.attr('width'),
      heightChanged: _ddau.default.attr('height'),
      imgReady: function imgReady() {
        var img = new Image();
        img.onload = function () {
          var width = this.get('width') / this.get('scale');
          var height = this.get('height') / this.get('scale');
          var croppedImg = (0, _cropImg.default)(img, this.get('left'), this.get('top'), this.get('width'), this.get('height'), this.get('scale'), width, height);
          this.imgReady({
            src: croppedImg,
            width: width,
            height: height
          });
        }.bind(this);
        img.src = this.get('ajaxService.currentUserPath') + '/proxy?url=' + encodeURIComponent(this.get('img.src'));
      }
    }
  });
});