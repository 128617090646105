define('es-frontend/routes/client/help', ['exports', 'es-frontend/utils/asset-url'], function (exports, _assetUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      document.title = 'Help - EveryoneSocial';
      return new Ember.RSVP.Promise(function (resolve) {
        if (window.everyonesocialHelp) {
          resolve(window.everyonesocialHelp);
          return;
        }
        window.everyonesocialHelpCallback = function () {
          window.everyonesocialHelp.forEach(function (section) {
            section.items.forEach(function (item) {
              item.content = item.content.replace(new RegExp('{{asset-url}}', 'g'), (0, _assetUrl.default)());
            });
          });
          resolve(window.everyonesocialHelp);
        };
        var script = document.createElement('script');
        script.async = true;
        script.src = (0, _assetUrl.default)() + '/json/help.jsonp';
        document.body.appendChild(script);
      });
    }
  });
});