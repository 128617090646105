define('es-frontend/services/facebook', ['exports', 'es-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    _ready: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('_ready', new Ember.RSVP.Promise(function (resolve, reject) {

        var loc = location.protocol + '//' + location.host + '/';
        var useJSAPI = loc == _environment.default.APP.restRootURL;
        _this.set('_useJSAPI', useJSAPI);

        if (!useJSAPI) {
          resolve();
          return;
        }

        window.fbAsyncInit = function () {
          window.FB.init({
            appId: _environment.default.facebook.appId,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v3.0'
          });
          resolve();
        };
        if (window.FB) {
          window.fbAsyncInit();
          return;
        }
        var js = document.createElement('script');
        js.id = 'facebook-jssdk';
        js.async = true;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        js.onerror = function () {
          reject('Error loading facebook API');
        };
        document.body.appendChild(js);
      }));
    },
    share: function share(url, message) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (_this2.get('_useJSAPI')) {
          _this2.get('_ready').then(function () {
            window.FB.ui({
              method: 'share',
              href: url,
              quote: message
            }, function (response) {
              if (response) {
                resolve();
              } else {
                reject('Share Cancelled');
              }
            });
          }, reject);
        } else {

          //We use the older facebook api here and just resolve true and return
          var toOpen = 'https://www.facebook.com/sharer/sharer.php?' + Ember.$.param({
            // This is the ES production Facebook app id
            app_id: 445951432102854,
            display: 'popup',
            u: url,
            ref: 'plugin',
            quote: message
          });
          window.open(toOpen, '_blank');
          resolve();
        }
      });
    }
  });
});