define('es-frontend/components/mobile-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['mobile-menu', 'col'],
    router: Ember.inject.service('router'),
    ajaxService: Ember.inject.service('es-ajax'),
    segment: Ember.inject.service('segment'),

    click: function click() {
      this.set('open', false);
    },


    streamsActive: Ember.computed('router.currentRouteName', function () {
      var currentRouteName = this.get('router.currentRouteName');
      return currentRouteName && currentRouteName.startsWith('client.streams');
    }),

    actions: {
      logout: function logout() {
        this.get('segment').reset();
        var frameless = true;
        /* eslint-disable no-empty */
        try {
          frameless = window.self === window.top;
        } catch (e) {}
        /* eslint-enable no-empty */
        if (frameless) {
          location.href = this.get('ajaxService.restRootPath') + 'logout';
        } else {
          this.get('ajaxService').ajax({
            userPath: false,
            dataType: 'html',
            url: 'logout.json'
          }).then(function () {
            location.reload();
          });
        }
      }
    }
  });
});