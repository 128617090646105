define('es-frontend/utils/query-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = queryParams;
  function queryParams(str, resolveValueFn) {
    var ret = {};
    var query = arguments.length ? str : location.search;
    if (!query) {
      return ret;
    }
    if (query.charAt(0) == '?') {
      query = query.substring(1);
    }
    if (!query) {
      return ret;
    }
    if (!resolveValueFn) {
      resolveValueFn = resolveValue;
    }
    query.split('&').forEach(function (part) {
      var pair = part.split('=');
      ret[decodeURIComponent(pair[0])] = resolveValueFn(decodeURIComponent(pair[1]));
    });
    return ret;
  }

  function resolveValue(value) {
    if (value == 'true') {
      return true;
    } else if (value == 'false') {
      return false;
    } else if (value == 'null' || value == null) {
      return null;
    } else if (!isNaN(value)) {
      return parseFloat(value);
    } else {
      return value;
    }
  }
});