define('es-frontend/components/social-queue-select', ['exports', 'es-frontend/utils/absolute-position-of', 'es-frontend/utils/ddau', 'ember-local-storage', 'es-frontend/utils/sort-social-queues'], function (exports, _absolutePositionOf, _ddau, _emberLocalStorage, _sortSocialQueues) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['social-queue-select', 'row'],
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    social_queues: [],
    selectedIds: [],
    _persistent: (0, _emberLocalStorage.storageFor)('social-queue-select'),
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    open: false,
    defaultSelect: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.later(function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        var social_queues = _this.get('social_queues');
        var selectedIds = _this.get('selectedIds');
        if (social_queues.get('length') && !selectedIds.get('length')) {
          var presets = _this.get('_persistent.selectedIds');
          var _selectedIds = [];
          social_queues.forEach(function (social_queue) {
            var id = parseInt(social_queue.id);
            if (presets.indexOf(id) >= 0) {
              _selectedIds.push(id);
            }
          });
          if (_this.get('defaultSelect') && !_selectedIds.length) {
            _selectedIds = social_queues.map(function (social_queue) {
              return parseInt(social_queue.id);
            }).filter(function (id) {
              return id > 0;
            });
          }
          _this.actions.selectedIdsChanged.call(_this, _selectedIds);
        }
      });
    },


    _queues: Ember.computed('social_queues', function () {
      var ret = [];
      this.get('social_queues').forEach(function (social_queue) {
        ret.push(Ember.Object.create({
          id: parseInt(social_queue.id),
          selected: false,
          profile_name: social_queue.get('profile_name'),
          service: social_queue.get('service'),
          btnClass: ''
        }));
      });
      (0, _sortSocialQueues.default)(ret);
      return ret;
    }),

    _queues_with_selection: Ember.computed('_queues', 'selectedIds', function () {
      var ret = [];
      var selectedIds = this.get('selectedIds');
      this.get('_queues').forEach(function (queue) {
        var selected = selectedIds.indexOf(queue.id) >= 0;
        queue.setProperties({
          selected: selected,
          btnClass: 'btn main ' + (selected ? queue.service : ' txt8 bg3')
        });
        ret.push(queue);
      });
      return ret;
    }),

    selectedIdsObserver: Ember.observer('selectedIds', function () {
      var _this2 = this;

      Ember.run.later(function () {
        //Twitter disallow duplicates in post
        var numTwitter = 0;
        var result = [];
        var social_queues = _this2.get('social_queues');
        _this2.get('selectedIds').forEach(function (id) {
          var social_queue = social_queues.findBy('id', '' + id);
          if (social_queue) {
            if (social_queue.get('service') == 'twitter') {
              numTwitter++;
              if (numTwitter < 2) {
                result.push(id);
              }
            } else {
              result.push(id);
            }
          }
        });
        if (numTwitter > 1) {
          _this2.set('selectedIds', result);
        } else {
          _this2.set('_persistent.selectedIds', _this2.get('selectedIds'));
        }
      });
    }),

    showPlus: Ember.computed('social_queues', 'selectedIds', function () {
      return (this.get('social_queues.length') || 0) > (this.get('selectedIds.length') || 0);
    }),

    actions: {
      _layoutPopup: function _layoutPopup(popupElement) {
        var position = (0, _absolutePositionOf.default)(this.$());
        popupElement.css({
          left: Math.round(position.left) + 'px',
          top: Math.round(position.top) + 'px'
        });
      },

      selectedIdsChanged: _ddau.default.attr('selectedIds'),
      toggleSocialQueue: function toggleSocialQueue(queue) {
        var _this3 = this;

        Ember.run.later(function () {
          if (_this3.isDestroying || _this3.isDestroyed) {
            return;
          }
          var selectedIds = _this3.get('selectedIds').slice();
          var index = selectedIds.indexOf(queue.id);
          if (index >= 0) {
            selectedIds.splice(index, 1);
          } else {
            selectedIds.splice(0, 0, queue.id);
          }
          _this3.actions.selectedIdsChanged.call(_this3, selectedIds);
        });
      }
    }
  });
});