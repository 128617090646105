define('es-frontend/components/es-sidebar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNameBindings: ['className', 'position'],
    classNames: ['es-sidebar', 'row', 'underlay'],
    attributeBindings: ['style'],
    position: 'left',
    width: 200,
    open: true,
    delay: 600,
    showCloseBtn: true,

    didRender: function didRender() {
      var _this = this;

      Ember.run.later(function () {
        if (!_this.$()) {
          return;
        }
        if (_this.get('open')) {
          _this.$().addClass('open').css('width', '100%');
          _this.$('.inner').width(_this.get('width'));
        } else {
          _this.$('.inner').width(0);
          _this.$().removeClass('open');
          Ember.run.later(function () {
            if (!_this.$()) {
              return;
            }
            if (!_this.get('open')) {
              _this.$().css('width', 0);
            }
          }, _this.get('delay'));
        }
      });
    }
  });
});