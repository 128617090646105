define('es-frontend/controllers/client', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    router: Ember.inject.service('router'),
    widthService: Ember.inject.service('width'),
    subscribableService: Ember.inject.service('subscribable'),
    store: Ember.inject.service('store'),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('subscribableService').on('subscribableChange', this, this._subscribableChange);
    },


    classNames: Ember.computed('router.currentRouteName', 'widthService.width', function () {
      var ret = this.get('router.currentRouteName').split('.');
      ret.push('col', this.get('widthService.size'));
      return ret.join(' ');
    }),

    _subscribableChange: function _subscribableChange(subscribable) {

      // This is a bit of a fudge, since although subscribables and subscriptions can
      // represent different aspects of the same entity, their attributes have different names.

      var subscriptions = this.get('model.subscriptions').slice();
      if (subscribable.get('manage') == 'add' || subscribable.get('manage') == 'blocked') {
        // unsubscribed
        var index = subscriptions.findIndex(function (obj) {
          return obj.get('content_stream_id') == subscribable.id;
        });
        if (index >= 0) {
          subscriptions.splice(index, 1);
        }
      } else {
        // subscribed
        var subscription = this.get('store').push({
          data: [{
            id: '' + subscribable.id, // This is a fudge - I don't actually get access to the db entity here...
            type: 'subscription',
            attributes: {
              content_stream_id: subscribable.id,
              image_url: subscribable.get('image_url'),
              name: subscribable.get('title')
            }
          }]
        })[0];
        subscriptions.push(subscription);
      }
      this.set('model.subscriptions', subscriptions);
    }
  });
});