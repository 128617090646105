define('es-frontend/routes/signin/safe-to-close', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      try {
        window.close();
      } catch (e) {
        //No action required
      }
      return this.modelFor('signin');
    }
  });
});