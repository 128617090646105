define('es-frontend/components/es-tab-pane', ['exports', 'es-frontend/utils/ddau'], function (exports, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    classNames: ['es-tab-pane', 'col'],
    selected: null,
    bodyClass: 'fill es-tab-body bg1',
    tabsClassNames: ['es-tabs', 'row', 'bg2'],
    tabClassNameBindings: ['selected', 'selected:bg1'],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    _selectedIndex: Ember.computed('selected', function () {
      return this.$('.es-tabs .es-tab[data-tab-value="' + this.get('selected') + '"]').data('tab-index');
    }),

    _selectedValueAndIndex: Ember.computed('selected', function () {
      return {
        value: this.get('selected'),
        index: this.get('_selectedIndex')
      };
    }),

    actions: {
      selectedChanged: _ddau.default.attr('selected')
    }
  });
});