define('es-frontend/utils/sort-social-queues', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = sortSocialQueues;
  function sortSocialQueues(social_queues) {
    return social_queues.filter(function (a) {
      return a.service != 'xing';
    }).sort(function (a, b) {
      return _toScore(a.service) - _toScore(b.service);
    });
  }

  function _toScore(service) {
    switch (service) {
      case 'twitter':
        return 1;
      case 'facebook':
        return 2;
      case 'linkedin':
        return 3;
      case 'xing':
        return 4;
      default:
        return 5;
    }
  }
});