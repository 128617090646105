define('es-frontend/components/taggable-area', ['exports', 'es-frontend/utils/ddau', 'es-frontend/utils/ensure-visible', 'es-frontend/utils/twitter-text'], function (exports, _ddau, _ensureVisible, _twitterText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['taggable-area', 'col'],
    focus: false,
    active: false,
    value: null,
    suggestions: null,
    zIndex: 90,
    popupOffsetLeft: 0,
    popupOffsetTop: 0,
    popupOffsetWidth: 0,
    readonly: false,

    textAreaClassNames: Ember.computed('focus', 'active', 'suggestions', function () {
      var ret = ['fill'];
      if (this.get('focus')) {
        ret.push('focus');
        if (this.get('suggestions')) {
          ret.push('suggestions-open');
        }
      }
      if (this.get('active')) {
        ret.push('active');
      }
      if (this.get('textAreaClasses')) {
        ret.push(this.get('textAreaClasses'));
      }
      return ret.join(' ');
    }),

    _getQuery: function _getQuery($text) {
      var bounds = this._suggestBounds($text);
      var token = $text.val().substring(bounds[0], bounds[1]).trim();
      if (token.length > 1 && token.charAt(0) == '@' && /^@?(\w){1,15}$/.test(token)) {
        return token.substring(1);
      }
      return null;
    },

    _suggestBounds: function _suggestBounds($text) {
      var val = $text.val();
      var selectionStart = $text.prop('selectionStart');
      var selectionEnd = $text.prop('selectionEnd');
      if (selectionStart != selectionEnd) {
        return [selectionStart, selectionEnd];
      }
      if (selectionStart && selectionStart < val.length) {
        selectionStart--;
      }
      while (selectionStart) {
        if (/\s/.test(val.charAt(selectionStart))) {
          selectionStart++;
          break;
        }
        selectionStart--;
      }
      while (selectionEnd < val.length) {
        if (/\s/.test(val.charAt(selectionEnd))) {
          break;
        }
        selectionEnd++;
      }
      return [selectionStart, selectionEnd];
    },

    _runQuery: function _runQuery() {
      var _this = this;

      var query = this.get('query');
      if (!query) {
        this.set('suggestions', null); // clear suggestions if there is no query
        return;
      }
      this.search(query).then(function (suggestions) {
        _this.setProperties({
          focus: true,
          hiliteIndex: 0,
          suggestions: suggestions && suggestions.length ? suggestions : null
        });
      });
    },
    search: function search() /*query*/{
      return new Ember.RSVP.Promise(function (resolve) {
        resolve([]);
      });
    },


    hilitedSuggestion: Ember.computed('suggestions', 'hiliteIndex', function () {
      return this.get('suggestions.' + this.get('hiliteIndex'));
    }),

    suggestionKey: function suggestionKey(suggestion) {
      return '@' + suggestion;
    },
    suggestionValue: function suggestionValue(suggestion) {
      return suggestion;
    },


    valueObserver: Ember.observer('value', function () {
      if (this.stateChanged) {
        this.stateChanged(this.get('keys'), this.get('value'));
      }
    }),

    focusObserver: Ember.observer('focus', function () {
      if (this.get('focus')) {
        this.grabFocus();
      }
    }),

    grabFocus: function grabFocus() {
      Ember.run.later(this, function () {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        if (this.get('focus')) {
          this.$('textarea').focus();
        }
      }, 400);
    },
    didInsertElement: function didInsertElement() {
      this.grabFocus();
      this.processHeight();
    },


    remaining: Ember.computed('value', 'maxLength', function () {
      return this.get('maxLength') - _twitterText.default.getTweetLength(this.get('value') || '');
    }),

    remainingClass: Ember.computed('remaining', function () {
      return 'remaining ' + (this.get('remaining') < 0 ? ' error' : '');
    }),

    processHeight: function processHeight() {
      var $text = this.$('textarea');
      if (this.get('active')) {
        var height = $text.height();
        $text.height('auto');
        var newHeight = $text[0].scrollHeight;
        $text.height(height).height(newHeight);
      } else {
        $text.height(18);
      }
    },


    activeObserver: Ember.observer('active', function () {
      this.processHeight();
    }),

    actions: {
      focusChanged: _ddau.default.attr('focus'),
      activeChanged: _ddau.default.attr('active'),

      focusIn: function focusIn() {
        this.actions.focusChanged.call(this, true);
        this.actions.activeChanged.call(this, true);
      },
      focusOut: function focusOut() {
        var _this2 = this;

        Ember.run.later(function () {
          if (_this2.isDestroying || _this2.isDestroyed) {
            return;
          }
          _this2.actions.focusChanged.call(_this2, false);
          if (!_this2.get('value')) {
            _this2.set('active', false);
          }
        }, 200);
      },
      keyDown: function keyDown(event) {
        this.processHeight();
        var suggestions = this.get('suggestions');
        if (suggestions) {
          switch (event.key) {
            case 'ArrowDown':
              {
                var hiliteIndex = this.get('hiliteIndex');
                var newhiliteIndex = (hiliteIndex + 1) % suggestions.length;
                this.set('hiliteIndex', newhiliteIndex);
                (0, _ensureVisible.default)(this.$('.suggestion')[newhiliteIndex]);
                event.preventDefault();
                break;
              }
            case 'ArrowUp':
              {
                var _hiliteIndex = this.get('hiliteIndex');
                var _newhiliteIndex = (_hiliteIndex ? _hiliteIndex : suggestions.length) - 1;
                this.set('hiliteIndex', _newhiliteIndex);
                (0, _ensureVisible.default)(this.$('.suggestion')[_newhiliteIndex]);
                event.preventDefault();
                break;
              }
            case 'Escape':
              this.set('query', null);
              this.set('suggestions', null);
              event.preventDefault();
              break;
            case 'Delete':
            case 'Backspace':
              this.set('query', null);
              this.set('suggestions', null);
              break;
          }
        }
      },
      keyUp: function keyUp() {
        this.processHeight();
      },
      keyPress: function keyPress(event) {
        var _this3 = this;

        var suggestions = this.get('suggestions');
        if (suggestions) {
          switch (event.key) {
            case 'Return':
            case 'Enter':
              {
                this.actions.applySuggestion.call(this, this.get('suggestions.' + this.get('hiliteIndex')));
                this.set('suggestions', null);
                event.preventDefault();
                break;
              }
          }
        }
        Ember.run.later(function () {
          var $text = _this3.$('textarea');
          var query = _this3._getQuery($text);
          if (_this3.get('query') == query) {
            return;
          }
          _this3.set('query', query);
          Ember.run.debounce(_this3, _this3._runQuery, 500);
        });
      },
      applySuggestion: function applySuggestion(suggestion) {
        var $text = this.$('textarea');
        var suggestBounds = this._suggestBounds($text);
        var val = $text.val();

        var suggestionKey = this.suggestionKey(suggestion);
        var keys = Ember.$.extend({}, this.get('keys') || {});
        keys[suggestionKey] = this.suggestionValue(suggestion);

        var value = val.substring(0, suggestBounds[0]) + suggestionKey + val.substring(suggestBounds[1], val.length) + (suggestBounds[1] == val.length ? ' ' : '');

        this.setProperties({
          value: value,
          keys: keys,
          suggestions: null
        });

        $text.focus();

        if (this.stateChanged) {
          this.stateChanged(keys, value);
        }
      }
    }
  });
});