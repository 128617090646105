define('es-frontend/utils/cross-origin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = crossOrigin;
  function crossOrigin(options, url, token) {
    url = url || options.url;
    if (!url.startsWith(location.protocol + '//' + location.host + '/')) {
      options.crossDomain = true;
      var xhrFields = options.xhrFields = options.xhrFields || {};
      xhrFields.withCredentials = true;
    }
    if (token) {
      var before = options.beforeSend;
      options.beforeSend = function (request) {
        if (before) {
          before(request);
        }
        request.setRequestHeader('X-CSRF-Token', token);
      };
    }
    return options;
  }
});