define('es-frontend/services/embed', ['exports', 'es-frontend/utils/is-valid-url'], function (exports, _isValidUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    scraper: Ember.inject.service('scraper'),
    handlerOrder: ['Facebook', 'Instagram', 'YouTube', 'Vimeo', 'Vidyard', 'DailyMotion', 'Twitter', 'Reddit', 'SurveyMonkey', 'GoogleForms', 'Linkedin', 'Url', 'Frame'],
    fbEmbedTimeout: null,

    embed: function embed(input, embedded) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        input = input ? input.trim() : '';
        embedded = !!embedded;

        //a handler can be identified from the input - it will return a promise
        for (var h = 0; h < _this2.handlerOrder.length; h++) {
          var handler = _this2.handlers[_this2.handlerOrder[h]];
          if (handler.call(_this2, input, embedded, resolve, reject)) {
            return;
          }
        }

        //No suitable handler found...
        reject({
          url: input,
          msg: 'Invalid input.'
        });
      });
    },


    handlers: {
      /**
       * The most common case - somebody pasted in a url
       */
      Url: function Url(input, embedded, resolve) {
        if ((0, _isValidUrl.default)(input)) {
          var anchor = document.createElement('a');
          anchor.href = input;
          if (anchor.hostname) {
            var ret = {
              url: input,
              embedCode: null,
              nativeShare: false
            };
            var embedAspectRatio = 1;
            var param = 'embedAspectRatio=';
            var index = input.indexOf(param);
            if (index > 0) {
              embedAspectRatio = parseFloat(input.substring(index + param.length, this._indexFrom(input, '&', index))) || 1;
              embedded = true; // If there is an aspect ratio specified, then we should embed.
            }
            if (embedded) {
              ret.embedCode = this._frameEmbedCode(input, embedAspectRatio);
            }
            resolve(ret);
            return true;
          }
        }
        return false;
      },

      /**
       * Second most common generic case - user posts code for an iframe
       */
      Frame: function Frame(input, embedded, resolve) {
        if (input.toLowerCase().startsWith('<iframe')) {
          // an iframe
          var $frame = Ember.$(input);
          var width = $frame.attr('width');
          var height = $frame.attr('height');
          var url = $frame.attr('src');
          var embedAspectRatio = 1;
          if (width && height) {
            embedAspectRatio = Math.round(width * 100 / height) / 100;
            if (embedAspectRatio) {
              url += (url.indexOf('?') > 0 ? '&' : '?') + 'embedAspectRatio=' + embedAspectRatio;
            }
          }
          return this.handlers.Url.call(this, url, true, resolve);
        }
        return false;
      },
      Facebook: function Facebook(input, embedded, resolve) {
        var index = input.indexOf('https://www.facebook.com/');
        if (index < 0) {
          return false; // definitely not facebook
        }

        var url = this._extractGenericUrl(input, index);

        //Cut out width parameter - we will ad our own
        var start = url.indexOf('width=');
        if (start > 0) {
          var end = this._indexFrom(url, '&', start);
          url = url.substring(0, start) + url.substring(end);
        }

        /* eslint-disable no-empty */
        if (url.startsWith('https://www.facebook.com/plugins/video.php?href=')) {
          url = decodeURIComponent(url.substring(48, this._indexFrom(url, '&', 48)));
        } else if (url.indexOf('/videos/') >= 0) {} else if (url.startsWith('https://www.facebook.com/plugins/post.php?href=')) {
          url = decodeURIComponent(url.substring(47, this._indexFrom(url, '&', 47)));
        } else if (url.indexOf('/posts/') >= 0) {} else {
          return false;
        }
        /* eslint-enable no-empty */

        resolve({
          url: url,
          embedCode: this._facebookEmbedCode(url),
          nativeShare: true
        });
        return true;
      },
      Instagram: function Instagram(input, embed, resolve) {
        var index;
        if ((index = input.indexOf('https://www.instagram.com/')) < 0) {
          return false;
        }
        var url = this._extractGenericUrl(input, index);

        resolve({
          url: url,
          embedCode: this._instagramEmbedCode(url),
          nativeShare: true
        });
        return true;
      },
      YouTube: function YouTube(url, embed, resolve) {
        var v, a, start, end;
        if (url && (url.startsWith('https://youtu.be/') || url.startsWith('https://www.youtube.com/') || url.startsWith('http://youtu.be/') || url.startsWith('http://www.youtube.com/'))) {
          a = document.createElement('a');
          a.href = url;
          v = a.pathname;
          if (v.startsWith('/embed/')) {
            start = 7;
            end = v.indexOf('/', start);
          } else {
            v = a.search;
            start = v.indexOf('v=');
            if (start >= 0) {
              start += 2;
              end = v.indexOf('&', start);
            } else {
              v = a.pathname;
              start = 1;
              end = v.indexOf('/', start);
            }
          }
        }
        if (v) {
          if (end < 0) {
            end = v.length;
          }
          v = v.substring(start, end);
          resolve({
            url: 'https://www.youtube.com/watch?v=' + v,
            embedCode: this._frameEmbedCode('https://www.youtube.com/embed/' + v, 3 / 2),
            nativeShare: false
          });
          return true;
        }
        return false;
      },
      Vimeo: function Vimeo(url, embed, resolve) {
        var index;
        var path = null;
        if ((index = url.indexOf('vimeo.com/')) > 0) {
          path = url.substring(index + 10).split(/[^\w-\/]/g)[0];
        } else if ((index = url.indexOf('//vimeopro.com/')) > 0) {
          path = url.substring(index + 15).split(/[^\w-\/]/g)[0];
        }
        var videoId = null;
        if (path) {
          if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
          }
          path = path.split('/');
          if (path.length == 1) {
            videoId = parseInt(path[0]);
          } else {
            index = path.indexOf('video');
            if (index >= 0) {
              videoId = parseInt(path[index + 1]);
            }
          }
        }
        if (videoId) {
          resolve({
            url: url.indexOf('https://') ? 'https://vimeo.com/' + videoId : url,
            embedCode: this._frameEmbedCode('https://player.vimeo.com/video/' + videoId, 3 / 2),
            nativeShare: false
          });
          return true;
        }
        return false;
      },
      Vidyard: function Vidyard(url, embed, resolve) {
        var index;
        var videoId = null;
        url = url.replace(/https:\/\/play.vidyard.com\/embed\/v4.js/g, '');
        if ((index = url.indexOf('//play.vidyard.com/')) > 0) {
          videoId = url.substring(index + 19).split(/\W/g)[0];
        } else if ((index = url.indexOf('//video.vidyard.com/watch/')) > 0) {
          videoId = url.substring(index + 26).split(/\W/g)[0];
        } else if ((index = url.indexOf('//share.vidyard.com/watch/')) > 0) {
          videoId = url.substring(index + 26).split(/\W/g)[0];
        }
        if (videoId) {
          resolve({
            url: 'https://share.vidyard.com/watch/' + videoId,
            //embedCode: this._frameEmbedCode('https://play.vidyard.com/'+videoId+'.html?v=3.1.1', 3/2),
            embedCode: this.vidyardEmbedCode(videoId),
            nativeShare: false
          });
          return true;
        }
        return false;
      },
      DailyMotion: function DailyMotion(url, embed, resolve) {
        var index;
        var videoId = null;
        if ((index = url.indexOf('dailymotion.com/video/')) > 0) {
          videoId = url.substring(index + 22).split(/\W/g)[0];
        } else if ((index = url.indexOf('//dai.ly/')) > 0) {
          videoId = url.substring(index + 9).split(/\W/g)[0];
        } else if ((index = url.indexOf('dailymotion.com/embed/video/')) > 0) {
          videoId = url.substring(index + 28).split(/\W/g)[0];
        }
        if (videoId) {
          resolve({
            url: 'https://dai.ly/' + videoId,
            embedCode: this._frameEmbedCode('https://www.dailymotion.com/embed/video/' + videoId, 3 / 2),
            nativeShare: false
          });
          return true;
        }
        return false;
      },


      /**
       * Check for twitter style embed code. - Embedding tweets is kind of annoying - they don't support simple embeddable URLs with iframes
       * but rather insist that you use their javascipt API to insert a widget into the page. (Cue much cursing and insults directed at
       * their mothers, who clearly didn't raise them properly.)
       *
       * We therefore store the tweet as normal, but do some voodoo to insert the tweets into the page. I am not happy about this because
       * of the security implications of letting arbitrary third party code run in our page. It just feels wrong.
       *
       */
      Twitter: function Twitter(input, embed, resolve, reject) {
        var _this3 = this;

        var status = input.indexOf('/status/');
        if (status < 0) {
          return false;
        }
        var start = input.lastIndexOf('https://twitter.com/', status);
        if (start < 0) {
          return false;
        }
        var url = input.substring(start, this._indexFrom(input, '"', start));
        start = url.indexOf('/status/');
        if (start < 0) {
          return null;
        }
        return this.handlers.Url.call(this, url, true, function () {
          //anatomy of an embedded tweet url: https://twitter.com/<USER_HANDLE>/status/<STATUS_ID>/OTHER_NONESSENTIAL
          var userHandle = url.substring(url.lastIndexOf('/', start - 1) + 1, start);
          start += 8;
          var statusId = url.substring(start, _this3._indexFrom(url, '/', start));
          resolve({
            url: url,
            embedCode: _this3._twitterEmbedCode(userHandle, statusId),
            nativeShare: true
          });
        }, reject);
      },
      Reddit: function Reddit(input, embed, resolve, reject) {
        var _this4 = this;

        //Anatomy of a reddit URL: https://www.reddit.com/r/<POST_IDENTIFIER>
        var start = input.indexOf('https://www.reddit.com/r/');
        if (start < 0) {
          return false;
        }
        var url = input.substring(start, Math.min(this._indexFrom(input, '"', start), this._indexFrom(input, '?', start)));
        return this.handlers.Url.call(this, url, true, function () {
          resolve({
            url: url,
            embedCode: _this4._redditEmbedCode(url),
            nativeShare: true
          });
        }, reject);
      },


      /**
       * Check for survey monkey style embed code. We can grab the web link, but not their embed codes
       */
      SurveyMonkey: function SurveyMonkey(input, embed, resolve, reject) {
        if (!input.indexOf('https://www.surveymonkey.com/r/')) {
          resolve({
            url: input,
            embedCode: this._frameEmbedCode(input, 1),
            nativeShare: false
          });
          return true;
        } else if (input.indexOf('widget.surveymonkey.com/collect/website/js') > 0) {
          reject('Survey monkey embed codes are not currently supported - please use a weblink instead');
          return true;
        }
        return false;
      },
      GoogleForms: function GoogleForms(input, embed, resolve) {
        if (!input.indexOf('https://docs.google.com/forms/')) {
          resolve({
            url: input,
            embedCode: this._frameEmbedCode(input, 1),
            nativeShare: false
          });
          return true;
        }
        return false;
      },


      Linkedin: function Linkedin(input, embed, resolve, reject) {
        var start = input.indexOf('https://www.linkedin.com/embed/');
        if (start < 0) {
          return false;
        }

        var frameEmbedCode = this._frameEmbedCode;
        function innerResolve(result) {
          result.nativeShare = true;
          var url = result.url;
          var embedAspectRatio = parseFloat(url.substring(url.indexOf('embedAspectRatio=') + 17)) || 0.65;
          result.embedCode = frameEmbedCode(url, embedAspectRatio, 10);
          resolve(result);
        }

        var ret = this.handlers.Frame.call(this, input, true, innerResolve, reject);
        if (!ret) {
          var url = input.substring(start, Math.min(this._indexFrom(input, '"', start), this._indexFrom(input, '\'', start)));
          ret = this.handlers.Url.call(this, url, true, innerResolve, reject);
        }
        return ret;
      }
    },

    _frameEmbedCode: function _frameEmbedCode(url, embedAspectRatio, addToHeight) {
      if (!addToHeight) {
        addToHeight = 0;
      }
      return function ($container) {
        $container.html('<iframe style="width:100%;border:none;" src="' + url + '"></iframe>');
        var $frame = $container.find('iframe');
        function resize() {
          var width = $frame.width();
          if (width) {
            $frame.height(width / embedAspectRatio + addToHeight);
          } else {
            window.setTimeout(resize, 100);
          }
        }
        resize();
        window.setTimeout(resize, 300); // need to do this again because there is a weird delay in the article modal
      };
    },
    _extractGenericUrl: function _extractGenericUrl(input, index) {
      if (!index) {
        return input;
      }
      switch (input.charAt(index - 1)) {
        case '"':
          return input.substring(index, this._indexFrom(input, '"', index + 1));
        case '\'':
          return input.substring(index, this._indexFrom(input, '\'', index + 1));
        default:
          return input.substring(index, this._indexFrom(input, /\s/, index + 1));
      }
    },


    /**
     * Embedded facebook posts require that width is passed as a parameter to the iframe. We therefore have to jump trough
     * some hoops in order to pass the correct width parameter to the url for the iframe.
     */
    _facebookEmbedCode: function _facebookEmbedCode(url) {
      var _this = this;
      return function ($container) {
        var embedTimeout;
        function doEmbed() {
          var width = $container.width();
          if (!width) {
            //If the width is now present, we try again in a little while
            window.clearTimeout(embedTimeout);
            embedTimeout = window.setTimeout(doEmbed, 100);
            return;
          }

          $container.html('<div class="fb-post" data-href="' + url + '" data-width="' + width + '" data-show-text="true"><blockquote cite="' + url + '" class="fb-xfbml-parse-ignore">_</blockquote></div>');
          (function (d, s, id) {
            if (d.getElementById(id)) {
              return;
            }
            var js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/all.js#xfbml=1&version=v2.10";
            document.body.appendChild(js);
          })(document, 'script', 'facebook-jssdk');
          window.clearTimeout(_this.fbEmbedTimeout);
          _this.fbEmbedTimeout = window.setTimeout(function () {
            if (window.FB) {
              window.FB.XFBML.parse();
            }
          }, 100);
        }
        doEmbed();
      };
    },


    /**
     * Embedded instagram posts require that width is passed as a parameter to the iframe. We therefore have to jump trough
     * some hoops in order to pass the correct width parameter to the url for the iframe.
     */
    _instagramEmbedCode: function _instagramEmbedCode(url) {
      return function ($container) {
        var embedTimeout;
        function doEmbed() {
          var width = $container.width();
          if (!width) {
            //If the width is now present, we try again in a little while
            window.clearTimeout(embedTimeout);
            embedTimeout = window.setTimeout(doEmbed, 100);
            return;
          }

          $container.html('<blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="' + url + '?utm_source=ig_embed_loading" data-instgrm-version="12" style="width: 100%;"></blockquote>');

          if (Ember.$('script[src="//www.instagram.com/embed.js"]').length) {
            if (window.instgrm && window.instgrm.Embeds) {
              window.instgrm.Embeds.process();
            }
            return;
          }

          var script = document.createElement('script');
          script.src = "//www.instagram.com/embed.js";
          document.body.appendChild(script);
        }
        doEmbed();
      };
    },
    _twitterEmbedCode: function _twitterEmbedCode(userHandle, statusId) {
      return function ($container) {
        $container.html('<div>' + '<blockquote class="twitter-tweet" data-lang="en-gb"><a href="https://twitter.com/' + userHandle + '/status/' + statusId + '"></a></blockquote>' + '<script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script>' + '</div>');
      };
    },
    _redditEmbedCode: function _redditEmbedCode(url) {
      return function ($container) {
        $container.html('<div>' + '<blockquote class="reddit-card"><a href="' + url + '?ref=share&ref_source=embed"></a></blockquote>' + '<script async src="//embed.redditmedia.com/widgets/platform.js" charset="utf-8"></script>' + '</div>');
      };
    },
    _loadVidyard: function _loadVidyard() {
      var vidyard = this.get('vidyard');
      if (!vidyard) {
        this.set('vidyard', vidyard = new Ember.RSVP.Promise(function (resolve) {
          var script = document.createElement('script');
          script.async = true;
          script.addEventListener('load', function () {
            resolve();
          });
          script.src = 'https://play.vidyard.com/embed/v4.js';
          document.body.appendChild(script);
        }));
      }
      return vidyard;
    },
    vidyardEmbedCode: function vidyardEmbedCode(videoId) {
      var _this5 = this;

      return function ($container) {
        _this5._loadVidyard().then(function () {
          $container.css({
            maxWidth: '100%',
            maxHeight: 300,
            overflow: 'hidden'
          }).html('<img class="vidyard-player-embed" ' + 'src="https://play.vidyard.com/' + videoId + '.jpg" ' + 'data-uuid="' + videoId + '" ' + 'data-v="4" ' + 'data-type="inline" ' + 'style="max-width:100%;max-height:300px;opacity:0.1" />');
          window.VidyardV4.api.renderPlayer($container.find('img')[0]);
          window.setTimeout(function () {
            $container.find('iframe,.vidyard-player-container').css('maxHeight', 300);
          }, 400);
        });
      };
    },


    //Get the index of the substring given
    _indexFrom: function _indexFrom(str, val, fromIndex) {
      var index = str.indexOf(val, fromIndex);
      return index < 0 ? str.length : index;
    },
    _removeEnding: function _removeEnding(str, val) {
      return str.endsWith(val) ? str.substring(0, str.length - val.length) : str;
    }
  });
});