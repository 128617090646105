define('es-frontend/routes/index', ['exports', 'es-frontend/utils/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    //If nothing is specified, we replace current with client
    beforeModel: function beforeModel() {
      var qp = (0, _queryParams.default)();
      if (qp.hash) {
        this.replaceWith(qp.hash);
      } else {
        this.replaceWith('client');
      }
    }
  });
});