define('es-frontend/components/search-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['search-input', 'row'],
    value: '',
    iconPosition: 'left',
    icon: 'search',
    placeholder: 'Search',

    actions: {
      valueChanged: function valueChanged(event) {
        this.set('value', event.currentTarget.value);
      }
    }

  });
});