define('es-frontend/utils/ddau', ['exports', 'es-frontend/utils/strings'], function (exports, _strings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    /*
    Returns a function based on the attribute given. E.g.: Taking the attr 'foo',
    the function will look for an attribute of this 'fooChanged', and if present
    invoke it as a function with the value provided as the first argument. If
    there is no such attribute, set the attribute 'foo' on this to the value
    provided as the first argument.
    */
    attr: function attr(_attr) {
      var action = (_attr.indexOf('_') ? _attr : _attr.substring(1)) + 'Changed';
      return function (event) {
        var newValue = event && event.target ? Ember.$(event.target).val() : event;
        var oldValue = this.get(_attr);
        if (oldValue == newValue) {
          return; // no change
        }
        var fn = this.get(action);
        if (fn) {
          fn.call(this, newValue, oldValue);
        } else {
          this.set(_attr, newValue);
        }
      };
    },
    firer: function firer(action, defaultFn) {
      defaultFn = defaultFn || '_default' + _strings.default.capitalize(action);
      return function () {
        var fn = this.get(action) || this.get(defaultFn);
        if (fn) {
          fn.apply(this, arguments);
        }
      };
    }
  };
});