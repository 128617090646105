define('es-frontend/routes/client/share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      url: {
        refreshModel: true,
        replace: false
      },
      curated_article_id: {
        refreshModel: true,
        replace: false
      }
    },
    ajaxService: Ember.inject.service('es-ajax'),

    model: function model(params) {
      var _this = this;

      var clientModel = this.modelFor('client');
      var model = {
        user: clientModel.get('user'),
        customer: clientModel.get('customer'),
        features: clientModel.get('features'),
        subscriptions: clientModel.get('subscriptions')
      };
      if (!params.curated_article_id) {
        return model;
      }
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('ajaxService').ajax({
          url: '/contents/' + params.curated_article_id + '.json',
          method: 'GET'
        }).then(function (article) {
          model.article = article;
          if (article) {
            model.defaultMeta = _this.defaultMeta(article);
          }
          resolve(model);
        }, reject);
      });
    },
    setupController: function setupController(controller, model) {
      if (!controller.get('url')) {
        controller.set('url', Ember.get(model, 'article.url'));
      }
      Ember.Route.prototype.setupController.apply(this, arguments);
    },
    defaultMeta: function defaultMeta(article) {
      var imgs = [];
      if (Ember.get(article, 'large_image_url')) {
        imgs.push({
          src: Ember.get(article, 'large_image_url')
        });
      }
      return {
        title: Ember.get(article, 'title'),
        description: Ember.get(article, 'summary'),
        imgs: imgs
      };
    }
  });
});