define('es-frontend/routes/signin/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    activate: function activate() {
      Ember.run.later(function () {
        Ember.$('input.email').focus();
      });
    },
    model: function model() {
      var model = this.modelFor('signin');
      if (model.sso_url) {
        location.replace(model.sso_url); //using sso to handle signin, so we redirect
      }
      return model;
    }
  });
});