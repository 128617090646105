define('es-frontend/adapters/subscription', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindAll: function urlForFindAll() {
      return '/approved_streams.json';
    },
    shouldReloadAll: function shouldReloadAll() {
      return false;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    }
  });
});