define('es-frontend/helpers/pts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pts = pts;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function pts(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        pts = _ref2[0];

    if (!pts) {
      return '-';
    }
    var abs = Math.abs(pts);
    var round = Math.round(abs);
    var str = round.toString(10);
    var parts = [];
    var index = str.length % 3;
    if (index) {
      parts.push(str.substring(0, index));
    }
    while (index < str.length) {
      var next = Math.max(index + 3, str.length);
      parts.push(str.substring(index, next));
      index = next;
    }
    var ret = parts.join(',');
    if (pts < 0) {
      //Negative
      ret = '-' + ret;
    }
    if (abs != round) {
      //Decimal
      var s = abs.toString(10);
      ret += s.substring(s.indexOf('.'));
    }
    return ret;
  }

  exports.default = Ember.Helper.helper(pts);
});