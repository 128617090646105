define('es-frontend/controllers/client/queue/settings', ['exports', 'ember-buffered-proxy/proxy'], function (exports, _proxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    esAjax: Ember.inject.service('es-ajax'),
    widthService: Ember.inject.service('width'),
    segment: Ember.inject.service('segment'),
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    days: [{ id: 'sun', name: 'Sunday', shortName: 'Su' }, { id: 'mon', name: 'Monday', shortName: 'M' }, { id: 'tue', name: 'Tuesday', shortName: 'T' }, { id: 'wed', name: 'Wednesday', shortName: 'W' }, { id: 'thu', name: 'Thursday', shortName: 'Th' }, { id: 'fri', name: 'Friday', shortName: 'F' }, { id: 'sat', name: 'Saturday', shortName: 'Sa' }],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    socialQueuesObserver: Ember.observer('model.social_queues', function () {
      this.set('selectedSocialQueue', this.get('model.social_queues.firstObject'));
    }),

    selectedSocialQueueChanged: Ember.observer('selectedSocialQueue', function () {
      var _this = this;

      this.set('schedule', null);
      this.get('store').findRecord('schedule', this.get('selectedSocialQueue.id')).then(function (schedule) {
        _this.set('schedule', _proxy.default.create({ content: schedule }));
      }, function (error) {
        throw error;
      });
    }),

    selectedDays: Ember.computed('schedule.{mon,tue,wed,thu,fri,sat,sun}', function () {
      var schedule = this.get('schedule');
      var ret = [];
      if (schedule) {
        this.get('days').forEach(function (day) {
          if (schedule.get(day.id)) {
            ret.push(day);
          }
        });
      }
      return ret;
    }),

    actions: {
      selectQueue: function selectQueue(social_queue) {
        this.set('selectedSocialQueue', social_queue);
      },
      toggleDay: function toggleDay(day) {
        this.toggleProperty('schedule.' + day.id);
        Ember.run.debounce(this, this.actions.save, 1000);
      },
      deletePostTime: function deletePostTime(post_time) {
        this.set('schedule.post_times.' + this.get('schedule.post_times').indexOf(post_time) + '.deleted', true);
        this.set('schedule.post_times', this.get('schedule.post_times').slice()); // force change
        Ember.run.debounce(this, this.actions.save, 1000);
      },
      postTimeChanged: function postTimeChanged() {
        this.set('schedule.post_times', this.get('schedule.post_times').slice()); // force change
        Ember.run.debounce(this, this.actions.save, 1000);
      },
      createPostTime: function createPostTime() {
        var _this2 = this;

        var post_times = (this.get('schedule.post_times') || []).slice();
        var row = {
          id: -new Date().getTime(),
          post_time: "12:00 PM"
        };
        this.get('days').forEach(function (day) {
          row[day.id] = !!_this2.get('schedule.' + day.id);
        });
        post_times.pushObject(Object.create(row));
        this.set('schedule.post_times', post_times);
        Ember.run.debounce(this, this.actions.save, 1000);
      },
      save: function save() {
        var _this3 = this;

        var schedule = this.get('schedule');
        if (!schedule.hasBufferedChanges) {
          return;
        }
        schedule.applyBufferedChanges();
        schedule.content.save().then(function () {
          _this3.get('esAjax').ajax({
            method: 'POST',
            url: '/v1/social_queues/' + schedule.content.id + '/reschedule_posts.json'
          });
          _this3.get('segment').track('update_schedule', schedule.content.toJSON());
        }, function () {
          _this3.toast.error('Error Updating Schedule');
        });
      }
    }

  });
});