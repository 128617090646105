define('es-frontend/utils/icons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    add_circle: '\uE900',
    admin: '\uE901',
    analytics: '\uE902',
    arrow_down: '\uE903',
    arrow_up: '\uE904',
    arrows_up_down: '\uE905',
    business_card: '\uE906',
    calendar: '\uE907',
    camera: '\uE908',
    check: '\uE909',
    click: '\uE90A',
    close: '\uE90B',
    delete: '\uE90C',
    edit: '\uE90D',
    email: '\uE90E',
    engagement: '\uE90F',
    error: '\uE910',
    extras: '\uE911',
    facebook_groups: '\uE912',
    facebook: '\uE913',
    gplus: '\uE914',
    help: '\uE915',
    influencer: '\uE916',
    left: '\uE917',
    link: '\uE918',
    linkedin_groups: '\uE919',
    linkedin: '\uE91A',
    logout: '\uE91B',
    menu: '\uE91C',
    notifications: '\uE91D',
    options: '\uE91E',
    people: '\uE91F',
    person: '\uE920',
    queue: '\uE921',
    right: '\uE922',
    rotate_cw: '\uE923',
    salesforce: '\uE924',
    search: '\uE925',
    settings: '\uE926',
    share: '\uE927',
    skeleton: '\uE928',
    star: '\uE929',
    streams: '\uE92A',
    thumb_down: '\uE92B',
    thumb_up: '\uE92C',
    twitter: '\uE92D',
    upload: '\uE92E',
    video: '\uE92F',
    xing: '\uE930',
    share2: '\uEA82'
  };
});