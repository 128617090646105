define('es-frontend/utils/read-img', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = readImg;
  function readImg(obj) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (obj && obj.files && obj.files.length) {
        readFile(obj.files[0], resolve, reject);
      } else {
        reject();
      }
    });
  }

  function readFile(file, resolve, reject) {
    if (!file.type.startsWith('image/')) {
      reject('File was not an image');
      return;
    } else if (!window.FileReader) {
      reject('File upload is not supported');
      return;
    }
    var reader = new FileReader();
    reader.onloadend = function () {
      parseImg(reader.result, resolve, reject);
    };
    reader.onerror = function () {
      reject('There was an error reading the file!');
    };
    reader.readAsDataURL(file);
  }

  function parseImg(src, resolve, reject) {
    var img = new Image();
    img.onload = function () {
      if (img.width > 7680 || img.height > 4320) {
        var scale = Math.min(7680 / img.width, 4320 / img.height);
        var destWidth = Math.round(img.width * scale);
        var destHeight = Math.round(img.height * scale);
        var canvas = document.createElement('canvas');
        canvas.width = destWidth;
        canvas.height = destHeight;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, destWidth, destHeight);
        var format = src.substring(5, src.indexOf(';', 5));
        var dataUrl = canvas.toDataURL(format || "image/jpeg");
        parseImg(dataUrl, resolve, reject);
      } else {
        resolve(img);
      }
    };
    img.onerror = function () {
      reject('There was an error parsing the file!');
    };
    img.src = src;
  }
});