define('es-frontend/services/airbrake', ['exports', 'ember-cli-airbrake/services/airbrake'], function (exports, _airbrake) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _airbrake.default;
    }
  });
});