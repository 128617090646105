define('es-frontend/components/comment-stream', ['exports', 'es-frontend/utils/find-nested', 'es-frontend/utils/flatten-nested'], function (exports, _findNested, _flattenNested) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    commentService: Ember.inject.service('comment'),
    classNames: ['comment-stream', 'col', 'pad'],
    segment: Ember.inject.service('segment'),
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    comments: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    compressed: true,
    commentTextFocus: true,

    visibleComments: Ember.computed('comments', 'compressed', function () {

      var comments = (0, _flattenNested.default)(this.get('comments'), 'comments', []);

      if (!this.get('compressed')) {
        return comments.filter(function (comment) {
          return !comment.deleted || comment.has_children;
        });
      }

      //We find the three with the highest ids. (since we dont have dates to work with)
      var retainIds = comments.filter(function (comment) {
        return !comment.deleted;
      }).map(function (comment) {
        return comment.id;
      }).sort(function (a, b) {
        return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
      }).slice(-3);

      return comments.filter(function (comment) {
        return retainIds.indexOf(comment.id) >= 0;
      });
    }),

    _count: function _count(comments) {
      var _this = this;

      if (!comments) {
        return 0;
      }
      var ret = 0;
      comments.forEach(function (comment) {
        if (!comment.deleted) {
          ret++;
        }
        ret += _this._count(comment.comments);
      });
      return ret;
    },


    actions: {
      postComment: function postComment(text, parent_id) {
        var _this2 = this;

        this.get('segment').track('add_comment', {
          commentable_type: this.get('commentable_type'),
          commentable_id: this.get('commentable_id'),
          parent_id: parent_id
        });
        return this.get('commentService').post(this.get('commentable_type'), this.get('commentable_id'), text, parent_id).then(function (comment) {
          var comments = _this2.get('comments').slice();
          if (parent_id == null) {
            comments.push(comment);
          } else {
            var nested = (0, _findNested.default)(comments, parent_id, 'comments');
            var nestedComments = nested.comments ? nested.comments.slice() : [];
            nestedComments.push(comment);
            Ember.set(nested, 'comments', nestedComments);
          }
          _this2.setProperties({
            comments: comments,
            comments_count: _this2.get('comments_count') + 1,
            showComment: false
          });
        });
      },
      removeComment: function removeComment(id) {
        var _this3 = this;

        return this.get('commentService').remove(id).then(function () {
          var comments = _this3.get('comments').slice();
          var nested = (0, _findNested.default)(comments, id, 'comments');
          Ember.set(nested, 'deleted', true);
          _this3.setProperties({
            comments: comments,
            comments_count: _this3.get('comments_count') - 1
          });
          _this3.rerender();
        });
      },
      updateComment: function updateComment(id, text) {
        var _this4 = this;

        return this.get('commentService').update(id, text).then(function (result) {
          var comments = _this4.get('comments').slice();
          var nested = (0, _findNested.default)(comments, id, 'comments');
          Ember.set(nested, 'rich_text', text);
          Ember.set(nested, 'updated_at', result.updated_at);
          _this4.set('comments', comments);
        });
      },
      loadReplies: function loadReplies(id) {
        var _this5 = this;

        this.get('commentService').find(id).then(function (comment) {
          var comments = _this5.get('comments').slice();
          var nested = (0, _findNested.default)(comments, id, 'comments');
          Ember.set(nested, 'comments', comment.comments);
          _this5.set('comments', comments);
        });
      }
    }

  });
});