define('es-frontend/components/remaining-time', ['exports', 'moment', 'es-frontend/utils/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    delta: 3600000,

    remaining: Ember.computed('end', function () {
      var remaining = Math.round(this._remaining() / 1000);
      if (remaining <= 0) {
        return 'Leaderboard Ended: ' + (0, _moment.default)(this.get('end')).format(_constants.default.VISIBLE_DATE);
      }
      var ret = [];

      var days = Math.floor(remaining / 86400);
      if (days) {
        ret.push(days, ' ', days > 1 ? 'Days' : 'Day');
      }

      var hours = Math.floor((remaining - days * 86400) / 3600);
      if (hours) {
        if (ret.length) {
          ret.push(', ');
        }
        ret.push(hours, ' ', hours > 1 ? 'Hours' : 'Hour');
      }

      ret.push(' Left');

      return ret.join('');
    }),

    didInsertElement: function didInsertElement() {
      this._setTimer();
    },
    willDestroyElement: function willDestroyElement() {
      window.clearTimeout(this.get('_timeout'));
    },


    endObserver: Ember.observer('end', function () {
      this._setTimer();
    }),

    _remaining: function _remaining() {
      return new Date(this.get('end')).getTime() - new Date().getTime();
    },
    _iterate: function _iterate() {
      this.notifyPropertyChange('end');
      this._setTimer();
    },
    _setTimer: function _setTimer() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      window.clearTimeout(this.get('_timeout'));
      var remaining = this._remaining();
      if (remaining > 0) {
        this.set('_timeout', window.setTimeout(this._iterate.bind(this), remaining % this.get('delta')));
      }
    }
  });
});