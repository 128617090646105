define('es-frontend/components/reaction-popup', ['exports', 'es-frontend/utils/reaction-types'], function (exports, _reactionTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    reactionTypes: _reactionTypes.default,
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    myReactions: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    actions: {
      toggleReaction: function toggleReaction(reactionType) {
        var reaction = this.get('myReactions').findBy('status', reactionType);
        if (reaction) {
          this.removeReaction(reaction);
        } else {
          this.addReaction(reactionType);
        }
        Ember.$(document.body).click(); // simulate outside click to close
      }
    }
  });
});