define('es-frontend/adapters/activity', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    esAjax: Ember.inject.service('es-ajax'),

    query: function query(store, modelClass, _query) {
      var _this = this;

      var data = {
        page: _query.page,
        per_page: _query.size || 20,
        start_date: _query.start_date,
        end_date: _query.end_date
      };
      var sort = _query.sort;
      if (sort) {
        data.sort_field = sort;
        data.sort_direction = _query.direction || 'asc';
      }
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('esAjax').ajax({
          url: '/reports/contents.json',
          data: data
        }).then(function (additionalActivity) {
          var results = additionalActivity.results;
          results.meta = additionalActivity.paging;
          resolve(results);
        }, reject);
      });
    }
  });
});