define('es-frontend/components/es-tooltip', ['exports', 'es-frontend/utils/absolute-position-of'], function (exports, _absolutePositionOf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    position: 'top', //top|right|bottom|left
    perpendicularOffset: 10,
    parallelOffset: 0,
    modal: false,
    open: false,
    outsideClickCloses: true,
    popupClass: 'popup bg1',
    underlayClass: 'donut',

    outerClass: Ember.computed('position', function () {
      var ret = ['es-popup', 'es-tooltip', this.get('position')];
      return ret.join(' ');
    }),

    isFixed: function isFixed(jq) {
      if (jq.css('position') == 'fixed') {
        return true;
      } else if (jq.prop('tagName') == 'BODY') {
        return false;
      } else {
        return this.isFixed(jq.parent());
      }
    },
    _layoutUnderlay: function _layoutUnderlay(underlay) {
      var anchor = Ember.$(this.get('anchor')).first();
      if (!anchor.length) {
        underlay.css({
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: 30
        });
        return;
      }
      var position = (0, _absolutePositionOf.default)(anchor);
      underlay.css({
        position: 'fixed',
        left: position.left,
        top: position.top,
        width: anchor.outerWidth(),
        height: anchor.outerHeight(),
        zIndex: 30
      });
    },


    actions: {
      _layoutPopup: function _layoutPopup(tooltip) {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        var anchor = Ember.$(this.get('anchor')).first();
        if (!anchor.length) {
          tooltip.css({
            transform: 'translate(-50%,-50%)'
          });
          tooltip.find('.arrow').css({
            display: 'none'
          });
          return;
        }
        var position = (0, _absolutePositionOf.default)(anchor);
        var perpendicularOffset = this.get('perpendicularOffset');
        var parallelOffset = this.get('parallelOffset');
        var ttLeft = position.left;
        var ttTop = position.top;
        var arrowLeft = tooltip.width() / 2;
        var arrowTop = tooltip.height() / 2;

        switch (this.get('position')) {
          case 'top':
            ttLeft += (anchor.outerWidth() - tooltip.outerWidth()) / 2 + parallelOffset;
            ttTop -= tooltip.height() + perpendicularOffset;
            arrowTop = tooltip.height();
            arrowLeft -= parallelOffset;
            break;
          case 'right':
            ttLeft += anchor.outerWidth() + perpendicularOffset;
            ttTop += (anchor.height() - tooltip.height()) / 2 + parallelOffset;
            arrowLeft = 0;
            arrowTop -= parallelOffset;
            break;
          case 'bottom':
            ttLeft += (anchor.outerWidth() - tooltip.outerWidth()) / 2 + parallelOffset;
            ttTop += anchor.height() + perpendicularOffset;
            arrowTop = 0;
            arrowLeft -= parallelOffset;
            break;
          case 'left':
            ttLeft -= tooltip.outerWidth() + perpendicularOffset;
            ttTop += (anchor.height() - tooltip.height()) / 2 + parallelOffset;
            arrowLeft = tooltip.outerWidth();
            arrowTop -= parallelOffset;
        }

        if (ttLeft < 0) {
          arrowLeft += ttLeft;
          ttLeft = 0;
        }

        if (ttTop < 0) {
          arrowTop += ttTop;
          ttTop = 0;
        }

        var overflowX = ttLeft + tooltip.width() - Ember.$(window).width();
        if (overflowX > 0) {
          ttLeft -= overflowX;
          arrowLeft += overflowX;
        }

        tooltip.css({
          position: this.isFixed(anchor) ? 'fixed' : 'absolute',
          left: ttLeft,
          top: ttTop
        });

        tooltip.find('.arrow').css({
          left: arrowLeft,
          top: arrowTop
        });
      },
      _layoutUnderlay: function _layoutUnderlay(underlay) {
        this._layoutUnderlay(underlay);
      }
    }
  });
});