define('es-frontend/components/load-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    loadingMsg: 'Loading...',
    infiniteScroll: false,
    content: false,
    loading: false,
    done: false,
    error: null,
    iconSize: 48,
    errorMsg: Ember.computed.alias('error'),
    loadPadding: 0,
    customLoad: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('infiniteScroll')) {
        this._onScroll = this._onScroll.bind(this); // make sure this pointer is correct in jquery
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.get('infiniteScroll')) {
        //Need to use capture here as scroll events do not normally bubble
        window.addEventListener('scroll', this._onScroll, true);
        //$(this.element).parents().on('scroll', this._onScroll);
      }
      if (!this.content) {
        Ember.run.later(this, this._startLoading);
      }
    },
    willDestroyElement: function willDestroyElement() {
      //$(this.element).parents().off('scroll', this._onScroll);
      window.removeEventListener('scroll', this._onScroll, true);
      this._onScroll = null;
    },
    _startLoading: function _startLoading() {
      var _this = this;

      if (this.loading || this.done) {
        return;
      }
      this.set('loading', true);
      this.load().then(function (done) {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        _this.setProperties({
          loading: false,
          content: true,
          done: !!done
        });
        if (done) {
          window.removeEventListener('scroll', _this._onScroll, true);
        } else if (_this.get('infiniteScroll')) {
          Ember.run.later(_this, _this._onScroll);
        }
      }, function (error) {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        _this.setProperties({
          loading: false,
          content: false,
          error: error
        });
      });
    },
    _onScroll: function _onScroll() {
      if (this.isDestroying || this.isDestroyed || this.loading || this.error || this.done) {
        return; // do nothing if already loading we ignore this event
      }

      //If a parent element has not scrolled all the way to the bottom we ignore this event
      var element = Ember.$(this.element);
      while (element[0].tagName != 'HTML') {
        var e = element[0];
        if (e.scrollTop + e.clientHeight + this.get('loadPadding') < e.scrollHeight) {
          return;
        }
        element = element.offsetParent();
      }

      element = document.scrollingElement || document.documentElement;
      if (element.scrollTop + window.innerHeight + this.get('loadPadding') < element.scrollHeight) {
        return;
      }

      this._startLoading();
    },


    //Override this to load data specific to component
    load: function load() {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        setTimeout(function () {
          reject('Default load function should be overwritten!');
        }, 2000);
      });
    },


    doneObserver: Ember.observer('done', function () {
      if (!this.done) {
        Ember.run.later(this, this._startLoading);
      }
    })

  });
});