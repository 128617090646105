define('es-frontend/components/user-notifications-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service('store'),
    ajaxService: Ember.inject.service('es-ajax'),
    open: false,
    anchor: null,
    position: 'bottom',
    title: Ember.computed('notifications', function () {
      var count = this.get('notifications.length');
      switch (count) {
        case 0:
          return 'No New Notifications';
        case 1:
          return '1 New Notification';
        default:
          return count + ' New Notifications';
      }
    }),

    actions: {
      loadMore: function loadMore() {
        var _this = this;

        return this.get('store').findAll('user-notification').then(function (notifications) {
          _this.set('notifications', notifications);
          _this.get('ajaxService').ajax({
            url: '/mark_as_read.json',
            method: 'POST',
            data: {
              type: 'notifications'
            }
          }).then(function () {
            _this.set('notifications_count', 0);
          });
        });
      }
    }
  });
});