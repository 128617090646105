define('es-frontend/router', ['exports', 'es-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('client', function () {
      this.route('queue', function () {
        this.route('queued');
        this.route('history');
        this.route('settings');
      });
      this.route('analytics');
      this.route('help');
      this.route('leaderboard');
      this.route('streams', function () {
        this.route('stream', { path: 'stream/:id' });
        this.route('topic', { path: 'topic/:id' });
        this.route('personal', { path: 'personal/:id' });
      });
      this.route('manage-streams');
      this.route('article', { path: 'article/:id' });
      this.route('profile', { path: 'profile/:id' });
      this.route('share');
      this.route('notifications');
      this.route('extras');
    });
    this.route('signin', function () {
      this.route('login');
      this.route('user-details');
      this.route('set-password');
      this.route('request-access');
      this.route('forgot-password');
      this.route('sso');
      this.route('safe-to-close');
      this.route('signup', function () {
        this.route('set-password');
        this.route('user-details');
        this.route('user-tags');
      });
    });
    this.route('remote-share');
    this.route('error-msg');
  });

  exports.default = Router;
});