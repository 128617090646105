define('es-frontend/routes/signin/signup-error', ['exports', 'es-frontend/utils/get-error-msg'], function (exports, _getErrorMsg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('errorMsg', (0, _getErrorMsg.default)(model));
    }
  });
});