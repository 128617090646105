define('es-frontend/services/query', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    q: '',
    segment: Ember.inject.service('segment'),

    queryObserver: Ember.observer('q', function () {
      //console.log('QUERY UPDATED', this.get('q'));
      var q = this.get('q');
      this.get('segment').track(q ? 'query_updated' : 'query_cleared', {
        q: q
      });
    })

  });
});