define('es-frontend/services/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    toast: Ember.inject.service('toast'),
    airbrake: Ember.inject.service('airbrake'),
    multiFrameService: Ember.inject.service('multiFrameService'),
    router: Ember.inject.service('router'),
    errorMsg: Ember.inject.service('error-msg'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.onerror = this.onError.bind(this);
      window.onerror = this.windowOnError.bind(this);
    },
    onError: function onError(error) {
      if (this.get('multiFrameService.closing') || this.skipError(error)) {
        return;
      }
      this.get('airbrake').notify(error);
      /* eslint-disable no-console */
      console.error(error);
      /* eslint-enable no-console */
      var errorMsg = 'Oops!!! It looks like something went wrong. You may need to refresh the application to continue.';
      if (document.querySelector('.ember-view')) {
        // there is some content
        this.get('toast').error(errorMsg);
      } else {
        // blank page...
        this.set('errorMsg.msg', errorMsg);
        this.get('router').transitionTo('error-msg');
      }
    },
    windowOnError: function windowOnError(message, file, line, char, error) {
      if (!error) {
        error = { message: message, fileName: file, lineNumber: line };
      }
      this.onError(error);
    },
    skipError: function skipError(error) {
      if (error && error.message) {
        if (error.message.indexOf('ResizeObserver loop limit exceeded') >= 0) {
          return true;
        }
      }
      return false;
    }
  });
});