define('es-frontend/adapters/user', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    //TODO: This is very customized - it would be nice to get Ember to send patch requests and to
    //have the results in an understandable format.
    updateRecord: function updateRecord(store, type, snapshot) {
      var changedAttributes = snapshot.changedAttributes();
      for (var key in changedAttributes) {
        changedAttributes[key] = changedAttributes[key][1];
      }
      changedAttributes.email = snapshot.attributes().email;
      return this.ajax('/users/' + snapshot.id + '.json', 'PATCH', {
        contentType: 'application/json',
        data: JSON.stringify(changedAttributes)
      });
    }
  });
});