define('es-frontend/services/multi-frame-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this._eventCallback = this._eventCallback.bind(this);
      window.addEventListener('message', this._eventCallback);
    },
    announceLoad: function announceLoad() {
      if (window.opener) {
        window.opener.postMessage(JSON.stringify({ type: 'esLoad' }), '*');
      }
    },
    _eventCallback: function _eventCallback(event) {
      try {
        var msg = typeof event.data == 'string' ? JSON.parse(event.data) : event.data;
        switch (msg.type) {
          case 'esClose':
            // parent frame asked us to close
            this.set('closing', true);
            window.close();
            break;
          default:
        }
      } catch (e) {
        //Unknown message
        /* eslint-disable no-console */
        //console.log(e);
        /* eslint-enable no-console */
      }
    }
  });
});