define('es-frontend/services/es-ajax', ['exports', 'es-frontend/config/environment', 'es-frontend/utils/cross-origin'], function (exports, _environment, _crossOrigin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    restRootPath: null,
    currentUserPath: null,
    token: null,
    taskService: Ember.inject.service('tasks'),
    ajaxService: Ember.inject.service('ajax'),

    init: function init() {
      this._super.apply(this, arguments);

      //if they share a common superdomain, just use rootUrl.. (Saves on XSS confusion)
      var restRootPath = _environment.default.APP.restRootURL;

      var parts = restRootPath.split('//');
      if (parts[0] == location.protocol) {
        var restHost = parts[1].split('/')[0].split('.');
        var currentHost = location.host.split('.');
        if (currentHost.length > 1 && currentHost.length == restHost.length && currentHost[0] != 'ember') {
          restHost.splice(0, 1);
          currentHost.splice(0, 1);
          if (currentHost.join('.') == restHost.join('.')) {
            restRootPath = location.protocol + '//' + location.host + '/';
          }
        }
      }

      this.set('restRootPath', restRootPath);
    },
    ajax: function ajax(options) {
      var _this = this;

      var ret = new Ember.RSVP.Promise(function (resolve, reject) {
        /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
        var opts = Ember.$.extend({
          dataType: 'json',
          data: options.data,
          userPath: true,
          resolveUrl: function resolveUrl(root) {
            return root + options.url;
          }
        }, options);
        /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
        var url = opts.resolveUrl(_this.get(opts.userPath ? 'currentUserPath' : 'restRootPath'));
        (0, _crossOrigin.default)(opts, url, _this.get('token'));
        var taskService = _this.get('taskService');
        var taskId = taskService.start(url);
        var promise = _this.get('ajaxService').request(url, opts);
        promise.then(function () {
          taskService.finish(taskId);
          ret.promise = promise;
          resolve.apply(this, arguments);
        }, function (response) {
          if (opts.data && (!opts.method || opts.method == 'GET')) {
            url = url + '?' + Ember.$.param(opts.data);
          }
          if (response) {
            if (response.status == 403 || response.payload && response.payload.error == "HTTP Token: Access denied.") {
              location.href = location.protocol + '//' + location.host + location.pathname;
              return;
            } else if (response.message == 'Resource was not found.') {
              response.message = 'Resource was not found: ' + url;
            }
          }
          taskService.finish(taskId);
          reject.apply(this, arguments);
        });
      });
      return ret;
    }
  });
});