define('es-frontend/initializers/add-modals-element', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var rootEl = document.querySelector(application.rootElement);
    var modalContainerEl = document.createElement('div');
    var modalContainerElId = 'modals';
    modalContainerEl.id = modalContainerElId;
    rootEl.appendChild(modalContainerEl);
  }

  exports.default = {
    initialize: initialize
  };
});