define('es-frontend/controllers/signin/signup/set-password', ['exports', 'es-frontend/utils/get-error-msg'], function (exports, _getErrorMsg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    segment: Ember.inject.service('segment'),
    email: Ember.computed.alias('model.user.email'),
    password: null,
    passwordValid: true,
    termsAccepted: false,
    termsValid: true,

    passwordClass: Ember.computed('passwordValid', function () {
      var ret = ['input-container', 'col'];
      if (!this.get('passwordValid')) {
        ret.push('error');
      }
      return ret.join(' ');
    }),

    passwordObserver: Ember.observer('password', function () {
      this.set('passwordValid', true);
    }),

    termsLabelClass: Ember.computed('termsValid', function () {
      return 'col-center ' + (this.get('termsValid') ? 'txt3' : 'error');
    }),

    termsAcceptedObserver: Ember.observer('termsAccepted', function () {
      this.set('termsValid', true);
    }),

    customerTerms: Ember.computed('model.terms.{terms_conditions,terms_conditions_enabled}', function () {
      return this.get('model.terms.terms_conditions_enabled') ? this.get('model.terms.terms_conditions') : null;
    }),

    actions: {
      setPassword: function setPassword() {
        var _this = this;

        var userData = {
          verification_code: this.get('model.user.verification_code'),
          signup_step: 1,
          email: this.get('email'),
          accepted_conditions: true,
          conditions_response_timestamp: new Date().toString()
        };

        var password = this.get('password');
        if (!this.get('model.customer.sso_url')) {
          if (!password) {
            this.setProperties({
              errorMsg: 'Password must not be blank!',
              passwordValid: false,
              termsValid: true
            });
            return;
          }
          userData.password = password;
        }

        if (!this.get('termsAccepted')) {
          this.setProperties({
            errorMsg: 'You must accept the terms in order to continue!',
            passwordValid: true,
            termsValid: false
          });
          return;
        }

        var userId = this.get('model.user.id');
        this.get('ajaxService').ajax({
          method: 'PUT',
          userPath: false,
          url: 'users/' + userId + '.json',
          data: {
            user: userData
          }
        }).then(function () {
          if (_this.get('model.customer.sso_url')) {
            _this.transitionToRoute('signin.signup.user-details');
            return;
          }
          //login
          _this.get('ajaxService').ajax({
            method: 'POST',
            userPath: false,
            url: 'sessions.json',
            data: {
              email: _this.get('email'),
              password: password
            }
          }).then(function () {
            _this.get('segment').track('signin_signup_set_password_success');
            _this.transitionToRoute('signin.signup.user-details');
          });
        }, function (status) {
          _this.setProperties({
            errorMsg: (0, _getErrorMsg.default)(status) || 'Error!'
          });
        });
      }
    }

  });
});