define('es-frontend/controllers/client/share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    statusParser: Ember.inject.service('status-parser'),
    store: Ember.inject.service('store'),
    url: null,
    curated_article_id: null,

    actions: {
      clearUrl: function clearUrl() {
        if (this.get('curated_article_id')) {
          history.back();
          return;
        }
        this.setProperties({
          url: null,
          urlOrCustomMessage: null
        });
      },
      parseStatus: function parseStatus() {
        var _this = this;

        this.get('statusParser').parse(this.get('urlOrCustomMessage') || '').then(function (status) {
          if (status.url) {
            _this.setProperties(status);
          } else if (status.message) {
            _this.get('store').findAll('social_queue').then(function (social_queues) {
              if (social_queues.get('length')) {
                _this.set('model.article', {
                  tweet_text: status.message,
                  fb_commentary: status.message
                });
              } else {
                _this.get('toast').error('No URL found in content.');
              }
            });
          }
        }, function (error) {
          throw error;
        });
      }
    }
  });
});