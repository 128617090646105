define('es-frontend/components/scorecard-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['scorecard-summary', 'row', 'pad-bottom', 'pad-right']
  });
});