define('es-frontend/models/social-queue', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    profile_name: _emberData.default.attr('string'),
    nickname: _emberData.default.attr('string'),
    service: _emberData.default.attr('string'),
    profile_pic_url: _emberData.default.attr('string'),
    profile_image_url: Ember.computed.alias('profile_pic_url'), // This is what it is called literally everywhere else
    oauth_access_token_id: _emberData.default.attr('number'),
    page_and_group_id: _emberData.default.attr('number'),
    followers_count: _emberData.default.attr('number'),
    invalid_token: _emberData.default.attr('boolean')
  });
});