define('es-frontend/controllers/client/extras', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    widthService: Ember.inject.service('width'),
    browser: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (window.chrome) {
        this.set('browser', 'Chrome');
      } else if (window.opr && window.opr.addons || window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
        this.set('browser', 'Opera');
      } else if (typeof InstallTrigger !== 'undefined') {
        this.set('browser', 'Firefox');
      } else if (/constructor/i.test(window.HTMLElement) || function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      }(!window['safari'] || typeof window.safari !== 'undefined' && window.safari.pushNotification)) {
        this.set('browser', 'Safari');
      } else if ( /*@cc_on!@*/false || !!document.documentMode) {
        this.set('browser', 'Internet Explorer');
      } else if (window.StyleMedia) {
        this.set('browser', 'Edge');
      }
    },


    isChrome: Ember.computed('browser', function () {
      return this.get('browser') == 'Chrome';
    }),

    bookmarklet: Ember.computed('browser', function () {
      return "(function(d){var url='" + location.protocol + "//" + location.host + "/es_frontend#/remote-share?url='+encodeURIComponent(location.href)+'&closeWinOnFinish=true';var width=600;var height=800;var left=Math.round(((window.screenLeft==null)?window.screenLeft:window.screenX)+((window.outerWidth-width)/2));var top=Math.round(((window.screenTop==null)?window.screenTop:window.screenY)+((window.outerHeight-height)/2));var options='location=1,toolbar=0,scrollbars=1,width='+width+',height='+height+',top='+top+',left='+left;window.open(url,'window name',options);})(document)";
    }),

    actions: {
      installChrome: function installChrome() {
        if (this.get('isChrome')) {
          window.open('https://chrome.google.com/webstore/detail/everyonesocial/nmmhjbplcdnocnkjppnefkjhaogfceoe');
        }
      }
    }
  });
});