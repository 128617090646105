define('es-frontend/components/leaderboard-summary', ['exports', 'es-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['leaderboard-summary', 'col'],
    ajaxService: Ember.inject.service('es-ajax'),
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    social_queues: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    reach: Ember.computed('social_queues', function () {
      var ret = 0;
      this.get('social_queues').forEach(function (social_queue) {
        ret += social_queue.get('followers_count');
      });
      return ret;
    }),

    twitter: Ember.computed('social_queues', function () {
      return (this.get('social_queues') || []).findBy('service', 'twitter');
    }),

    facebook: Ember.computed('social_queues', function () {
      return (this.get('social_queues') || []).findBy('service', 'facebook');
    }),

    linkedin: Ember.computed('social_queues', function () {
      return (this.get('social_queues') || []).findBy('service', 'linkedin');
    }),

    xing: Ember.computed('social_queues', function () {
      return (this.get('social_queues') || []).findBy('service', 'xing');
    }),

    connectTwitter: Ember.computed('ajaxService.restRootPath', function () {
      return _environment.default.APP.restRootURL + 'auth/twitter?force_login=true&ember_callback=' + encodeURIComponent(location.hash);
    }),

    connectFacebook: Ember.computed('ajaxService.restRootPath', function () {
      return _environment.default.APP.restRootURL + 'auth/facebook?ember_callback=' + encodeURIComponent(location.hash);
    }),

    connectLinkedin: Ember.computed('ajaxService.restRootPath', function () {
      return _environment.default.APP.restRootURL + 'auth/linkedin?ember_callback=' + encodeURIComponent(location.hash);
    }),

    connectXing: Ember.computed('ajaxService.restRootPath', function () {
      return _environment.default.APP.restRootURL + 'auth/xing?ember_callback=' + encodeURIComponent(location.hash);
    })
  });
});