define('es-frontend/controllers/signin/safe-to-close', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      foo: function foo() {
        debugger;
      }
    }
  });
});