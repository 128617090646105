define('es-frontend/utils/asset-url', ['exports', 'es-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = assetUrl;
  function assetUrl() {
    var url = _environment.default.APP.apiRootURL;
    if (!url) {
      return '/';
    }
    return url + (url.endsWith('/') ? '' : '/') + 'assets';
  }
});