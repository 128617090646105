define('es-frontend/components/social-connect-link', ['exports', 'es-frontend/utils/ddau', 'es-frontend/config/environment'], function (exports, _ddau, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['social-connect-link'],
    store: Ember.inject.service('store'),
    ajaxService: Ember.inject.service('es-ajax'),
    session_param: null,
    authenticating: false,
    // need to store like this because cross origin blocks when we try to do a
    // .get('opened') because 'get' tries to access attributes of the resultant
    // object before returning it
    openedHolder: null,
    segment: Ember.inject.service('segment'),

    init: function init() {
      this._super.apply(this, arguments);
      this._childLoadCallback = this._childLoadCallback.bind(this);
      this.set('openedHolder', {});
    },


    href: Ember.computed('service', 'session_param', function () {
      var service = this.get('service');
      var force_login = service == 'twitter';
      var ember_callback = encodeURIComponent('?parentComponentId=' + this.elementId);
      var session_param = this.get('session_param');
      return _environment.default.APP.restRootURL + 'services/new?ember_callback=' + ember_callback + '&service=' + service + (force_login ? '&force_login=true' : '') + '&session_param=' + session_param;
    }),

    didInsertElement: function didInsertElement() {
      window.addEventListener('message', this._childLoadCallback);
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('message', this._childLoadCallback);
      this._childLoadCallback = null;
    },
    click: function click() {
      var _this = this;

      if (this.get('service')) {
        this.get('segment').track('initiate_' + this.get('service'));
        //Load the session token from the endpoint
        this.get('ajaxService').ajax({
          url: 'session_param.json',
          userPath: false,
          async: false
        }).then(function (result) {
          _this.set('session_param', result.session_param);
          _this.set('authenticating', true);
          _this.openedHolder.opened = window.open(_this.get('href'));
        });
        return;
      }

      //Weird error case - just delete the social_queue
      var queues = [];
      this.get('queues').forEach(function (queue) {
        if (queue.get('service')) {
          queues.push(queue);
        } else {
          queue.deleteRecord();
          queue.save();
        }
      });
      this.set('queues', queues);
    },
    _childLoadCallback: function _childLoadCallback(event) {
      try {
        var msg = typeof event.data == 'string' ? JSON.parse(event.data) : event.data;
        switch (msg.type) {
          case 'esLoad':
            if (!this.openedHolder.opened) {
              return;
            }
            this.openedHolder.opened.postMessage(JSON.stringify({ type: 'esClose' }), '*');
            this.actions.authenticated.call(this);
            break;
          default:
        }
      } catch (e) {
        //Unknown message
        /* eslint-disable no-console */
        //console.log(e);
        /* eslint-enable no-console */
      }
    },


    actions: {
      queuesChanged: _ddau.default.attr('queues'),

      authenticated: function authenticated() {
        var _this2 = this;

        this.set('authenticating', false);
        this.openedHolder.opened = null;
        this.get('store').findAll('social-queue', { reload: true }).then(function (queues) {
          queues = queues.toArray();
          _this2.get('segment').track('success_' + _this2.get('service'), queues[queues.length - 1].toJSON());
          _this2.actions.queuesChanged.call(_this2, queues);
        });
      }
    }
  });
});