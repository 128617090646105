define('es-frontend/adapters/curated-topic', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindAll: function urlForFindAll() {
      return '/curated_topics.json';
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      return this.get('esAjax').ajax({
        url: '/curated_topics/' + snapshot.id + '/toggle_subscription.json',
        method: 'POST',
        data: snapshot.attributes()
      });
    }
  });
});