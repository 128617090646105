define('es-frontend/services/comment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    findForCommentable: function findForCommentable(commentable_type, commentable_id) {
      return this.get('ajaxService').ajax({
        url: '/comments.json',
        data: {
          commentable_type: commentable_type,
          commentable_id: commentable_id
        }
      });
    },
    find: function find(comment_id) {
      return this.get('ajaxService').ajax({
        url: '/comments/' + comment_id + '.json'
      });
    },
    post: function post(commentable_type, commentable_id, text, parent_id) {

      var data = {
        comment: {
          commentable_type: commentable_type,
          commentable_id: commentable_id,
          text: text,
          parent_id: parent_id
        },
        user_ids: [],
        group_ids: []
      };

      //For notifications we need to parse out the ids here and pass them up to the server
      var index = 0;
      /* eslint-disable no-constant-condition */
      while (true) {
        var nameStart = text.indexOf('@[', index);
        var nameEnd = text.indexOf('](', nameStart);
        var idEnd = text.indexOf(')', nameEnd);
        if (nameStart < 0 || nameEnd < 0 || idEnd < 0) {
          break;
        }
        var parts = text.substring(nameEnd + 2, idEnd).split(':');
        data[parts[0].trim().toLowerCase() + '_ids'].push(parseInt(parts[1].trim()));
        index = idEnd + 1;
      }
      /* eslint-enable ember/no-on-calls-in-components */

      return this.get('ajaxService').ajax({
        method: 'POST',
        url: '/comments.json',
        contentType: 'application/json',
        data: JSON.stringify(data)
      });
    },
    remove: function remove(comment_id) {
      return this.get('ajaxService').ajax({
        method: 'DELETE',
        url: '/comments/' + comment_id + '.json',
        contentType: 'application/json'
      });
    },
    update: function update(comment_id, text) {
      return this.get('ajaxService').ajax({
        method: 'PATCH',
        url: '/comments/' + comment_id + '.json',
        contentType: 'application/json',
        data: {
          comment: {
            id: comment_id,
            text: text
          }
        }
      });
    },


    //TODO: Maybe this belongs in its own service
    tagSearch: function tagSearch(query) {
      return this.get('ajaxService').ajax({
        url: '/search.json',
        data: {
          q: query
        }
      });
    }
  });
});