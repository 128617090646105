define('es-frontend/utils/abstract-stream-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    queryService: Ember.inject.service('query'),
    id: null,
    q: Ember.computed.alias('queryService.q'),
    page: null,
    per_page: 7,
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    articles: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    loading: false,
    done: false,
    type: 'curated',

    load: function load() {
      var _this = this;

      window.clearInterval(this.get('handlingStale'));
      this.set('handlingStale', null);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.loadFromServer().then(function (result) {
          var articles = _this.articles.slice();
          articles.push.apply(articles, result.articles);
          var done = result.articles.length < _this.per_page;
          _this.setProperties({
            articles: articles,
            done: done
          });
          resolve(done);

          if (result.status == 206) {
            //Load additionals here!
            _this.set('handlingStale', window.setInterval(_this.handleStale.bind(_this), 10000));
          }
        }, reject);
      });
    },
    loadFromServer: function loadFromServer() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var promise = _this2.get('ajaxService').ajax({
          url: '/contents.json',
          data: {
            cs: _this2.id,
            type: _this2.get('type'),
            q: _this2.get('q'),
            page: _this2.page,
            per_page: _this2.per_page
          }
        });
        promise.then(function (loadedArticles) {
          resolve({
            articles: loadedArticles,
            status: promise.promise.xhr.status
          });
        }, reject);
      });
    },
    handleStale: function handleStale() {
      var _this3 = this;

      this.loadFromServer().then(function (result) {
        if (!_this3.get('handlingStale')) {
          return;
        }

        var currentArticleIds = _this3.get('articles').map(function (article) {
          return article.id;
        });
        var loadedArticleIds = result.articles.map(function (article) {
          return article.id;
        });
        var newIds = loadedArticleIds.filter(function (item) {
          return currentArticleIds.indexOf(item) < 0;
        });
        _this3.set('numNonStaleArticles', newIds.length);

        if (result.status == 200) {
          window.clearInterval(_this3.get('handlingStale'));
          _this3.set('handlingStale', null);
        }
      });
    },


    actions: {
      loadNext: function loadNext() {
        if (this.loading || this.done) {
          return false;
        }
        this.incrementProperty('page');
        return this.load();
      },
      initialLoad: function initialLoad(id, q, user, customer, client, features, subscriptions) {
        this.setProperties({
          id: id,
          q: q,
          user: user,
          customer: customer,
          client: client,
          features: features,
          subscriptions: subscriptions,
          page: 0,
          articles: [],
          loading: false,
          done: false
        });
        return this.load();
      },
      refreshStale: function refreshStale() {
        this.setProperties({
          page: 0,
          articles: [],
          loading: false,
          done: false,
          numNonStaleArticles: null
        });
        return this.load();
      },
      clearQuery: function clearQuery() {
        this.set('q', '');
      }
    }
  });
});