define('es-frontend/adapters/leaderboard', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    esAjax: Ember.inject.service('es-ajax'),

    findAll: function findAll() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('esAjax').ajax({
          url: '/leaderboard_configs.json'
        }).then(function (leaderboards) {
          resolve(leaderboards);
        }, reject);
      });
    }
  });
});