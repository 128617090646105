define('es-frontend/adapters/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend({

    esAjax: Ember.inject.service('es-ajax'),
    cache_max_age: 1000 * 60 * 10, // 10 minute cache

    // We reload all if the cache has never been updated
    shouldReloadAll: function shouldReloadAll() {
      if (this.get('updated_at') == null) {
        this.set('updated_at', new Date().getTime());
        return true;
      }
      return false;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      var updated_at = this.get('updated_at');
      var now = new Date().getTime();
      if (now - updated_at > this.get('cache_max_age')) {
        this.set('updated_at', now);
        return true;
      }
      return false;
    },


    /**
     * Make sure that the namespace and host are set up correctly before allowing
     * normal ajax to continue - it sucks that we are overriding a private function,
     * but there really was no other way to do this aside from making sure the
     * bootstrap data is available before the application initializes
     */
    ajax: function ajax(url, type, options) {
      if (!options) {
        options = {};
      }
      return this.get('esAjax').ajax(Ember.$.extend({
        url: url,
        method: type
      }, options));
    },


    pathForType: function pathForType(type) {
      return Ember.String.underscore(type);
    }
  });
});