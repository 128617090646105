define('es-frontend/utils/abstract-client-route', ['exports', 'es-frontend/utils/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    taskService: Ember.inject.service('tasks'),
    ajaxService: Ember.inject.service('es-ajax'),
    pubnub: Ember.inject.service('pubnub'),
    errorMsg: Ember.inject.service('error-msg'),
    segment: Ember.inject.service('segment'),

    model: function model() {
      var _this = this,
          _arguments = arguments;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var qp = (0, _queryParams.default)();
        var data = { stats: true, leaderboard_configs: true };
        if (qp.user_id) {
          data.user_id = qp.user_id;
        }
        _this.get('ajaxService').ajax({
          url: 'slim_bootstrap.json',
          userPath: false,
          data: data
        }).then(function (slim_bootstrap) {
          try {
            //If not logged in, there is no way to continue
            if (!slim_bootstrap.logged_in) {
              _this.replaceWith('signin');
              return;
            }
            if (!slim_bootstrap.user.signup_completed && slim_bootstrap.user.signup_step < 2) {
              _this.replaceWith('signin.signup');
              return;
            }
            if (slim_bootstrap.customer && slim_bootstrap.customer.redirect) {
              location.replace(slim_bootstrap.customer.redirect);
              return;
            }

            var user = _this._doStore(slim_bootstrap.user, 'user');
            _this.get('segment').identify(user.get('email'), slim_bootstrap.user);
            var customer = _this._doStore(slim_bootstrap.customer, 'customer');
            var client = _this._doStore(slim_bootstrap.client, 'client');
            var subscriptions = _this._doStoreArray(slim_bootstrap.subscriptions, 'subscription');
            var curated_topics = _this._doStoreArray(slim_bootstrap.curated_topics, 'curated-topic');
            var leaderboards = _this._doStoreArray(slim_bootstrap.leaderboard_configs, 'leaderboard');

            _this.get('ajaxService').setProperties({
              currentUserPath: _this.get('ajaxService.restRootPath') + slim_bootstrap.current_user_path.substring(1),
              token: slim_bootstrap.token
            });
            _this._setFavicon(slim_bootstrap.customer.icon_url);

            var pubnub = null;
            //if(config.environment != 'development'){
            pubnub = _this.get('pubnub');
            pubnub.subscribe({
              channels: ['web-notification-channel-' + user.id],
              withPresence: true
            });
            //}

            _this.get('ajaxService').ajax({
              url: '/mark_as_read.json',
              method: 'POST',
              data: {
                type: 'articles'
              }
            });

            resolve(Ember.Object.create({
              user: user,
              customer: customer,
              client: client,
              subscriptions: subscriptions,
              administered_groups: slim_bootstrap.administered_groups, //TODO: This name literally has no meaning. Pick something better (like curated_streams maybe?)
              curated_topics: curated_topics.filterBy('subscribed', true),
              personal_streams: slim_bootstrap.personal_streams,
              leaderboards: leaderboards,
              stats: slim_bootstrap.stats,
              features: slim_bootstrap.features,
              closeDialog: !!qp.parentComponentId,
              pubnub: pubnub
            }));
          } catch (e) {
            reject(e);
          }
        }, function (response) {
          if (response && response.payload && response.payload.message) {
            _this.set('errorMsg.msg', response.payload.message);
            reject.call(_this, response.payload.message);
            _this.get('router').replaceWith('error-msg');
            return;
          }
          reject.apply(_this, _arguments);
        });
      });
    },
    _setFavicon: function _setFavicon(iconUrl) {
      Ember.$('link[rel=icon]').prop('href', iconUrl);
    },
    _doStore: function _doStore(obj, type) {
      return this.get('store').push({
        data: [{
          id: obj.id,
          type: type,
          attributes: obj
        }]
      })[0];
    },
    _doStoreArray: function _doStoreArray(arr, type) {
      var data = [];
      arr.forEach(function (obj) {
        data.push({
          id: obj.id,
          type: type,
          attributes: obj
        });
      });
      var store = this.get('store');
      store.adapterFor(type).set('updated_at', new Date().getTime());
      return store.push({
        data: data
      });
    }
  });
});