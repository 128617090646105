define('es-frontend/routes/signin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    widthService: Ember.inject.service('width'),

    queryParams: {
      company: {
        refreshModel: true,
        replace: false
      }
    },

    model: function model(params) {
      var _this = this;

      document.title = 'Welcome to EveryoneSocial';
      //company id can be grabbed from slug, or subdomain
      var subdomain = params.company;
      if (subdomain == null) {
        subdomain = location.hostname.split('.')[0];
        if (!subdomain || subdomain == 'app' || subdomain == 'localhost' || location.hostname.endsWith('.cloudfront.net')) {
          // we are a special case
          subdomain = 'es';
        }
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('ajaxService').ajax({
          userPath: false,
          url: 'sessions/get_customer_by_subdomain.json',
          data: {
            subdomain: subdomain,
            RelayState: location.protocol + '//' + location.host + location.pathname
          }
        }).then(function (model) {
          if (model.redirect) {
            location.replace(model.redirect);
          }
          if (model.icon_url) {
            _this._setFavicon(model.icon_url);
          }
          model.user_email = '';
          model.widthService = _this.get('widthService');
          resolve(model);
        }, reject);
      });
    },
    _setFavicon: function _setFavicon(iconUrl) {
      Ember.$('link[rel=icon]').prop('href', iconUrl);
    },
    beforeModel: function beforeModel() {
      Ember.$('html').addClass('signin-page');
    },
    deactivate: function deactivate() {
      Ember.$('html').removeClass('signin-page');
    }
  });
});