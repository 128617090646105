define('es-frontend/components/es-date-time-picker', ['exports', 'moment', 'es-frontend/utils/ddau', 'es-frontend/utils/constants', 'es-frontend/utils/absolute-position-of'], function (exports, _moment, _ddau, _constants, _absolutePositionOf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SERVER_DATE = _constants.default.SERVER_DATE;
  exports.default = Ember.Component.extend({
    classNames: ['es-date-time-picker', 'col'],
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    minutes: ['0', '5', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
    amOrPm: ['AM', 'PM'],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    //timezone: 'Eastern Time (US & Canada)',
    value: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('value')) {
        this.set('value', this.get('minMoment').call(this).format(SERVER_DATE));
      }
      if (!this.get('center')) {
        this.set('center', (0, _moment.default)(this.get('_valueMoment')));
      }
    },


    _valueMoment: Ember.computed('value', function () {
      return (0, _moment.default)(this.get('value'), SERVER_DATE);
    }),

    hour: Ember.computed('_valueMoment', function () {
      return parseInt(this.get('_valueMoment').format('h'));
    }),

    minute: Ember.computed('_valueMoment', function () {
      return (Math.round(this.get('_valueMoment').minutes() / 5) * 5).toString();
    }),

    ampm: Ember.computed('_valueMoment', function () {
      return this.get('_valueMoment').format('a').toUpperCase();
    }),

    minMoment: function minMoment() {
      var ret = (0, _moment.default)();
      ret.set({ minutes: Math.ceil((ret.minutes() + 5) / 5) * 5, seconds: 0, milliseconds: 0 });
      return ret;
    },
    maxMoment: function maxMoment() {
      return this.get('minMoment').call(this).add(1, 'year');
    },
    _updateValueMoment: function _updateValueMoment(m) {
      var min = this.get('minMoment').call(this);
      var max = this.get('maxMoment').call(this);
      if (min && m.toDate().getTime() < min.toDate().getTime()) {
        m = min;
      } else if (max && m.toDate().getTime() > max.toDate().getTime()) {
        m = max;
      }
      this.actions.valueChanged.call(this, m.format(SERVER_DATE));
    },


    hasNextMonth: Ember.computed('center', function () {
      var center = this.get('center');
      var max = this.get('maxMoment').call(this);
      return center.year() < max.year() || center.year() == max.year() && center.month() < max.month();
    }),

    hasPrevMonth: Ember.computed('center', function () {
      var center = this.get('center');
      var min = this.get('minMoment').call(this);
      return center.year() > min.year() || center.year() == min.year() && center.month() > min.month();
    }),

    compareDate: function compareDate(a, b) {
      if (a.year() != b.year()) {
        return a.year() - b.year();
      }
      if (a.month() != b.month()) {
        return a.month() - b.month();
      }
      return a.date() - b.date();
    },
    didRender: function didRender() {
      var _this = this;

      this.$('.ember-power-calendar-nav-control--previous').prop('disabled', !this.get('hasPrevMonth'))[this.get('hasPrevMonth') ? 'removeClass' : 'addClass']('disabled');
      this.$('.ember-power-calendar-nav-control--next').prop('disabled', !this.get('hasNextMonth'))[this.get('hasNextMonth') ? 'removeClass' : 'addClass']('disabled');
      var min = this.get('minMoment').call(this);
      var max = this.get('maxMoment').call(this);
      this.$('.ember-power-calendar-day--current-month').each(function (index, element) {
        var $element = Ember.$(element);
        var enabled = true;
        var date = (0, _moment.default)(Ember.$(element).data('date'), 'YYYY-MM-DD');
        if (min && _this.compareDate(date, min) < 0) {
          enabled = false;
        }
        if (max && _this.compareDate(date, max) > 0) {
          enabled = false;
        }
        $element.prop('disabled', !enabled);
      });
    },
    layoutTimePopup: function layoutTimePopup(popupElement, anchor) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var position = (0, _absolutePositionOf.default)(anchor);
      popupElement.css({
        position: 'fixed',
        left: position.left,
        top: position.top - popupElement.outerHeight(),
        width: anchor.outerWidth()
      });
    },


    actions: {
      valueChanged: _ddau.default.attr('value'),

      calendarDateChanged: function calendarDateChanged(date) {
        var m = date.moment;

        var min = this.get('minMoment').call(this);
        if (min && this.compareDate(m, min) < 0) {
          return;
        }

        var max = this.get('maxMoment').call(this);
        if (max && this.compareDate(m, max) > 0) {
          return;
        }

        this._updateValueMoment((0, _moment.default)(this.get('_valueMoment')).set({ year: m.year(), month: m.month(), date: m.date() }));
      },
      hourChanged: function hourChanged(hour) {
        if (this.get('ampm') == 'PM') {
          hour += 12;
        }
        this._updateValueMoment((0, _moment.default)(this.get('_valueMoment')).set({ hour: hour }));
      },
      minuteChanged: function minuteChanged(minute) {
        this._updateValueMoment((0, _moment.default)(this.get('_valueMoment')).set({ minute: parseInt(minute) }));
      },
      ampmChanged: function ampmChanged(ampm) {
        var value = (0, _moment.default)(this.get('_valueMoment'));
        if (ampm == 'AM') {
          value.subtract(12, 'hours');
        } else {
          value.add(12, 'hours');
        }
        this._updateValueMoment(value);
      },
      centerChanged: function centerChanged(center) {
        this.set('center', center.moment);
      },
      addCenterMonth: function addCenterMonth(amt) {
        var center = (0, _moment.default)(this.get('center')).add(amt, 'month');

        var min = this.get('minMoment').call(this);
        if (center.year() < min.year() || center.year() == min.year() && center.month() < min.month()) {
          return;
        }

        var max = this.get('maxMoment').call(this);
        if (center.year() > max.year() || center.year() == max.year() && center.month() > max.month()) {
          return;
        }

        this.set('center', center);
      },
      schedule: function schedule() {
        this.get('schedule')(this.get('value'));
      },
      layoutHourPopup: function layoutHourPopup(element) {
        this.layoutTimePopup(element, this.$('.hours'));
      },
      layoutMinutePopup: function layoutMinutePopup(element) {
        this.layoutTimePopup(element, this.$('.minutes'));
      },
      layoutAmPmPopup: function layoutAmPmPopup(element) {
        this.layoutTimePopup(element, this.$('.amOrPm'));
      }
    }
  });
});