define('es-frontend/components/bookmarklet-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'a',
    classNames: ['btn', 'main', 'btn-lg', 'primary-inverse', 'bookmarklet'],
    attributeBindings: ['href'],

    click: function click() {
      alert('Drag and drop me to your favorites bar');
      return false;
    },


    href: Ember.computed('script', function () {
      return 'javascript:void(' + encodeURIComponent(this.get('script')) + ')';
    }),

    //Ember is adding "unsafe" before javascript links - this component exists basically to strip that back
    //out as it is breaking the bookmarklet in firefox.
    didRender: function didRender() {
      var href = this.element.href;
      if (href.startsWith('unsafe:')) {
        this.element.href = href.substring(7);
      }
    }
  });
});