define('es-frontend/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    error: Ember.inject.service('error'),
    multiFrameService: Ember.inject.service('multi-frame-service'),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('error');
    },
    beforeModel: function beforeModel() {
      this.get('multiFrameService').announceLoad();
    }
  });
});