define('es-frontend/components/number-spinner', ['exports', 'es-frontend/utils/ddau'], function (exports, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['number-spinner', 'row'],
    inputClass: 'fill',
    min: -Number.MAX_VALUE,
    max: Number.MAX_VALUE,
    step: 1,
    value: 0,
    loop: true,

    change: function change(event) {
      this.actions.setValue.call(this, parseFloat(event.target.value));
    },


    actions: {
      valueChanged: _ddau.default.attr('value'),
      setValue: function setValue(value) {
        var min = this.get('min');
        var max = this.get('max');
        var step = this.get('step');
        if (step != 1 && Math.floor(step) == step) {
          var m = min == -Number.MAX_VALUE ? 0 : min;
          value = Math.round((value - m) / step) * step + m;
        }
        value = Math.max(value, min);
        value = Math.min(value, max);
        this.actions.valueChanged.call(this, value);
        this.$('input').val(value); // Hack - I dunno why this is needed, but it is
      },
      increment: function increment() {
        var newValue = (this.get('value') || 0) + this.get('step');
        if (this.get('loop')) {
          newValue = newValue > this.get('max') ? this.get('min') : newValue;
        }
        this.actions.setValue.call(this, newValue);
      },
      decrement: function decrement() {
        var newValue = (this.get('value') || 0) - this.get('step');
        if (this.get('loop')) {
          newValue = newValue < this.get('min') ? this.get('max') : newValue;
        }
        this.actions.setValue.call(this, newValue);
      }
    }
  });
});