define('es-frontend/components/es-date-range-picker', ['exports', 'moment', 'es-frontend/utils/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var VISIBLE_DATE = _constants.default.VISIBLE_DATE;
  exports.default = Ember.Component.extend({
    classNames: ['es-date-range-picker', 'col'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_range', {
        start: this.get('start') ? (0, _moment.default)(this.get('start'), VISIBLE_DATE).toDate() : null,
        end: this.get('end') ? (0, _moment.default)(this.get('end'), VISIBLE_DATE).toDate() : null
      });
      this.set('center', (0, _moment.default)(this.get('start') || this.get('end')), VISIBLE_DATE);
    },
    minMoment: function minMoment() {
      return this.get('maxMoment').call(this).add(-1, 'year');
    },
    maxMoment: function maxMoment() {
      return (0, _moment.default)();
    },
    compareDate: function compareDate(a, b) {
      if (a.year() != b.year()) {
        return a.year() - b.year();
      }
      if (a.month() != b.month()) {
        return a.month() - b.month();
      }
      return a.date() - b.date();
    },
    didRender: function didRender() {
      var _this = this;

      this.$('.ember-power-calendar-nav-control--previous').prop('disabled', !this.get('hasPrevMonth'))[this.get('hasPrevMonth') ? 'removeClass' : 'addClass']('disabled');
      this.$('.ember-power-calendar-nav-control--next').prop('disabled', !this.get('hasNextMonth'))[this.get('hasNextMonth') ? 'removeClass' : 'addClass']('disabled');
      var min = this.get('minMoment').call(this);
      var max = this.get('maxMoment').call(this);
      this.$('.ember-power-calendar-day--current-month').each(function (index, element) {
        var $element = Ember.$(element);
        var enabled = true;
        var date = (0, _moment.default)(Ember.$(element).data('date'), 'YYYY-MM-DD');
        if (min && _this.compareDate(date, min) < 0) {
          enabled = false;
        }
        if (max && _this.compareDate(date, max) > 0) {
          enabled = false;
        }
        $element.prop('disabled', !enabled);
      });
    },


    hasNextMonth: Ember.computed('center', function () {
      var center = this.get('center');
      var max = this.get('maxMoment').call(this);
      return center.year() < max.year() || center.year() == max.year() && center.month() < max.month();
    }),

    hasPrevMonth: Ember.computed('center', function () {
      var center = this.get('center');
      var min = this.get('minMoment').call(this);
      return center.year() > min.year() || center.year() == min.year() && center.month() > min.month();
    }),

    actions: {
      centerChanged: function centerChanged(center) {
        this.set('center', center.moment);
      },
      rangeChanged: function rangeChanged(range) {
        this.set('_range.start', range.date.start ? range.date.start : null);
        this.set('_range.end', range.date.end ? range.date.end : null);
      },
      dateRangeChanged: function dateRangeChanged() {
        var start = this.get('_range.start');
        if (start) {
          start = (0, _moment.default)(start).format(VISIBLE_DATE);
        }
        var end = this.get('_range.end');
        if (end) {
          end = (0, _moment.default)(end).format(VISIBLE_DATE);
        }
        this.get('dateRangeChanged')(start, end);
      }
    }
  });
});