define('es-frontend/utils/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var VISIBLE_DATE = 'MM-DD-YYYY';
  var SERVER_DATE = 'YYYY-MM-DDTHH:mm:ss';

  exports.default = {
    VISIBLE_DATE: VISIBLE_DATE,
    SERVER_DATE: SERVER_DATE,
    services: {
      1: 'twitter',
      2: 'facebook',
      3: 'linkedin',
      4: 'gplus',
      5: 'email',
      6: 'xing',
      7: 'linkedin_groups',
      8: 'facebook_groups',
      9: 'link'
    }
  };
});