define('es-frontend/components/img-upload', ['exports', 'es-frontend/utils/ddau', 'es-frontend/utils/read-img'], function (exports, _ddau, _readImg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    circle: true,
    drag: false,
    boundsEditable: true,
    handleSize: 10,
    aspectRatio: 0,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var img = this.get('img');
      var imgURL = this.get('imgURL');
      if (imgURL && !img) {
        img = new Image();
        img.onload = function () {
          _this.set('img', img);
        };
        img.src = imgURL;
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.$('form').on('drag dragstart dragend dragover dragenter dragleave drop', function (event) {
        event.preventDefault();
        event.stopPropagation();
        if (event.type == 'drop') {
          _this2.set('drag', false);
          _this2._readFile(event.dataTransfer);
        } else if (event.type == 'dragover') {
          _this2.set('drag', true);
        } else if (event.type == 'dragleave') {
          _this2.set('drag', false);
        }
      });
    },
    _readFile: function _readFile(obj) {
      var _this3 = this;

      (0, _readImg.default)(obj).then(function (img) {
        _this3.actions.imgChanged.call(_this3, img);
        _this3.actions.imgURLChanged.call(_this3, img.src);
      }, function (msg) {
        _this3._clear(msg);
      });
    },
    _clear: function _clear(msg) {
      if (msg) {
        this.get('toast').error(msg);
      }
      this.actions.imgURLChanged.call(this, null);
      this.actions.imgChanged.call(this, null);
    },


    actions: {

      imgChanged: _ddau.default.attr('img'),
      imgURLChanged: _ddau.default.attr('imgURL'),

      leftChanged: _ddau.default.attr('left'),
      topChanged: _ddau.default.attr('top'),
      widthChanged: _ddau.default.attr('width'),
      heightChanged: _ddau.default.attr('height'),
      scaleChanged: _ddau.default.attr('scale'),

      profileImageChanged: function profileImageChanged() {
        this._readFile(this.$('#' + this.get('elementId') + 'file')[0]);
      },
      rotate: function rotate() {
        var _this4 = this;

        var img = this.get('img');

        var canvas = document.createElement('canvas');
        canvas.width = img.naturalHeight;
        canvas.height = img.naturalWidth;
        var ctx = canvas.getContext('2d');

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(Math.PI / 2);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        var newImgURL = canvas.toDataURL("image/png");
        var newImg = new Image();
        newImg.onload = function () {
          _this4.actions.imgChanged.call(_this4, newImg);
          _this4.actions.imgURLChanged.call(_this4, newImgURL);
        };
        newImg.src = newImgURL;
      }
    }
  });
});