define('es-frontend/controllers/client/queue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    router: Ember.inject.service('router'),

    queuedClass: Ember.computed('router.currentRouteName', function () {
      return this.linkClass(this.get('router.currentRouteName') == "client.queue.queued");
    }),

    historyClass: Ember.computed('router.currentRouteName', function () {
      return this.linkClass(this.get('router.currentRouteName') == "client.queue.history");
    }),

    settingsClass: Ember.computed('router.currentRouteName', function () {
      return this.linkClass(this.get('router.currentRouteName') == "client.queue.settings");
    }),

    linkClass: function linkClass(active) {
      return 'fill btn main btn-lg primary-inverse' + (active ? '' : '-focus bg2 txt6');
    }
  });
});