define('es-frontend/components/profile-img', ['exports', 'es-frontend/utils/strings', 'es-frontend/utils/icons'], function (exports, _strings, _icons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['profile-img'],
    classNameBindings: ['influencer'],
    size: 50,
    icon: 'person',
    initialsClass: 'txt7-inverse',
    iconClass: 'txt7',
    irregular: false,

    initials: Ember.computed('title', function () {
      return _strings.default.initials(this.get('title'));
    }),

    didInsertElement: function didInsertElement() {
      this._populate(this.$());
    },


    elementObserver: Ember.observer('src', 'title', 'size', 'icon', 'initialsClass', 'iconClass', function () {
      var _this = this;

      Ember.run.later(function () {
        if (!(_this.isDestroying || _this.isDestroyed)) {
          _this._populate(_this.$());
        }
      });
    }),

    // We do everything in here because if the image load fails
    // then we need to quickly adapt. (And in some cases we copy dom elements for
    // convenience - this is the easiest way of making sure they stay in sync if
    // an image load fails)
    _populate: function _populate($container) {
      if (this.get('src')) {
        this._populateImg($container);
        return;
      }
      if (this.get('title')) {
        this._populateInitials($container);
        return;
      }
      this._populateIcon($container);
    },
    _populateImg: function _populateImg($container) {
      var $img = $container.find('img.es-img');
      if (!$img.length) {
        $container.empty();
        $img = Ember.$('<img/>').addClass('es-img fadein').on('error', this._imgError.bind(this));
        if (this.get('irregular')) {
          $img.on('load', this._imgLoad.bind(this));
        }
        $img.appendTo($container);
      }
      $img.prop('src', this.get('src')).prop('title', this.get('title')).css(this.get('css'));
      this._populateInfluencer($container);
    },
    _populateInitials: function _populateInitials($container) {
      var $initials = $container.find('.profile-initials');
      if (!$initials.length) {
        $container.empty();
        $initials = Ember.$('<div/>').addClass('profile-initials fadein').appendTo($container);
      }
      $initials.prop('title', this.get('title')).addClass('es-initials').html(this.get('initials')).css(this.get('css'));
      var initialsClass = this.get('initialsClass');
      var _prevInitialsClass = this.get('_prevInitialsClass');
      if (_prevInitialsClass != initialsClass) {
        $initials.removeClass(_prevInitialsClass).addClass(initialsClass);
        this.set('_prevInitialsClass', initialsClass);
      }
      this._populateInfluencer($container);
    },
    _populateIcon: function _populateIcon($container) {
      var size = this.get('size');
      var $icon = $container.find('.profile-img-icon');
      if (!$icon.length) {
        $container.empty();
        $icon = Ember.$('<div/>').addClass('profile-img-icon es-icon fadein').html(_icons.default[this.get('icon')]).appendTo($container);
      }
      $icon.prop('title', this.get('title')).css({
        fontSize: size + 'px'
      });
      var iconClass = this.get('iconClass');
      var _prevIconClass = this.get('_prevIconClass');
      if (_prevIconClass != iconClass) {
        $icon.removeClass(_prevIconClass).addClass(iconClass);
        this.set('_prevIconClass', iconClass);
      }
      this._populateInfluencer($container);
    },
    _populateInfluencer: function _populateInfluencer($container) {
      var $influencer = $container.find('.influencer');
      if (!this.get('influencer')) {
        $influencer.remove();
        return;
      }
      if (!$influencer.length) {
        Ember.$('<div/>').addClass('influencer es-icon fadein').html(_icons.default['influencer']).css({ fontSize: this.get('size') / 2 + 'px' }).appendTo($container);
      }
    },


    css: Ember.computed('size', function () {
      var size = this.get('size');
      return {
        width: size + 'px',
        height: size + 'px',
        display: 'block',
        border: 'none',
        lineHeight: size + 'px',
        textAlign: 'center',
        fontSize: size / 2 + 'px'
      };
    }),

    _imgError: function _imgError() {
      if (!(this.isDestroying || this.isDestroyed)) {
        this.set('src', null);
      }
    },
    _imgLoad: function _imgLoad() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var $img = this.$('img');
      var nw = $img[0].naturalWidth;
      var nh = $img[0].naturalHeight;
      var size = this.get('size');
      if (nw < nh) {
        $img.width(nw * size / nh);
        this.$().addClass('row-center');
      } else {
        $img.height(nh * size / nw);
        this.$().addClass('col-center');
      }
    }
  });
});