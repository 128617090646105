define('es-frontend/utils/get-error-msg', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getErrorMsg;
  function getErrorMsg(model) {
    if (!model || typeof model == 'string') {
      return model;
    }
    return getErrorMsg(model.payload) || getErrorMsg(model.message) || getErrorMsg(model.msg) || getErrorMsg(model.error) || getErrorMsg(model.responseJSON);
  }
});