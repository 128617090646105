define('es-frontend/utils/abstract-stream-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      q: {
        refreshModel: true,
        replace: false
      }
    },
    controllerName: 'client/streams/topic',
    segment: Ember.inject.service('segment'),

    init: function init() {
      this._super.apply(this, arguments);
      this.on('deactivate', this._onDeactivate.bind(this));
    },
    model: function model(params) {
      var parentModel = this.modelFor('client/streams');
      var selectedStream = parentModel.get('streams').find(function (obj) {
        return obj.id == params.id;
      });
      document.title = (selectedStream ? selectedStream.title : 'Articles') + ' - EveryoneSocial';
      parentModel.set('selectedStream', selectedStream);
      parentModel.set('selectedStreamId', params.id);
      var controller = this.controllerFor(this.get('controllerName'));
      var clientModel = this.modelFor('client');
      var sources = selectedStream.sources || [];
      this.get('segment').track('content_stream_viewed', {
        content_stream_id: params.id,
        company_default: false,
        company: false,
        third_party: selectedStream.route === "client.streams.topic",
        personal: selectedStream.route === "client.streams.personal",
        includes_twitter_handle: !!sources.find(function (source) {
          return source.feed_url.startsWith('@');
        }),
        includes_twitter_hashtag: !!sources.find(function (source) {
          return source.feed_url.startsWith('#');
        }),
        includes_rss_feed: !!sources.find(function (source) {
          return source.feed_url.startsWith('http');
        }),
        inclues_keyword: !sources.find(function (source) {
          return source.feed_url.startsWith('@') || source.feed_url.startsWith('#') || source.feed_url.startsWith('http');
        })
      });
      return controller.actions.initialLoad.call(controller, params.id, params.q, clientModel.user, clientModel.customer, clientModel.client, clientModel.features, clientModel.subscriptions);
    },


    _onDeactivate: function _onDeactivate() {
      window.clearInterval(this.controller.handlingStale);
    }
  });
});