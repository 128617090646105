define('es-frontend/models/client', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    slug: _emberData.default.attr('string'),
    salesforce_token_id: _emberData.default.attr('number'),
    salesforce_bcc_email: _emberData.default.attr('string'),
    salesforce_enabled: _emberData.default.attr('boolean')
  });
});