define('es-frontend/components/terms-lightbox', ['exports', 'es-frontend/utils/asset-url'], function (exports, _assetUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    title: "Terms & Conditions",
    attr: 'terms_conditions',

    everyoneSocialTerms: Ember.computed('everyoneSocialTermsObj', function () {
      return this.get('everyoneSocialTermsObj.' + this.get('attr'));
    }),

    actions: {
      loadTerms: function loadTerms() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          window.everyoneSocialTermsCallback = function () {
            _this.set('everyoneSocialTermsObj', window.everyoneSocialTerms);
            resolve();
          };
          if (window.everyoneSocialTerms) {
            return window.everyoneSocialTermsCallback();
          }
          var script = document.createElement('script');
          script.async = true;
          script.src = (0, _assetUrl.default)() + '/json/everyonesocial_terms.jsonp';
          document.body.appendChild(script);
        });
      }
    }
  });
});