define('es-frontend/models/leaderboard-summary', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    enterprise_client_id: _emberData.default.attr('number'),
    user_id: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    profile_image_url: _emberData.default.attr('string'),
    influencer: _emberData.default.attr('boolean'),
    rank: _emberData.default.attr('number'),
    score: _emberData.default.attr('number'),
    share_points: _emberData.default.attr('number')
  });
});