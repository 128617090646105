define('es-frontend/services/width', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HUGE = 1400;
  var LARGE = 1024;
  var MEDIUM = 736;

  exports.default = Ember.Service.extend({

    width: Ember.$(window).width(),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.$(window).on('resize', this._onResize.bind(this));
      this.set('menuOpen', this.get('gtXs'));
    },


    size: Ember.computed('width', function () {
      var width = this.get('width');
      if (width >= HUGE) {
        return 'huge';
      } else if (width >= LARGE) {
        return 'large';
      } else if (width >= MEDIUM) {
        return 'medium';
      } else {
        return 'small';
      }
    }),

    small: Ember.computed('width', function () {
      return this.get('size') == 'small';
    }),

    med: Ember.computed('width', function () {
      return this.get('size') == 'medium';
    }),

    large: Ember.computed('width', function () {
      return this.get('size') == 'large';
    }),

    huge: Ember.computed('width', function () {
      return this.get('size') == 'huge';
    }),

    gtSmall: Ember.computed('width', function () {
      return this.get('width') >= MEDIUM;
    }),

    gtMedium: Ember.computed('width', function () {
      return this.get('width') >= LARGE;
    }),

    ltLarge: Ember.computed('width', function () {
      return this.get('width') < LARGE;
    }),

    ltHuge: Ember.computed('width', function () {
      return this.get('width') < HUGE;
    }),

    _onResize: function _onResize() {
      this.set('width', Ember.$(window).width());
    }
  });
});