define('es-frontend/components/external-article', ['exports', 'es-frontend/utils/constants', 'moment', 'es-frontend/utils/strings'], function (exports, _constants, _moment, _strings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SERVER_DATE = _constants.default.SERVER_DATE;
  exports.default = Ember.Component.extend({
    classNames: ['external-article', 'single-article', 'col', 'bg1'],
    ajaxService: Ember.inject.service('es-ajax'),
    segment: Ember.inject.service('segment'),

    summary: Ember.computed('article.full_summary', function () {
      var summary = this.get('article.full_summary');
      if (summary && summary.length > 255) {
        summary = summary.substring(0, 254) + '\u2026';
      }
      return summary;
    }),

    defaultMeta: Ember.computed('article.full_summary', function () {
      var article = this.get('article');
      var imgs = [];
      var img = this.$('.img-link img')[0];
      if (img) {
        imgs.push({
          src: img.src,
          width: img.naturalWidth,
          height: img.naturalHeight
        });
      }
      return {
        title: _strings.default.limit(article.title, 255),
        description: _strings.default.limit(article.full_summary, 255),
        imgs: imgs
      };
    }),

    didRender: function didRender() {
      var _this = this;

      this.$('.img-link img').on('error', function () {
        if (!(_this.destroying || _this.destroyed)) {
          var img = _this.$('.img-link');
          if (img) {
            img.remove();
          }
        }
      }).on('load', function () {
        if (!(_this.destroying || _this.destroyed)) {
          var img = _this.$('img')[0];
          if (img.naturalWidth < 100 || img.naturalHeight < 100) {
            _this.$('.img-link').remove();
          }
        }
      });
    },
    showPermalink: function showPermalink() {
      var _this2 = this;

      this.get('segment').track('Permalink', {
        user_email: this.get('user.email'),
        action: 'Permalink',
        userStream: true,
        source: this.get('article.source'),
        contentId: this.get('article.id'),
        contentTitle: this.get('article.title'),
        contentUrl: this.get('article.url'),
        isBranded: false, // all these are branded content
        stream: this.get('id')
      });
      this.set('showLink', true);
      Ember.run.later(function () {
        try {
          // this.$() does not work because popup
          Ember.$('.permalink').focus()[0].select();
          if (document.execCommand('Copy')) {
            _this2.get('toast').info('Link Copied to Clipboard');
          }
        } catch (e) {
          //If the copy failed it just means the user has to do it for themselves - the text is still visible
        }
      }, 200);
    },


    actions: {
      permalink: function permalink() {
        var _this3 = this;

        if (this.get('permalink')) {
          this.showPermalink();
          return;
        }
        this.get('ajaxService').ajax({
          method: 'POST',
          url: '/other_shares',
          contentType: 'application/json',
          data: JSON.stringify({
            image_url: this.get('artile.large_image_url'),
            large_image_url: this.get('artile.large_image_url'),
            post_at: (0, _moment.default)().add(1, 'hours').format(SERVER_DATE),
            url: this.get('article.url'),
            title: this.get('artile.title'),
            summary: this.get('summary'),
            network: "permalink"
          })
        }).then(function (post) {
          _this3.set('permalink', post.url);
          _this3.showPermalink();
        });
      }
    }
  });
});