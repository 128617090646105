define('es-frontend/controllers/signin/signup/user-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    segment: Ember.inject.service('segment'),
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    tags: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    actions: {
      selected: function selected(category, tag) {
        category.set('selectedTag', tag);
        this.set('errorMsg', null);
        this.notifyPropertyChange('');
      },
      deselected: function deselected(category) {
        category.set('selectedTag', null);
      },
      saveTags: function saveTags() {
        var _this = this;

        var tag_ids = [];
        var categories = this.get('model.categories');
        if (categories) {
          for (var c = 0; c < categories.length; c++) {
            var category = categories[c];
            if (category.selectedTag) {
              tag_ids.push(category.selectedTag.id);
            } else {
              this.set('errorMsg', 'Please select a value!');
              return;
            }
          }
        }

        var userId = this.get('model.user.id');
        this.get('ajaxService').ajax({
          method: 'PUT',
          userPath: false,
          url: 'users/' + userId + '.json',
          data: {
            user: {
              signup_step: 3,
              verification_code: this.get('model.user.verification_code'),
              tag_ids: tag_ids
            }
          }
        }).then(function () {
          //full refresh
          _this.get('segment').track('signin_signup_user_tags_success');
          location.href = location.protocol + '//' + location.host + location.pathname;
        });
      }
    }
  });
});