define('es-frontend/controllers/client/profile', ['exports', 'ember-buffered-proxy/proxy', 'es-frontend/utils/get-error-msg', 'es-frontend/utils/sort-social-queues', 'es-frontend/utils/asset-url'], function (exports, _proxy, _getErrorMsg, _sortSocialQueues, _assetUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    widthService: Ember.inject.service('width'),
    ajaxService: Ember.inject.service('es-ajax'),
    segment: Ember.inject.service('segment'),

    twitter: Ember.computed('model.social_queues', function () {
      return (this.get('model.social_queues') || []).findBy('service', 'twitter');
    }),

    facebook: Ember.computed('model.social_queues', function () {
      return (this.get('model.social_queues') || []).findBy('service', 'facebook');
    }),

    linkedin: Ember.computed('model.social_queues', function () {
      return (this.get('model.social_queues') || []).findBy('service', 'linkedin');
    }),

    frameTarget: Ember.computed(function () {
      return window == window.top ? null : "_new";
    }),

    sortedSocialQueues: Ember.computed('model.social_queues', function () {
      return (0, _sortSocialQueues.default)(this.get('model.social_queues'));
    }),

    editUser: Ember.computed('model.user', function () {
      return _proxy.default.create({
        content: this.get('model.user')
      });
    }),

    actions: {
      removeSocialQueue: function removeSocialQueue(social_queue) {
        var segmentParams = social_queue.toJSON();
        social_queue.deleteRecord();
        social_queue.save();
        this.get('segment').track('disconnect_' + social_queue.get('service'), segmentParams);
        var social_queues = this.get('model.social_queues').slice();
        var index = social_queues.indexOf(social_queue);
        social_queues.splice(index, 1);
        this.set('model.social_queues', social_queues);
      },
      closeEditProfile: function closeEditProfile() {
        this.get('editUser').discardChanges();
        this.set('editProfileOpen', false);
      },
      updateUser: function updateUser(user) {
        var _this = this;

        var profile_image = user.buffer.profile_image;
        if (profile_image) {
          user.discardBufferedChanges(['profile_image_url']);
        }
        user.applyChanges();
        this.get('model.user').save().then(function () {
          if (profile_image) {
            _this.set('model.user.profile_image_url', profile_image);
          }
          _this.set('editProfileOpen', false);
          _this.get('toast').info('User Updated');
          user.setProperties({
            changePassword: false,
            current_password: null,
            password: null,
            password_confirmation: null
          });
        }, function (result) {
          _this.get('toast').error((0, _getErrorMsg.default)(result) || 'User Update Failed');
        });
      },
      loadTerms: function loadTerms() {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve) {
          window.everyoneSocialTermsCallback = function () {
            _this2.set('everyoneSocialTerms', window.everyoneSocialTerms);
            resolve();
          };
          if (window.everyoneSocialTerms) {
            return window.everyoneSocialTermsCallback();
          }
          var script = document.createElement('script');
          script.async = true;
          script.src = (0, _assetUrl.default)() + '/json/everyonesocial_terms.jsonp';
          document.body.appendChild(script);
        });
      },
      disconnectSalesforce: function disconnectSalesforce() {
        var _this3 = this;

        this.get('ajaxService').ajax({
          url: '/oauth_access_tokens/' + this.get('model.client.salesforce_token_id'),
          method: 'DELETE'
        }).then(function () {
          _this3.get('model.client').setProperties({
            salesforce_token_id: null,
            salesforce_bcc_email: null
          });
        });
      },
      onConnectLinkedinPage: function onConnectLinkedinPage() {
        var _this4 = this;

        this.set('pagesSocialQueue', null);
        this.get('store').findAll('social-queue', { reload: true }).then(function (queues) {
          _this4.set('model.social_queues', queues.toArray());
        });
      }
    }
  });
});