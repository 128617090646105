define('es-frontend/components/es-multi-select', ['exports', 'es-frontend/utils/absolute-position-of', 'es-frontend/utils/ddau'], function (exports, _absolutePositionOf, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['es-multi-select', 'row'],
    placeholder: 'Add',
    openBtnClass: 'btn open txt7 primary-focus pad-left pad-right',
    removeBtnClass: 'btn remove txt7 primary-focus pad-left pad-right',
    addBtnClass: 'btn add primary-inverse-focus',

    selectedOptions: Ember.computed('options', 'values', function () {
      var ret = [];
      var values = this.get('values') || [];
      (this.get('options') || []).forEach(function (option) {
        if (values.indexOf(option) >= 0) {
          ret.push(option);
        }
      });
      return ret;
    }),

    unselectedOptions: Ember.computed('options', 'values', function () {
      var ret = [];
      var values = this.get('values') || [];
      (this.get('options') || []).forEach(function (option) {
        if (values.indexOf(option) < 0) {
          ret.push(option);
        }
      });
      return ret;
    }),

    actions: {
      openChanged: _ddau.default.attr('open'),
      valuesChanged: _ddau.default.attr('values'),
      openSelection: function openSelection() {
        this.actions.openChanged.call(this, true);
      },
      _layoutPopup: function _layoutPopup(popupElement) {
        var anchor = this.$();
        var position = (0, _absolutePositionOf.default)(anchor);
        popupElement.css({
          left: position.left,
          top: position.top + anchor.outerHeight(),
          width: anchor.outerWidth()
        });
      },
      addSelection: function addSelection(option) {
        var values = (this.get('values') || []).slice();
        values.push(option);
        this.actions.valuesChanged.call(this, values);
      },
      removeSelection: function removeSelection(option) {
        var values = (this.get('values') || []).slice();
        values.splice(values.indexOf(option), 1);
        this.actions.valuesChanged.call(this, values);
      }
    }
  });
});