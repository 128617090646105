define("es-frontend/utils/find-nested", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = findNested;
  function findNested(array, id, nestAttr, chain) {
    if (!array) {
      return null;
    }
    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      if (obj.id == id) {
        if (chain) {
          chain.push(obj);
        }
        return obj;
      }
      var ret = findNested(obj[nestAttr], id, nestAttr, chain);
      if (ret) {
        if (chain) {
          chain.push(obj);
        }
        return ret;
      }
    }
    return null;
  }
});