define('es-frontend/routes/signin/signup/user-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    model: function model() {
      return Ember.RSVP.hash({
        customer: this.modelFor('signin'),
        user: this.modelFor('signin.signup'),
        user_tags: this.get('ajaxService').ajax({
          userPath: false,
          url: 'users/user_tags.json',
          data: {
            customer_id: this.modelFor('signin').id
          }
        })
      });
    },
    activate: function activate() {
      Ember.run.later(function () {
        Ember.$('input.password').focus();
      });
    }
  });
});