define('es-frontend/services/scraper', ['exports', 'es-frontend/utils/is-valid-url'], function (exports, _isValidUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({
    esAjax: Ember.inject.service('es-ajax'),
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    _promiseByUrl: {}, // we cache results from urls rather than get the full list each time and filter
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    fetch: function fetch(url) {
      //First chop the hash as this is never sent to servers
      var index = url.indexOf('#');
      if (index > 0) {
        url = url.substring(0, index);
      }
      var promise = this._promiseByUrl[url];
      return promise ? promise : this._doFetch(url);
    },


    resolve: function resolve(url, results) {
      this._promiseByUrl[url] = new Ember.RSVP.Promise(function (resolve) {
        resolve(results);
      });
    },

    _doFetch: function _doFetch(url) {
      var _this = this;

      return this._promiseByUrl[url] = new Ember.RSVP.Promise(function (resolve, reject) {

        if (!(0, _isValidUrl.default)(url)) {
          reject({
            msg: 'bad url sent to ScraperService'
          });
          return;
        }

        _this.get('esAjax').ajax({
          url: '/scraper.json',
          data: {
            url: url
          }
        }).then(function (results) {
          results.url = url;

          var title = _this._sanitizeTitle(results.title);
          var description = Ember.$('<div>').html(results.description).text().trim();

          var base;
          if (results.base) {
            base = results.base;
            if (base.endsWith('/')) {
              base = base.substring(0, base.length - 1);
            }
          } else {
            base = _this._extractBaseFromUrl(url);
          }

          var imgs = _this._sanitizeImgSrcs(results.imgs || [], base);
          _this._loadAllImgInfo(imgs, _this._sanitizeImgSrc(results.preferred_img), function (imgInfo) {
            resolve({
              imgs: imgInfo,
              title: title,
              description: description
            });
          });
        }, reject);
      });
    },

    // Check each of the images in the array given, and pass and array containing
    // only images which load and are of an appropriate size to the callback
    _loadAllImgInfo: function _loadAllImgInfo(srcs, preferred_img, callback) {

      var ret = [];
      var toCheck = srcs.length;

      function checkFinishedAll() {
        if (!toCheck) {
          ret.sort(function (a, b) {
            var aWeight = a.width * a.width + a.height * a.height;
            var bWeight = b.width * b.width + b.height * b.height;
            return bWeight - aWeight;
          });

          //Reinsert the preferred image at the front of the array after sorting
          if (preferred_img) {
            for (var i = 0; i < ret.length; i++) {
              var imageInfo = ret[i];
              if (imageInfo.src == preferred_img) {
                ret.splice(i, 1);
                ret.splice(0, 0, imageInfo);
              }
            }
          }

          callback(ret);
          return;
        }
      }

      function successSingle(imgInfo) {
        ret.push(imgInfo);
        finishedSingle();
      }

      function finishedSingle() {
        toCheck--;
        checkFinishedAll();
      }

      checkFinishedAll();
      for (var i = 0; i < srcs.length; i++) {
        this._loadSingleImgInfo(srcs[i], successSingle, finishedSingle);
      }
    },


    // we store only a summary of information about an image because we don't want
    // full buffers hanging around in memory
    _loadSingleImgInfo: function _loadSingleImgInfo(src, resolve, reject) {
      var img = new Image();
      img.onload = function () {
        if (img) {
          if (img.width < 100 || img.height < 100) {
            reject();
          } else {
            resolve({
              width: img.width,
              height: img.height,
              src: img.src
            });
          }
          img = null;
        }
      };
      img.onerror = function () {
        if (img) {
          reject();
          img = null;
        }
      };
      img.src = src;
    },

    _sanitizeImgSrcs: function _sanitizeImgSrcs(srcs, base) {
      var _this2 = this;

      var ret = [];
      var usedSrcs = {};
      srcs.forEach(function (src) {
        src = _this2._sanitizeImgSrc(src, base);
        if (!src || usedSrcs[src]) {
          return;
        }
        usedSrcs[src] = 1;
        ret.push(src);
      });
      return ret;
    },
    _sanitizeImgSrc: function _sanitizeImgSrc(src, base) {
      if (!src || !src.indexOf('http://') || !src.indexOf('https://')) {
        return src; //do nothing
      } else if (!src.indexOf('//')) {
        return location.protocol + src;
      } else if (!src.indexOf('/')) {
        return base + src;
      } else {
        return base + '/' + src;
      }
    },
    _extractBaseFromUrl: function _extractBaseFromUrl(url) {
      //strip out query string - we will not be using it
      var index = url.indexOf('?');
      if (index > 0) {
        url = url.substring(0, index);
      }

      //capture protocol separately
      var protocol = "http:";
      index = url.indexOf('//');
      if (index > 0) {
        protocol = url.substring(0, index);
        url = url.substring(index + 2);
      }

      //If there is a path, we pop the last element off it
      index = url.lastIndexOf('/');
      if (index > 0) {
        url = url.substring(0, index);
      }

      return protocol + '//' + url;
    },


    _getStr: function _getStr(p) {
      if (!p) {
        return '';
      } else if ((typeof p === 'undefined' ? 'undefined' : _typeof(p)) == 'object') {
        return p.content && typeof p.content == 'string' ? p.content : '';
      }
      return '' + p;
    },

    _sanitizeTitle: function _sanitizeTitle(title) {
      title = Ember.$('<div>').html(title).text().trim();
      var index;
      if ((index = title.indexOf(' on Instagram: ')) > 0) {
        title = title.substring(0, index) + ' on Instagram';
      } else if ((index = title.indexOf(' on LinkedIn: ')) > 0) {
        title = title.substring(0, index) + ' on LinkedIn';
      }
      return title;
    }
  });
});