define('es-frontend/components/search-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'form',
    classNames: ['search-form'],
    queryService: Ember.inject.service('query')
  });
});