define('es-frontend/adapters/queued-item', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    urlForQuery: function urlForQuery() {
      return '/v1/queued_items.json';
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var changedAttrs = snapshot.changedAttributes();
      for (var key in changedAttrs) {
        changedAttrs[key] = changedAttrs[key][1];
      }
      return this.get('ajaxService').ajax({
        url: '/v1/social_queues/' + snapshot.record.get('social_queue_id') + '/queued_posts/' + snapshot.id + '.json',
        method: 'PUT',
        contentType: 'application/json',
        data: changedAttrs
      });
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, type, snapshot) {
      return '/v1/social_queues/' + snapshot.record.get('social_queue_id') + '/queued_posts/' + id + '.json';
    }
  });
});