define('es-frontend/components/single-comment', ['exports', 'es-frontend/utils/strings', 'es-frontend/utils/my-reactions'], function (exports, _strings, _myReactions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['single-comment', 'col'],
    classNameBindings: ['replyClassName'],
    myReactions: (0, _myReactions.default)('comment.reactions'),
    reactionService: Ember.inject.service('reaction'),
    text: Ember.computed.oneWay('comment.rich_text'),
    commentService: Ember.inject.service('comment'),

    replyClassName: Ember.computed('comment.parent_id', function () {
      return this.get('comment.parent_id') ? 'reply' : '';
    }),

    uniqueReactionTypes: Ember.computed('comment.reactions', function () {
      var reactions = this.get('comment.reactions');
      var ret = [];
      if (!reactions) {
        return ret;
      }
      for (var r = 0; r < reactions.length; r++) {
        var status = reactions[r].status;
        if (ret.indexOf(status) < 0) {
          ret.push(status);
        }
      }
      return ret;
    }),

    numAdditionalReactions: Ember.computed('comment.reactions', function () {
      var reactions = this.get('comment.reactions');
      if (!reactions) {
        return 0;
      }
      var byStatus = {};
      for (var r = 0; r < reactions.length; r++) {
        var status = reactions[r].status;
        byStatus[status] = (byStatus[status] || 0) + 1;
      }
      var ret = 0;
      for (var key in byStatus) {
        ret += byStatus[key] - 1;
      }
      return ret;
    }),

    currentUserIsOwner: Ember.computed('comment.user', 'user', function () {
      return this.get('comment.user.id') == this.get('user.id');
    }),

    showPlus: Ember.computed('comment.{has_children,comments}', function () {
      return this.get('comment.has_children') && !this.get('comment.comments');
    }),

    rich_text: Ember.computed('comment.rich_text', function () {
      var value = this.get('comment.rich_text').replace(/(<([^>]+)>)/ig, '');
      if (!value) {
        return '';
      }
      var parsedValue = [];
      var index = 0;
      /* eslint-disable no-constant-condition */
      while (true) {
        var nameStart = value.indexOf('@[', index);
        var nameEnd = value.indexOf('](', nameStart);
        var idStart = value.indexOf(':', nameEnd) + 1;
        var idEnd = value.indexOf(')', nameEnd);
        if (nameStart < 0 || nameEnd < 0 || idEnd < 0) {
          parsedValue.push(value.substring(index, value.length));
          return parsedValue.join('');
        }
        var type = _strings.default.capitalize(value.substring(nameEnd + 2, value.indexOf(':', nameEnd)));
        parsedValue.push(value.substring(index, nameStart));
        if (type == 'User') {
          parsedValue.push('<a href="#/client/profile/' + value.substring(idStart, idEnd).trim() + '" class="es', type, 'Tag underline primary-focus txt4">', value.substring(nameStart + 2, nameEnd), '</a>');
        } else {
          parsedValue.push('<span class="es', type, 'Tag">', value.substring(nameStart + 2, nameEnd), '</span>');
        }
        index = idEnd + 1;
        if (value.charAt(index) == ':') {
          index++;
        }
      }
      /* eslint-enable no-constant-condition */
    }),

    actions: {
      addReaction: function addReaction(reactionType) {
        var _this = this;

        this.get('reactionService').addReaction('Comment', this.get('comment.id'), reactionType).then(function (reaction) {
          var reactions = (_this.get('comment.reactions') || []).filter(function (r) {
            return r.user_id != reaction.user_id;
          });
          reactions.push(reaction);
          _this.set('comment.reactions', reactions);
        });
      },
      removeReaction: function removeReaction(reaction) {
        var _this2 = this;

        this.get('reactionService').removeReaction('Comment', this.get('comment.id'), reaction.id).then(function () {
          var reactions = (_this2.get('comment.reactions') || []).slice();
          reactions.splice(reactions.findIndex(function (obj) {
            return obj.id == reaction.id;
          }), 1);
          _this2.set('comment.reactions', reactions);
        });
      },
      postComment: function postComment(value) {
        var _this3 = this;

        return this.postComment(value, this.get('comment.id')).then(function () {
          _this3.set('replying', false);
        });
      },
      removeComment: function removeComment() {
        return this.removeComment(this.get('comment.id'));
      },
      updateComment: function updateComment(text) {
        var _this4 = this;

        return this.updateComment(this.get('comment.id'), text).then(function () {
          _this4.set('editing', false);
        });
      },
      loadReplies: function loadReplies() {
        return this.loadReplies(this.get('comment.id'));
      }
    }

  });
});