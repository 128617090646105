define('es-frontend/components/es-icon', ['exports', 'es-frontend/utils/icons'], function (exports, _icons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['es-icon'],
    classNameBindings: ['value'],
    attributeBindings: ['style', 'title'],

    style: Ember.computed('size', function () {
      var size = this.get('size');
      if (size) {
        return Ember.String.htmlSafe('font-size:' + size + 'px');
      }
      return null;
    }),

    content: Ember.computed('value', function () {
      var value = this.get('value');
      var icon = _icons.default[value];
      if (!icon) {
        Ember.Logger.error('UnknownIcon:' + value);
      }
      return icon;
    })
  }).reopenClass({
    positionalParams: ['value']
  });
});