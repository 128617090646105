define('es-frontend/routes/client/streams', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var streams = [];
      var client = this.modelFor('client');
      client.get('subscriptions').forEach(function (subscription) {
        streams.push({
          route: "client.streams.stream",
          img: subscription.get('image_url'),
          id: subscription.get('content_stream_id'),
          title: subscription.get('name')
        });
      });
      if (client.get('customer.show_curated_streams')) {
        client.get('curated_topics').forEach(function (curated_topic) {
          streams.push({
            route: "client.streams.topic",
            img: curated_topic.get('icon_url'),
            id: curated_topic.get('id'),
            title: curated_topic.get('name')
          });
        });
      }
      if (client.get('features.personal_stream_access')) {
        client.get('personal_streams').forEach(function (personal_stream) {
          streams.push({
            route: "client.streams.personal",
            id: personal_stream.id,
            title: personal_stream.name,
            sources: personal_stream.sources
          });
        });
      }
      streams.push({
        route: 'client.manage-streams',
        icon: 'settings',
        title: 'Manage Streams'
      });
      return Ember.Object.create({
        user: client.get('user'),
        client: client.get('client'),
        customer: client.get('customer'),
        streams: streams,
        features: client.get('features')
      });
    }
  });
});