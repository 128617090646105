define('es-frontend/components/desktop-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['desktop-menu', 'col'],
    router: Ember.inject.service('router'),
    ajaxService: Ember.inject.service('es-ajax'),
    MAX_COMPRESSED: 7,

    init: function init() {
      this._super.apply(this, arguments);
      this._onResize = this._onResize.bind(this);
      this._onScroll = this._onScroll.bind(this);
    },


    streamsMenuItems: Ember.computed('subscriptions', 'curated_topics', 'personal_streams', 'stats', 'features', function () {
      var ret = [];
      var articles_counts = this.get('stats.articles_counts') || {};
      this.get('subscriptions').forEach(function (subscription) {
        ret.push({
          route: "client.streams.stream",
          img: subscription.get('image_url'),
          models: subscription.get('content_stream_id'),
          title: subscription.get('name'),
          numAlerts: articles_counts[subscription.id]
        });
      });
      if (this.get('customer.show_curated_streams')) {
        this.get('curated_topics').forEach(function (curated_topic) {
          ret.push({
            route: "client.streams.topic",
            img: curated_topic.get('icon_url'),
            models: curated_topic.get('id'),
            title: curated_topic.get('name')
          });
        });
      }
      if (this.get('features.personal_stream_access')) {
        this.get('personal_streams').forEach(function (personal_stream) {
          ret.push({
            route: "client.streams.personal",
            models: personal_stream.id,
            title: personal_stream.name
          });
        });
      }
      ret.push({
        route: 'client.manage-streams',
        icon: 'settings',
        title: 'Manage Streams'
      });
      return ret;
    }),

    streamsMenu: Ember.computed('streamsMenuItems', 'streamsExpanded', function () {
      var items = this.get('streamsMenuItems');
      if (this.get('streamsExpanded') || items.length < this.get('MAX_COMPRESSED')) {
        return items;
      }
      return items.slice(0, this.get('MAX_COMPRESSED'));
    }),

    adminHref: Ember.computed('ajaxService.restRootPath', function () {
      return this.get('ajaxService.restRootPath') + 'enterprise/' + this.get('user.customer_slug');
    }),

    oldAppHref: Ember.computed('ajaxService.restRootPath', function () {
      return this.get('ajaxService.restRootPath') + 'enterprise_clients/' + this.get('client.slug');
    }),

    didInsertElement: function didInsertElement() {
      Ember.$(window).on('resize', this._onResize).on('scroll', this._onScroll);
      this._onResize();
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('resize', this._onResize).off('scroll', this._onScroll);
    },
    didRender: function didRender() {
      this._onResize();
    },
    _onResize: function _onResize() {
      var _this = this;

      var $inner = this.$('.menu-inner');
      Ember.run.later(function () {
        var $this = _this.$();
        if (!$this) {
          return;
        }
        var fixed = Ember.$(window).height() > $this.offset().top + $inner.height();
        $inner.width($inner.width()).css({
          position: fixed ? 'fixed' : 'static',
          marginTop: fixed ? 0 : window.scrollY
        });
      }, 400);
    },
    _onScroll: function _onScroll() {
      var $inner = this.$('.menu-inner');
      if ($inner.css('position') == 'static') {
        if (parseInt($inner.css('marginTop')) > window.scrollY) {
          $inner.css('marginTop', window.scrollY);
        }
      }
    }
  });
});