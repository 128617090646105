define('es-frontend/controllers/remote-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    open: true,
    embedService: Ember.inject.service('embed'),
    scraperService: Ember.inject.service('scraper'),
    url: null,
    closeWinOnFinish: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.onMessage = this.onMessage.bind(this);
      window.addEventListener('message', this.onMessage, false);
      window.parent.postMessage(JSON.stringify({ message: 'loaded' }), '*');
    },
    onMessage: function onMessage(e) {
      var _this = this;

      if (this.get('_hasRemotePostRan')) {
        return;
      }
      var data;
      try {
        data = JSON.parse(e.data);
      } catch (e) {
        return;
      }
      if (data.type !== 'tabData') {
        return;
      }
      var tabData = data.message;

      if (tabData.type == 'page_meta_parsed') {
        this.get('scraperService')._promiseByUrl[tabData.original] = new Ember.RSVP.Promise(function (resolve) {
          resolve({
            title: tabData.title,
            description: tabData.description,
            imgs: tabData.images
          });
        });
      }

      this.get('embedService').embed(tabData.original).then(function (result) {
        var props = {
          url: result.url,
          message: tabData.title,
          embedded: !!result.embedCode,
          _hasRemotePostRan: true
        };
        if (_this.get('model')) {
          _this.setProperties(props);
        } else {
          _this.set('onModelProps', props);
        }
      });
    },
    willDestroy: function willDestroy() {
      window.removeEventListener('message', this.onMessage, false);
    },


    openObserver: Ember.observer('open', function () {
      window.parent.postMessage(JSON.stringify({ message: 'overlayClosed' }), "*");
      if (this.get('closeWinOnFinish')) {
        window.close();
      }
    }),

    modelObserver: Ember.observer('model', function () {
      var props = this.get('onModelProps');
      if (props) {
        this.setProperties(props);
      }
    }),

    actions: {
      beforeShare: function beforeShare() {
        //we do nothing here...
      },
      onShare: function onShare() {
        this.toggleProperty('open');
      }
    }
  });
});