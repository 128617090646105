define('es-frontend/components/fill-img', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['fill-img'],
    classNameBindings: ['_loadingState'],
    attributeBindings: ['style'],
    width: 120,
    height: 120,
    src: null,
    title: null,
    _img: null,
    _loadingState: null,

    /* eslint-disable ember/no-on-calls-in-components */
    srcObserver: Ember.on('init', Ember.observer('src', function () {
      var _this = this;

      //Irrespective of render, when the src changes, we try to load a new image based on it...
      var src = this.get('src');
      if (!src) {
        this.setProperties({
          _img: null,
          _loadingState: 'no-img'
        });
        return;
      }
      this.set('_loadingState', 'loading');
      var img = new Image();
      img.onload = function () {
        if (_this.isDestroying || _this.isDestroyed || _this.get('src') != src) {
          return;
        }
        _this.setProperties({
          _loadingState: 'loaded',
          _img: img
        });
      };
      img.onerror = function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        _this.set('_loadingState', 'no-img');
      };
      img.src = src;
    })),
    /* eslint-disable ember/no-on-calls-in-components */

    _imgObserver: Ember.observer('_img', function () {
      var img = this.get('_img');
      if (!img) {
        this.$('>img').detach();
        return;
      }
      var bounds = this.get('_bounds');
      Ember.$(img).css(bounds).addClass('fadein').appendTo(this.$());
    }),

    style: Ember.computed('width', 'height', function () {
      return 'width:' + this.get('width') + 'px;height:' + this.get('height') + 'px;';
    }),

    _bounds: Ember.computed('width', 'height', '_img', function () {
      var img = this.get('_img');
      var width = this.get('width');
      var height = this.get('height');
      var naturalWidth, naturalHeight;
      if (img) {
        naturalWidth = img.naturalWidth;
        naturalHeight = img.naturalHeight;
      } else {
        naturalWidth = naturalHeight = Math.min(width, height);
      }
      var scale = Math.max(width / naturalWidth, height / naturalHeight);
      var scaledWidth = naturalWidth * scale;
      var scaledHeight = naturalHeight * scale;
      var left = (width - scaledWidth) / 2;
      var top = (height - scaledHeight) / 2;
      return {
        marginLeft: left,
        marginTop: top,
        width: scaledWidth,
        height: scaledHeight
      };
    }),

    contentStyle: Ember.computed('_bounds', function () {
      var bounds = this.get('_bounds');
      return 'margin-left:' + bounds.marginLeft + 'px;margin-top:' + bounds.marginTop + 'px;width:' + bounds.width + 'px;height:' + bounds.height + 'px;';
    })
  });
});