define('es-frontend/models/leaderboard', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    accepted_content: _emberData.default.attr('number'),
    active: _emberData.default.attr('boolean'),
    branded_pricelist_id: _emberData.default.attr('number'),
    created_at: _emberData.default.attr('string'),
    custom_range: _emberData.default.attr('boolean'),
    enable_accepted_content_scoring: _emberData.default.attr('boolean'),
    enable_suggested_content_scoring: _emberData.default.attr('boolean'),
    enterprise_customer_id: _emberData.default.attr('number'),
    filters: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    start_time: _emberData.default.attr('string'),
    stop_time: _emberData.default.attr('string'),
    streams: _emberData.default.attr(),
    suggested_content: _emberData.default.attr('number'),
    third_party_pricelist_id: _emberData.default.attr('number'),
    updated_at: _emberData.default.attr('string')
  });
});