define('es-frontend/components/post-time-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['post-time-form', 'bg1', 'row'],
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    amPmOptions: ['AM', 'PM'],
    hourOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    minuteOptions: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    //value: "12:00 AM",
    _hour: 12,
    _minute: 0,
    _amOrPm: 'AM',

    init: function init() {
      this._super.apply(this, arguments);
      this._processValue();
    },


    postTimeObserver: Ember.observer('post_time.post_time', function () {
      this._processValue();
      var afterValueChange = this.get('afterValueChange');
      if (afterValueChange) {
        afterValueChange.call(this, this.get('social_queue'), this.get('post_time'));
      }
    }),

    _processValue: function _processValue() {
      var time = this.get('post_time.post_time');
      if (time) {
        var parts = time.split(/\W/);
        this.setProperties({
          _hour: parseInt(parts[0]),
          _minute: parseInt(parts[1]),
          _amOrPm: parts[2]
        });
      }
    },


    hmObserver: Ember.observer('_hour', '_minute', '_amOrPm', function () {
      var hour = this.get('_hour');
      if (hour < 10) {
        hour = '0' + hour;
      }
      var minute = this.get('_minute');
      if (minute < 10) {
        minute = '0' + minute;
      }
      this.set('post_time.post_time', hour + ':' + minute + ' ' + this.get('_amOrPm'));
    }),

    actions: {
      delete: function _delete() {
        this.get('delete')();
      }
    }
  });
});