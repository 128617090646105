define('es-frontend/components/abstract-share', ['exports', 'es-frontend/utils/no-nulls'], function (exports, _noNulls) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      userSearch: function userSearch(query) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          var queue = _this.get('isTwitterOnly');
          if (!queue) {
            resolve([]);
            return;
          }
          _this.get('ajaxService').ajax({
            url: '/twitter/user_search',
            data: (0, _noNulls.default)({
              handle: queue.get('profile_name'),
              q: query
            })
          }).then(function (results) {
            resolve(results.users);
          }, reject);
        });
      },
      suggestionKey: function suggestionKey(suggestion) {
        return '@' + suggestion.screen_name;
      },
      suggestionValue: function suggestionValue(suggestion) {
        return '@' + suggestion.screen_name;
      }
    }
  });
});