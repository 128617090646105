define('es-frontend/utils/crop-img', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = cropImg;
  function cropImg(img, left, top, width, height, scale, destWidth, destHeight, format) {
    if (!left && !top && width == img.width && height == img.height && destWidth == img.width && destHeight == img.height) {
      return img.src;
    }
    //Build the data url we will actually upload...
    var canvas = document.createElement('canvas');
    canvas.width = destWidth;
    canvas.height = destHeight;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, left / scale, top / scale, width / scale, height / scale, 0, 0, destWidth, destHeight);
    var dataURL = canvas.toDataURL(format || "image/jpeg");
    return dataURL;
  }
});