define('es-frontend/components/manage-subscribable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['subscribable', 'bg1', 'pad', 'margin-bottom'],
    classNameBindings: ['rowCol'],
    widthService: Ember.inject.service('width'),

    rowCol: Ember.computed('widthService.width', function () {
      return this.get('widthService.width') > 400 ? 'row' : 'col';
    }),

    actions: {
      subscribe: function subscribe(subscribable) {
        return this.get('subscribe')(subscribable);
      },
      unsubscribe: function unsubscribe(subscribable) {
        return this.get('unsubscribe')(subscribable);
      }
    }
  });
});