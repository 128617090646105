define('es-frontend/models/customer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    company_name: _emberData.default.attr('string'),
    icon_url: _emberData.default.attr('string'),
    logo_url: _emberData.default.attr('string'),
    salesforce_enabled: _emberData.default.attr('boolean'),
    main_contact: _emberData.default.attr('string'),
    logo: Ember.computed.or('logo_url', 'icon_url'),
    custom_subdomain: _emberData.default.attr('string'),
    show_curated_streams: _emberData.default.attr('boolean'),
    user_stream_management: _emberData.default.attr('boolean'),
    enable_sso: _emberData.default.attr('boolean'),
    is_es: Ember.computed('custom_subdomain', function () {
      return (this.get('custom_subdomain') || '').toLowerCase() == 'es';
    })
    //These were removed because they are long - they are loaded separately
    //terms_conditions: DS.attr('string'),
    //privacy_policy: DS.attr('string'),
    //social_media_policy: DS.attr('string'),
  });
});