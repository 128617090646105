define('es-frontend/controllers/signin/forgot-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    email: Ember.computed.alias('model.user_email'),
    emailValid: null,
    ajaxService: Ember.inject.service('es-ajax'),

    emailObserver: Ember.observer('email', function () {
      this.setProperties({
        emailValid: this.get('emailValid') == null || !!this.get('email'),
        errorMsg: null,
        passwordDisabled: true
      });
    }),

    emailClass: Ember.computed('emailValid', function () {
      var ret = ['input-container', 'col'];
      if (!this.get('emailValid')) {
        ret.push('error');
      }
      return ret.join(' ');
    }),

    actions: {
      forgotPassword: function forgotPassword() {
        var _this = this;

        var email = this.get('email');
        if (!email) {
          this.setProperties({
            errorMsg: 'Email must not be blank!',
            emailValid: false
          });
          return;
        }

        this.get('ajaxService').ajax({
          method: 'POST',
          userPath: false,
          url: 'forgotten_password.json',
          data: {
            email: email
          }
        }).then(function (status) {
          _this.set('message', status.message);
          _this.set('showPasswordReset', true);
        }, function (status) {
          _this.set('errorMsg', status ? status.msg : 'Unknown Error');
        });
      }
    }

  });
});