define('es-frontend/components/es-popup', ['exports', 'es-frontend/utils/ddau'], function (exports, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var openModals = [];

  exports.default = Ember.Component.extend({

    outsideClickCloses: true,
    ignoreCloseClickForClass: null,
    modal: true,
    width: null,
    height: null,
    outerClass: 'es-popup',
    popupClass: 'popup bg1',
    underlayClass: 'underlay',

    init: function init() {
      this._super.apply(this, arguments);
      this._click = this._click.bind(this);
      this._triggerLayout = this._triggerLayout.bind(this);
    },
    didInsertElement: function didInsertElement() {
      if (this.get('open')) {
        this._afterOpen();
      }
    },
    willDestroyElement: function willDestroyElement() {

      var view = document.querySelector('body>.ember-view');
      view.style.height = '';
      view.style.overflow = '';

      Ember.$(window).off('click touchstart', this._click);
      Ember.$(document.body).css('overflow', 'auto');
      this._click = this._triggerLayout = null;
    },
    getPopupElement: function getPopupElement() {
      return Ember.$('#' + this.elementId + 'Popup');
    },
    getUnderlayElement: function getUnderlayElement() {
      return Ember.$('#' + this.elementId + 'Underlay');
    },


    _openObserver: Ember.observer('open', function () {
      var open = this.get('open');
      if (this.get('modal')) {
        if (openModals.length == (open ? 0 : 1)) {
          Ember.$('html').css('overflow', open ? 'hidden' : 'auto');
        }
      }
      if (open) {
        this._afterOpen();
      } else {
        this._beforeClose();
      }
    }),

    _afterOpen: function _afterOpen() {
      var _this = this;

      Ember.run.later(function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        _this.getPopupElement().css('opacity', 1);
        _this.getUnderlayElement().css('opacity', 1);
        _this.set('_isOpen', true);
        if (_this.get('outsideClickCloses')) {
          Ember.$(window).on('click touchstart', _this._click);
        }
        Ember.$(window).on('resize', _this._triggerLayout);
        Ember.$(document.body).css('overflow', 'hidden');

        var view = document.querySelector('body>.ember-view');

        // Only one modal should be shown at a time, and all others should be hidden
        if (_this.get('modal')) {

          view.style.height = window.document.scrollingElement.clientHeight - 100 + 'px';
          view.style.overflow = "hidden";

          for (var m = 0; m < openModals.length; m++) {
            var otherId = '#' + openModals[m].elementId + "Outer";
            var other = Ember.$(otherId);
            if (other.css('opacity') != 0) {
              other.find('>div').animate({ opacity: 0 }, 'fast');
              _this.set('toShowOnClose', otherId);
              break;
            }
          }
          openModals.push(_this);
        }

        _this._triggerLayout();
        if (_this.afterOpen) {
          _this.afterOpen();
        }
      });
    },
    _beforeClose: function _beforeClose() {
      var _this2 = this;

      if (this.beforeClose) {
        this.beforeClose();
      }

      // Show the modal that was previously hidden
      var index = openModals.indexOf(this);
      if (index >= 0) {
        openModals.splice(index, 1);
        var toShowOnClose = this.get('toShowOnClose');
        if (toShowOnClose) {
          Ember.$(toShowOnClose).find('>div').animate({ opacity: 1 }, 'fast');
        }
      }

      var view = document.querySelector('body>.ember-view');
      view.style.height = '';
      view.style.overflow = '';

      Ember.$(window).off('click touchstart', this._click);
      Ember.$(document.body).css('overflow', 'auto');
      this.getPopupElement().css('opacity', 0);
      this.getUnderlayElement().css('opacity', 0);
      Ember.run.later(function () {
        Ember.$(window).off('resize', _this2._triggerLayout);
        if (_this2.isDestroying || _this2.isDestroyed) {
          return;
        }
        _this2.set('_isOpen', false);
      }, 300);
    },
    _click: function _click(event) {
      var elements = Ember.$(event.target).parents().add(event.target);
      var ignoreCloseClickForClass = this.get('ignoreCloseClickForClass');
      if (ignoreCloseClickForClass && elements.hasClass(ignoreCloseClickForClass)) {
        return;
      }
      if (elements.is(this.getPopupElement())) {
        return;
      }
      this.actions.openChanged.call(this, false);
    },


    _layoutObserver: Ember.observer('left', 'top', 'width', 'height', 'triggerLayout', function () {
      this._triggerLayout();
    }),

    _triggerLayout: function _triggerLayout() {
      this._layout(this.getPopupElement(), this.getUnderlayElement());
    },
    _layout: function _layout(popupElement, underlayElement) {
      if (underlayElement.length) {
        this._layoutUnderlay(underlayElement);
      }
      this._layoutPopup(popupElement);
    },
    _layoutUnderlay: function _layoutUnderlay(underlayElement) {
      underlayElement.css({
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 30
      });
    },
    _layoutPopup: function _layoutPopup(popupElement) {
      popupElement.css({
        transform: 'translate(-50%,-50%)'
      });
    },


    actions: {
      openChanged: _ddau.default.attr('open')
    }
  });
});