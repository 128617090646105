define('es-frontend/transitions', ['exports', 'es-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    var options = _environment.default.environment == 'test' ? { duration: 0 } : {};

    this.transition(this.use('fade', options));

    this.transition(this.toValue(function (toValue, fromValue) {
      return (toValue ? toValue.index : null) > (fromValue ? fromValue.index : null);
    }), this.use('toLeft'), this.reverse('toRight', options));
  };
});