define('es-frontend/routes/client/analytics', ['exports', 'es-frontend/utils/constants', 'moment'], function (exports, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      start: {
        refreshModel: true,
        replace: false
      },
      end: {
        refreshModel: true,
        replace: false
      }
    },
    esAjax: Ember.inject.service('es-ajax'),

    model: function model(params) {
      var start = params.start || (0, _moment.default)().add(-7, 'day').format(_constants.default.VISIBLE_DATE);
      var end = params.end || (0, _moment.default)().format(_constants.default.VISIBLE_DATE);
      var client = this.modelFor('client');
      return Ember.RSVP.hash({
        summary: this.get('esAjax').ajax({
          url: '/v1/reports/activity.json',
          data: {
            start_date: start,
            end_date: end
          }
        }),
        activity: [],
        start: start,
        end: end,
        user: client.get('user'),
        customer: client.get('customer'),
        client: client.get('client'),
        features: client.get('features')
      });
    },
    beforeModel: function beforeModel() {
      document.title = 'Analytics - EveryoneSocial';
    }
  });
});