define("es-frontend/utils/is-ios-app", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isIOSApp;

  function isTop() {
    try {
      return window.top == window;
    } catch (e) {
      return false; // error indicates cross domain
    }
  }

  function isMobileSafari() {
    return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);
  }

  function isIOSApp() {
    return isMobileSafari() && !isTop();
  }
});