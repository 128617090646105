define('es-frontend/routes/remote-share', ['exports', 'es-frontend/utils/abstract-client-route'], function (exports, _abstractClientRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abstractClientRoute.default.extend({

    queryParams: {
      url: {
        refreshModel: true,
        replace: false
      },
      closeWinOnFinish: {
        refreshModel: false,
        replace: false
      }
    },

    beforeModel: function beforeModel() {
      Ember.$('html').addClass('remote-share');
    }
  });
});