define('es-frontend/components/manage-personal-stream', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['personal-stream', 'bg1', 'pad', 'margin-bottom'],
    ajaxService: Ember.inject.service('es-ajax'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('personalStreamName', this.get('personalStream.name'));
    },


    actions: {
      deletePersonalStream: function deletePersonalStream() {
        var _this = this;

        this.deletePersonalStream(this.personalStream).then(function () {
          _this.get('toast').info('Stream Removed');
        });
      },
      editFormSubmitted: function editFormSubmitted() {
        var _this2 = this;

        if (this.get('editing')) {
          this.set('personalStream.name', this.get('personalStreamName'));
          this.updatePersonalStream(this.personalStream).then(function () {
            _this2.get('toast').info('Stream Updated');
            _this2.set('editing', false);
          });
          return;
        }
        this.toggleProperty('editing');
      },
      addFeedSubmitted: function addFeedSubmitted() {
        var _this3 = this;

        if (!this.newSource) {
          return;
        }
        if (this.get('personalStream.sources').find(function (source) {
          return source.url == _this3.newSource.trim();
        })) {
          return;
        }
        this.addSource(this.personalStream, this.newSource).then(function () {
          _this3.get('toast').info('Source Added');
          _this3.set('personalStream.sources', _this3.get('personalStream.sources').slice());
          _this3.set('newSource', '');
        });
      },
      deleteSource: function deleteSource(source) {
        var _this4 = this;

        var data = {
          cs: this.get('personalStream.id'),
          source_id: source.id
        };
        if (source.source_keyword) {
          data.keyword = {
            keyword: true
          };
        }
        this.get('ajaxService').ajax({
          method: 'DELETE',
          url: '/sources/' + source.id,
          data: data
        }).then(function () {
          var sources = _this4.get('personalStream.sources').slice();
          var index = sources.findIndex(function (s) {
            return source.id == s.id;
          });
          sources.splice(index, 1);
          _this4.set('personalStream.sources', sources);
        });
      }
    }
  });
});