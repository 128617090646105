define('es-frontend/components/profile-img-upload', ['exports', 'es-frontend/utils/ddau'], function (exports, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['profile_img_upload', 'row'],
    widthService: Ember.inject.service('width'),
    previewWidth: 375,
    open: false,
    left: null,
    top: null,
    width: null,
    height: null,

    actions: {
      imgURLChanged: _ddau.default.attr('imgURL'),
      uploadURLChanged: _ddau.default.attr('uploadURL'),
      imgChanged: _ddau.default.attr('img'),
      leftChanged: _ddau.default.attr('left'),
      topChanged: _ddau.default.attr('top'),
      widthChanged: _ddau.default.attr('width'),
      heightChanged: _ddau.default.attr('height'),
      scaleChanged: _ddau.default.attr('scale'),
      setImg: function setImg(img) {
        var _this = this;

        this.get('save')(img.src, img, 0, 0, img.width, img.height, 1, Ember.$('#' + this.elementId + 'form')).then(function (imgURL) {
          _this.actions.imgURLChanged.call(_this, imgURL);
          _this.set('open', false);
        });
      },
      save: function save() {
        var _this2 = this;

        this.get('save')(this.get('uploadURL'), this.get('img'), this.get('left'), this.get('top'), this.get('width'), this.get('height'), this.get('scale'), Ember.$('#' + this.elementId + 'form')).then(function (imgURL) {
          _this2.actions.imgURLChanged.call(_this2, imgURL);
          _this2.set('open', false);
        });
      },
      openPopup: function openPopup() {
        if (this.get('widthService.small')) {
          this.$('input[type=file]').click();
        } else {
          this.set('open', true);
        }
      },
      clearUpload: function clearUpload() {
        var _this3 = this;

        Ember.run.later(function () {
          _this3.actions.scaleChanged.call(_this3, null);
          _this3.actions.uploadURLChanged.call(_this3, null);
          _this3.actions.imgChanged.call(_this3, null);
        });
      }
    }
  });
});