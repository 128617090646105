define('es-frontend/adapters/schedule', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    esAjax: Ember.inject.service('es-ajax'),

    findRecord: function findRecord(store, type, id) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('esAjax').ajax({
          url: '/v1/social_queues/' + id + '/schedule_rows.json'
        }).then(function (schedule_rows) {
          var ret = {
            id: id,
            post_times: []
          };
          if (schedule_rows.length) {
            var schedule = schedule_rows[0];
            ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].forEach(function (day) {
              ret[day] = schedule[day];
            });
          }
          schedule_rows.forEach(function (row) {
            ret.post_times.push({
              id: row.id,
              post_time: row.post_time,
              deleted: false
            });
          });
          resolve(ret);
        }, reject);
      });
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var rsvps = [];
        var esAjax = _this2.get('esAjax');
        var changes = snapshot.changedAttributes();

        var _loop = function _loop() {
          var change = changes[key];
          if (['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].indexOf(key) >= 0) {
            rsvps.push(esAjax.ajax({
              url: '/v1/social_queues/' + snapshot.id + '/schedule_rows/toggle_day.json',
              method: 'POST',
              contentType: 'application/json',
              data: JSON.stringify({ day: key })
            }));
          } else if (key == 'post_times') {
            change[1].forEach(function (post_time) {
              var ajax;
              if (post_time.deleted) {
                ajax = esAjax.ajax({
                  url: '/v1/social_queues/' + snapshot.id + '/schedule_rows/' + post_time.id + '.json',
                  method: 'DELETE'
                });
                rsvps.push(ajax);
                ajax.then(function () {
                  change[1].splice(change[1].indexOf(post_time), 1);
                });
                return;
              }
              if (post_time.id < 0) {
                ajax = esAjax.ajax({
                  url: '/v1/social_queues/' + snapshot.id + '/schedule_rows.json',
                  method: 'POST',
                  contentType: 'application/json',
                  data: JSON.stringify({
                    social_queue_id: snapshot.id,
                    post_time: post_time.post_time,
                    sun: post_time.sun,
                    mon: post_time.mon,
                    tue: post_time.tue,
                    wed: post_time.wed,
                    thu: post_time.thu,
                    fri: post_time.fri,
                    sat: post_time.sat
                  })
                });
                rsvps.push(ajax);
                ajax.then(function (response) {
                  post_time.id = response.id;
                });
              } else {
                rsvps.push(esAjax.ajax({
                  url: '/v1/social_queues/' + snapshot.id + '/schedule_rows/' + post_time.id + '.json',
                  method: 'PUT',
                  contentType: 'application/json',
                  data: JSON.stringify({
                    id: post_time.id,
                    social_queue_id: snapshot.id,
                    post_time: post_time.post_time,
                    sun: post_time.sun,
                    mon: post_time.mon,
                    tue: post_time.tue,
                    wed: post_time.wed,
                    thu: post_time.thu,
                    fri: post_time.fri,
                    sat: post_time.sat
                  })
                }));
              }
            });
          }
        };

        for (var key in changes) {
          _loop();
        }

        var schedule = snapshot.attributes();
        schedule.id = snapshot.id;
        Ember.RSVP.all(rsvps).then(function () {
          resolve(schedule);
        }, reject);
      });
    }
  });
});