define('es-frontend/instance-initializers/ember-cli-airbrake', ['exports', 'es-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function registerEmberOnError(notifyFn) {
    var originalOnError = Ember.onerror || Ember.K;
    Ember.onerror = function (err) {
      originalOnError(err);
      notifyFn(err);
    };
  }

  function registerWindowOnError(notifyFn) {
    window.onerror = function (message, file, line, column, error) {
      if (message === 'Script error.') {
        // Ignore.
        return;
      }

      error = error || { error: {
          message: message,
          fileName: file,
          lineNumber: line,
          columnNumber: column || 0
        } };

      notifyFn(error);
    };
  }

  function initialize(instance) {
    // see http://emberjs.com/deprecations/v2.x/#toc_ember-applicationinstance-container
    var lookup = instance.lookup ? function () {
      return instance.lookup.apply(instance, arguments);
    } : function () {
      var _instance$container;

      return (_instance$container = instance.container).lookup.apply(_instance$container, arguments);
    };

    var notifyFn = function notifyFn(error) {
      var airbrake = lookup('service:airbrake');
      airbrake.notify(error);
    };

    if (_environment.default.airbrake) {
      registerEmberOnError(notifyFn);
      registerWindowOnError(notifyFn);
    }
  }

  exports.default = {
    name: 'ember-cli-airbrake',
    initialize: initialize
  };
});