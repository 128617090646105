define('es-frontend/controllers/client/analytics', ['exports', 'moment', 'es-frontend/utils/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var VISIBLE_DATE = _constants.default.VISIBLE_DATE;
  exports.default = Ember.Controller.extend({
    widthService: Ember.inject.service('width'),
    queryParams: ['start', 'end', 'sort', 'direction', 'page', 'size'],
    start: null,
    end: null,
    sort: 'num_posts',
    direction: 'desc',
    page: 1,
    size: 20,
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    sortOptions: ['Most Recent', 'Least Recent', 'Most Shares', 'Most Clicks', 'Most Engagements', 'Least Shares', 'Least Clicks', 'Least Engagements'],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    sortOption: Ember.computed('sort', 'direction', function () {
      var ret = [];
      ret.push(this.get('direction') == 'desc' ? 'Most' : 'Least');
      switch (this.get('sort')) {
        case 'total_clicks':
          ret.push('Clicks');
          break;
        case 'total_engagements':
          ret.push('Engagements');
          break;
        case 'updated_at':
          ret.push('Recent');
          break;
        default:
          ret.push('Shares');
      }
      return ret.join(' ');
    }),

    startChanged: Ember.observer('model.start', function () {
      this.set('start', this.get('model.start'));
    }),

    endChanged: Ember.observer('model.end', function () {
      this.set('end', this.get('model.end'));
    }),

    updateActivityTable: Ember.observer('start', 'end', 'sort', 'direction', 'page', 'size', 'model', function () {
      Ember.run.cancel(this.timer);
      this.timer = Ember.run.later(this, this.loadActivity);
    }),

    displaying: Ember.computed('model.{paging,activity}', function () {
      var paging = this.get('model.paging');
      if (!paging) {
        return 'No results found';
      }
      var first = paging.per_page * (paging.current_page - 1) + 1;
      var last = first + this.get('model.activity.length') - 1;
      var count = paging.current_page == paging.num_pages ? last : paging.num_pages * paging.per_page;
      return 'Displaying ' + first + '-' + last + ' of ' + count;
    }),

    loadActivity: function loadActivity() {
      var _this = this;

      this.store.query('activity', {
        start_date: this.get('start'),
        end_date: (0, _moment.default)(this.get('end'), VISIBLE_DATE).add(1, 'days').format(VISIBLE_DATE), // visible is inclusive
        sort: this.get('sort'),
        direction: this.get('direction'),
        page: this.get('page'),
        size: this.get('size')
      }).then(function (activity) {
        _this.set('model.activity', activity);
        _this.set('model.paging', activity.meta);
      }, function (error) {
        throw error;
      });
    },


    pages: Ember.computed('model.paging', function () {
      var ret = [];
      var paging = this.get('model.paging');
      if (!paging) {
        return ret;
      }
      var min = Math.max(1, paging.current_page - 2);
      var max = Math.min(paging.num_pages, paging.current_page + 2);
      for (var i = min; i <= max; i++) {
        ret.push(i);
      }
      return ret;
    }),

    emptyRows: Ember.computed('model.activity.length', function () {
      var len = 12 - (this.get('model.activity.length') || 0);
      var ret = [];
      for (var i = 0; i < len; i++) {
        ret.push(i);
      }
      return ret;
    }),

    rangeStr: Ember.computed('start', 'end', function () {
      var start = (0, _moment.default)(this.get('start'), _constants.default.VISIBLE_DATE);
      var end = (0, _moment.default)(this.get('end'), _constants.default.VISIBLE_DATE);
      return start.format('MMM DD YYYY') + ' - ' + end.format('MMM DD YYYY');
    }),

    actions: {
      loadPage: function loadPage(page) {
        this.set('page', page);
      },
      prevPage: function prevPage() {
        var page = this.get('page');
        if (page > 1) {
          this.set('page', page - 1);
        }
      },
      nextPage: function nextPage() {
        var page = this.get('page');
        if (page < this.get('model.paging.num_pages')) {
          this.set('page', page + 1);
        }
      },
      setSort: function setSort(sort) {
        var properties = {
          sort: sort,
          direction: 'desc'
        };
        if (this.get('sort') == sort) {
          if (this.get('direction') == 'desc') {
            properties.direction = 'asc';
          }
        }
        this.setProperties(properties);
      },
      sortOptionChanged: function sortOptionChanged(option) {
        var sort;
        if (option.endsWith('Engagements')) {
          sort = 'total_engagements';
        } else if (option.endsWith('Clicks')) {
          sort = 'total_clicks';
        } else if (option.endsWith('Recent')) {
          sort = 'updated_at';
        } else {
          sort = 'num_posts';
        }
        this.setProperties({
          sort: sort,
          direction: option.startsWith('Most') ? 'desc' : 'asc'
        });
      },
      dateRangeChanged: function dateRangeChanged(start, end) {
        if (!start) {
          this.get('toast').error('Please select a date range');
          return;
        }
        if (!end) {
          end = start;
        }
        this.setProperties({
          start: start,
          end: end,
          dateRangeOpen: false
        });
      }
    }
  });
});