define('es-frontend/components/connect-social-queues', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['connect-social-queues', 'row-center'],
    size: 30,
    twitter: true,
    facebook: true,
    linkedin: true
  });
});