define('es-frontend/routes/client/queue/queued', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    store: Ember.inject.service('store'),

    model: function model() {
      return this.get('store').findAll('social-queue');
    },
    resetController: function resetController(controller, model) {
      this._super(controller, model);
      var numDays = controller.get('numDays');
      controller.setProperties({
        start: (0, _moment.default)(),
        end: (0, _moment.default)().add(numDays, 'days'),
        queued_items: []
      });
    }
  });
});