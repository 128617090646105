define('es-frontend/components/es-walkthrough', ['exports', 'ember-local-storage', 'es-frontend/utils/absolute-position-of'], function (exports, _emberLocalStorage, _absolutePositionOf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    widthService: Ember.inject.service('width'),
    model: (0, _emberLocalStorage.storageFor)('es-walkthrough'),
    segment: Ember.inject.service('segment'),
    step: 1,

    /* eslint-disable ember/no-on-calls-in-components */
    stepObserver: Ember.on('init', Ember.observer('step', function () {
      Ember.$('html').css('overflow', this.get('model.visible') ? 'hidden' : 'auto');
      switch (this.get('step')) {
        case 1:
          this.resetUnderlay();
          return;
        case 2:
          this.setUnderlayFromAnchor(Ember.$('#streamsMenuItem a'), Ember.$('.menu-inner').css('position') == 'fixed' ? 'fixed' : 'absolute');
          window.scrollTo(0, 0);
          return;
        case 3:
          this.setUnderlayFromAnchor(Ember.$('.leaderboard-summary .top'), 'absolute');
          window.scrollTo(0, 0);
          return;
        case 4:
          this.setUnderlayFromAnchor(Ember.$('button.share'), 'absolute');
          return;
        default:
          this.resetUnderlay();
      }
    })),
    /* eslint-enable ember/no-on-calls-in-components */

    resetUnderlay: function resetUnderlay() {
      this.setProperties({
        underlayClass: 'dark-underlay',
        underlayStyle: 'position:fixed; left:0; top:0; width:100%; height:100%; z-index: 30; transition: none;'
      });
    },
    setUnderlayFromAnchor: function setUnderlayFromAnchor(anchor, pos) {
      if (!anchor.length) {
        this.resetUnderlay();
        return;
      }
      var position = (0, _absolutePositionOf.default)(anchor);
      this.setProperties({
        underlayClass: 'dark-donut',
        underlayStyle: 'position:' + pos + '; left:' + position.left + 'px; top:' + position.top + 'px; width:' + anchor.outerWidth() + 'px; height:' + anchor.outerHeight() + 'px; z-index:30; transition: none;'
      });
      anchor.first().focus();
    },
    hide: function hide() {
      this.set('model.visible', false);
      Ember.$('html').css('overflow', 'auto');
    },


    actions: {
      increment: function increment() {
        var step = this.get('step');
        var next = step + 1;
        if (next >= 5) {
          // Done
          this.hide();
        } else {
          this.set('step', next);
        }
        this.get('segment').track('Tutorial Action', {
          user_email: this.get('user.email'),
          step: step,
          totalSteps: 4,
          action: 'View',
          url: location.hash
        });
      },
      cancel: function cancel() {
        this.hide();
        this.get('segment').track('Tutorial Action', {
          user_email: this.get('user.email'),
          step: this.get('step'),
          totalSteps: 4,
          action: 'Cancel',
          url: location.hash
        });
      },
      layoutStep1: function layoutStep1(element) {
        element.css({
          position: 'fixed',
          right: 20,
          left: 'auto',
          bottom: 20,
          top: 'auto',
          marginBottom: 0,
          zIndex: 2147483002
        });
        Ember.$('#intercom-container').css('zIndex', 2147483001); //Intercom takes the absolute max z-index (Presumptious)
      }
    }
  });
});