define('es-frontend/services/xing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    share: function share(url) {
      return new Ember.RSVP.Promise(function (resolve) {
        var toOpen = 'https://www.xing.com/spi/shares/new?url=' + encodeURIComponent(url);
        window.open(toOpen, '_blank');
        resolve();
      });
    }
  });
});