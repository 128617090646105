define('es-frontend/components/es-input', ['exports', 'es-frontend/utils/ddau'], function (exports, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['es-input'],
    classNameBindings: ['invalidAndTouched:error'],
    valid: true,
    label: true,
    focus: false,
    touched: false,
    type: 'text',
    labelClass: 'txt6',
    inputClass: 'fill bg3 txt3',
    disabled: false,
    placeholder: Ember.computed('title', 'label', function () {
      return this.get('label') ? null : this.get('title');
    }),
    invalidAndTouched: Ember.computed('valid', 'touched', function () {
      return !this.get('valid') && this.get('touched');
    }),
    didInsertElement: function didInsertElement() {
      if (this.get('focus')) {
        this.$('input').focus();
      }
    },
    focusIn: function focusIn() {
      this.actions.focusChanged.call(this, true);
    },
    focusOut: function focusOut() {
      this.actions.focusChanged.call(this, false);
      this.actions.touchedChanged.call(this, true);
    },

    focusObserver: Ember.observer('focus', function () {
      this.didInsertElement();
    }),
    valueObserver: Ember.observer('value', function () {
      this.set('_value', this.get('value'));
    }),
    _valueObserver: Ember.observer('_value', function () {
      this.actions.valueChanged.call(this, this.get('_value'));
    }),
    actions: {
      focusChanged: _ddau.default.attr('focus'),
      valueChanged: _ddau.default.attr('value'),
      touchedChanged: _ddau.default.attr('touched')
    }

  });
});