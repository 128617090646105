define('es-frontend/services/segment', ['exports', 'es-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var METHODS = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"];

  function createAnalyticsMethod(method) {
    return function () {
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      this.push(args);
      return this;
    };
  }

  function createServiceMethod(method) {
    return function () {
      window.analytics[method].apply(window.analytics, arguments);
    };
  }

  var service = {

    segmentWriteKey: _environment.default.APP.segmentWriteKey,

    init: function init() {
      var analytics = window.analytics || (window.analytics = []);
      if (!analytics.initialize) {
        analytics.invoked = true;
        METHODS.forEach(function (method) {
          analytics[method] = createAnalyticsMethod(method);
        });
        analytics.SNIPPET_VERSION = "4.1.0";
        analytics._loadOptions = {
          initialPageview: false
        };

        var script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = ("https:" === document.location.protocol ? "https://" : "http://") + "cdn.segment.com/analytics.js/v1/" + this.get('segmentWriteKey') + "/analytics.min.js";
        document.body.appendChild(script);
      }
    }
  };

  METHODS.forEach(function (method) {
    service[method] = createServiceMethod(method);
  });

  exports.default = Ember.Service.extend(service);
});