define('es-frontend/adapters/user-notification', ['exports', 'es-frontend/adapters/application', 'es-frontend/utils/query-params'], function (exports, _application, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindAll: function urlForFindAll() {
      var qp = (0, _queryParams.default)();
      var params = qp.user_id ? '?user_id=' + qp.user_id : '';
      return '/user_notifications.json' + params;
    }
  });
});