define('es-frontend/components/es-underlay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    underlayClass: 'underlay',
    underlayStyle: 'position:fixed; left:0; top:0; width:100%; height:100%; z-index: 30;',
    open: false,
    _open: false,

    /* eslint-disable ember/no-on-calls-in-components */
    openObserver: Ember.on('init', Ember.observer('open', function () {
      var _this = this;

      if (this.get('open')) {
        this.set('_open', true);
      } else {
        Ember.run.later(function () {
          _this.set('_open', false);
        });
      }
    }))
    /* eslint-enable ember/no-on-calls-in-components */

  });
});