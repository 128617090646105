define('es-frontend/adapters/social-queue', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindAll: function urlForFindAll() {
      return '/v1/social_queues.json';
    },


    //TODO: This is very customized - it would be nice to get Ember to send patch requests and to
    //have the results in an understandable format.
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this = this;

      //return this._super(...arguments);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.ajax('/v1/social_queues/' + snapshot.id + '.json', 'DELETE').then(function () {
          var ret = snapshot.record.toJSON();
          ret.id = snapshot.id;
          resolve(ret);
        }, reject);
      });
    }
  });
});