define('es-frontend/helpers/fn', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fn = fn;
  function fn(params /*, hash*/) {
    var fn = params[0];
    var context = params[1] || window;
    var args = params.slice(2);
    return fn.apply(context, args);
  }

  exports.default = Ember.Helper.helper(fn);
});