define('es-frontend/controllers/client/queue/queued', ['exports', 'moment', 'es-frontend/utils/constants', 'es-frontend/utils/strings', 'es-frontend/utils/get-error-msg'], function (exports, _moment, _constants, _strings, _getErrorMsg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var VISIBLE_DATE = _constants.default.VISIBLE_DATE;


  var NUM_DAYS = 31;

  exports.default = Ember.Controller.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    numDays: NUM_DAYS,
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    start: (0, _moment.default)(),
    end: (0, _moment.default)().add(NUM_DAYS, 'days'),
    queued_items: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    queuedItemsByDay: Ember.computed('queued_items', function () {
      var ret = [];
      var prevDay;
      var dayItems;
      var today = (0, _moment.default)().format('dddd, MMMM D');
      this.get('queued_items').forEach(function (item) {
        var day = (0, _moment.default)(item.get('post_at')).format('dddd, MMMM D');
        if (prevDay != day) {
          prevDay = day;
          if (day == today) {
            day = 'Today - ' + day;
          }
          ret.push(dayItems = {
            day: day,
            items: []
          });
        }
        dayItems.items.push(item);
      });
      return ret;
    }),

    actions: {
      load: function load() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          var start = _this.get('start');
          var end = _this.get('end');
          _this.store.query('queued-item', {
            start_time: start.format(VISIBLE_DATE),
            end_time: end.format(VISIBLE_DATE)
          }).then(function (queued_items) {
            _this.setProperties({
              start: start.add(NUM_DAYS, 'days'),
              end: end.add(NUM_DAYS, 'days')
            });
            var ids = {};
            var newArray = _this.get('queued_items').slice();
            var initialLength = newArray.length;
            newArray.forEach(function (queued_item) {
              ids[queued_item.id] = 1;
            });
            queued_items.forEach(function (queued_item) {
              if (!queued_item.get('posted_at') && !ids[queued_item.id]) {
                newArray.push(queued_item);
              }
            });
            if (newArray.length == initialLength) {
              resolve(true);
              return;
            }
            newArray.sort(function (a, b) {
              var aValue = new Date(a.get('post_at')).getTime();
              var bValue = new Date(b.get('post_at')).getTime();
              return aValue - bValue; // inverse order for history
            });
            _this.set('queued_items', newArray);
            resolve(true);
          }, reject);
        });
      },
      deleteRecord: function deleteRecord(queued_item) {
        var _this2 = this;

        queued_item.destroyRecord().then(function () {
          var queued_items = _this2.get('queued_items').slice();
          var index = queued_items.indexOf(queued_item);
          queued_items.splice(index, 1);
          _this2.set('queued_items', queued_items);
          _this2.get('toast').info('Post Deleted');
        });
      },
      shareNow: function shareNow(queued_item) {
        var _this3 = this;

        this.get('ajaxService').ajax({
          url: '/v1/social_queues/' + queued_item.get('social_queue_id') + '/queued_posts/' + queued_item.id + '/deliver.json',
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(queued_item.toJSON())
        }).then(function () {
          var queued_items = _this3.get('queued_items').slice();
          var index = queued_items.indexOf(queued_item);
          queued_items.splice(index, 1);
          _this3.set('queued_items', queued_items);
          _this3.get('toast').info('Post Shared');
        }, function (result) {
          if (result && result.status == 422) {
            var social_queue_id = queued_item.get('social_queue_id');
            var social_queue = _this3.get('model').find(function (queue) {
              return queue.id == social_queue_id;
            });
            social_queue.set('invalid_token', true);
            result = 'You must reconnect your ' + _strings.default.capitalize(social_queue.get('service')) + ' account before posting content.';
          }
          _this3.get('toast').error((0, _getErrorMsg.default)(result));
        });
      }
    }
  });
});