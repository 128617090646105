define('es-frontend/controllers/client/streams', ['exports', 'moment', 'es-frontend/utils/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    widthService: Ember.inject.service('width'),
    store: Ember.inject.service('store'),
    router: Ember.inject.service('router'),
    subscribableService: Ember.inject.service('subscribable'),
    ajaxService: Ember.inject.service('es-ajax'),

    canSubscribe: Ember.computed('model.{selectedStreamId,selectedStream}', function () {
      var streamId = this.get('model.selectedStreamId');
      return streamId && streamId != 'all' && !this.get('model.selectedStream');
    }),

    actions: {
      loadSummary: function loadSummary() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.RSVP.hash({
            summary: _this.get('store').query('leaderboard-summary', {
              user_client_id: _this.get('model.client.id'),
              start_date: (0, _moment.default)().add(-30, 'day').format(_constants.default.VISIBLE_DATE),
              end_date: (0, _moment.default)().add(1, 'day').format(_constants.default.VISIBLE_DATE)
            }),
            social_queues: _this.get('store').findAll('social-queue')
          }).then(function (hash) {
            var summary = hash.summary;
            var userSummary = summary.findBy('user_id', parseInt(_this.get('model.user.id')));
            _this.setProperties({
              userSummary: userSummary,
              summary: summary,
              social_queues: hash.social_queues
            });
            if (summary.get('length')) {
              resolve();
            } else {
              _this.get('ajaxService').ajax({
                url: '/reports/shares.json'
              }).then(function (count) {
                _this.set('userShareCount', count);
                resolve();
              }, reject);
            }
          }, reject);
        });
      },
      selectedStreamChanged: function selectedStreamChanged(stream) {
        var args = [stream.route];
        if (stream.id) {
          args.push(stream.id);
        }
        var router = this.get('router');
        router.transitionTo.apply(router, args);
      },
      subscribe: function subscribe() {
        var _this2 = this;

        this.get('store').findAll('subscribable').then(function (subscribables) {
          var subscribable = subscribables.findBy('id', _this2.get('model.selectedStreamId'));
          _this2.get('subscribableService').subscribe(subscribable).then(function () {
            _this2.set('model.selectedStreamId', null);
          });
        });
      }
    }
  });
});