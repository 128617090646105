define('es-frontend/services/tasks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    _idCounter: 0,
    tasks: Ember.A([]),
    maxDuration: 20000,

    start: function start(name, maxDuration) {
      var _this = this;

      var id = this.incrementProperty('_idCounter');
      Ember.run.later(function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        _this.get('tasks').pushObject({
          id: id,
          name: name
        });
        _this.notifyPropertyChange('tasks');
      });
      Ember.run.later(function () {
        _this.finish(id);
      }, maxDuration || this.get('maxDuration'));
      return id;
    },
    finish: function finish(taskId) {
      var _this2 = this;

      Ember.run.later(function () {
        if (_this2.isDestroying || _this2.isDestroyed) {
          return;
        }
        var tasks = _this2.get('tasks');
        var index = tasks.findIndex(function (task) {
          return task.id == taskId;
        });
        if (index < 0) {
          return false;
        }
        tasks.removeAt(index);
        _this2.notifyPropertyChange('tasks');
      });
    },


    numTasks: Ember.computed('tasks', function () {
      return this.get('tasks.length');
    })
  });
});