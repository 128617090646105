define('es-frontend/routes/client/profile', ['exports', 'es-frontend/utils/asset-url'], function (exports, _assetUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    model: function model(params) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var ajaxService = _this.get('ajaxService');
        var currentUser = _this.modelFor('client').get('user');
        var isCurrentUser = currentUser.id == params.id;
        Ember.RSVP.hash({
          user: isCurrentUser ? currentUser : new Ember.RSVP.Promise(function (resolve, reject) {
            ajaxService.ajax({
              url: '/users/' + params.id + '.json'
            }).then(function (user) {
              resolve(_this.get('store').push({
                data: [{
                  id: user.id,
                  type: 'user',
                  attributes: user
                }]
              })[0]);
            }, reject);
          }),
          customer: _this.modelFor('client').get('customer'),
          client: _this.modelFor('client').get('client'),
          social_queues: isCurrentUser ? new Ember.RSVP.Promise(function (resolve, reject) {
            _this.get('store').findAll('social-queue').then(function (social_queues) {
              resolve(social_queues.toArray());
            }, reject);
          }) : ajaxService.ajax({
            url: '/users/' + params.id + '/social_queues.json'
          }),
          terms: _this.get('store').findRecord('terms', _this.modelFor('client').get('customer.id')),
          timezones: new Ember.RSVP.Promise(function (resolve) {
            if (window.timezones) {
              resolve(window.timezones);
              return;
            }
            window.timezonesCallback = function () {
              resolve(window.timezones);
            };
            var script = document.createElement('script');
            script.async = true;
            script.src = (0, _assetUrl.default)() + '/json/timezones.jsonp';
            document.body.appendChild(script);
          })
        }).then(function (hash) {
          ajaxService.ajax({
            url: '/leaderboard_v2/summary.json',
            data: {
              user_client_id: hash.user.get('client_id'),
              limit: 1
            }
          }).then(function (leaderboard) {
            hash.leaderboard = leaderboard.find(function (lb) {
              return lb.user_id == hash.user.id;
            });
            hash.features = _this.modelFor('client').get('features');
            hash.isCurrentUser = isCurrentUser;
            resolve(hash);
          }, reject);
        }, reject);
      });
    },
    beforeModel: function beforeModel() {
      document.title = 'Profile - EveryoneSocial';
    },
    afterModel: function afterModel(model) {
      document.title = model.user.get('name') + ' - EveryoneSocial';
    }
  });
});