define('es-frontend/controllers/signin/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    email: Ember.computed.alias('model.user_email'),
    password: '',
    errorMsg: null,
    emailValid: null,
    passwordValid: true,

    emailObserver: Ember.observer('email', function () {
      this.setProperties({
        emailValid: this.get('emailValid') == null || !!this.get('email'),
        errorMsg: null
      });
    }),

    passwordObserver: Ember.observer('password', function () {
      this.setProperties({
        passwordValid: !!this.get('password'),
        errorMsg: null
      });
    }),

    emailClass: Ember.computed('emailValid', function () {
      return 'input-container col' + (this.get('emailValid') ? '' : ' error');
    }),

    passwordClass: Ember.computed('passwordValid', function () {
      return 'input-container col' + (this.get('passwordValid') ? '' : ' error');
    }),

    actions: {
      login: function login() {
        var _this = this;

        var email = this.get('email');
        if (!email) {
          this.setProperties({
            errorMsg: 'Email must not be blank!',
            emailValid: false
          });
          return;
        }
        var password = this.get('password');
        if (!password) {
          this.setProperties({
            errorMsg: 'Password must not be blank!',
            passwordValid: false
          });
          return;
        }
        this.get('ajaxService').ajax({
          method: 'POST',
          userPath: false,
          url: 'sessions.json',
          data: {
            email: email,
            password: password
          }
        }).then(function (status) {
          if (status && status.result == 'success') {
            _this.set('password', null); //clear reference to password before continuing
            _this.replaceRoute('client');
          } else {
            _this.setProperties({
              errorMsg: (status || {}).msg || 'Incorrect email or password!',
              emailValid: false,
              passwordValid: false
            });
          }
        }, function () {
          _this.setProperties({
            errorMsg: 'Incorrect email or password!',
            emailValid: false,
            passwordValid: false
          });
        });
      }
    }
  });
});