define('es-frontend/initializers/mobile-safari-hack', ['exports', 'es-frontend/utils/is-ios-app'], function (exports, _isIosApp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    if ((0, _isIosApp.default)()) {
      document.querySelector(application.rootElement).className += ' mobile-safari-hack';
    }
  }

  exports.default = {
    initialize: initialize
  };
});