define('es-frontend/routes/signin/request-access', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    activate: function activate() {
      Ember.run.later(function () {
        Ember.$('input.email').focus();
      });
    },
    model: function model() {
      return this.modelFor('signin');
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.set('showAccessRequested', false);
    }
  });
});