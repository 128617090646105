define('es-frontend/components/img-bounds', ['exports', 'es-frontend/utils/ddau'], function (exports, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var R = 1;
  var B = 2;
  //Bitmask - 0xBR
  var TL = 0;
  var TR = 1;
  var BL = 2;
  var BR = 3;
  var CENTER = 4;

  //Uses both img and imgURL
  exports.default = Ember.Component.extend({
    classNames: ['img-bounds'],
    circle: true,
    scale: null,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    color: '#FFF',
    handleStroke: '#FFF',
    handleFill: '#CCC',
    handleSize: 15,
    editable: true,
    aspectRatio: 0,

    init: function init() {
      this._super.apply(this, arguments);
      this._drag = this._drag.bind(this);
      this._endDrag = this._endDrag.bind(this);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.later(function () {
        if (_this.get('scale') == null) {
          _this.setBoundsFromImg();
        }
      });
    },


    _svgWidth: Ember.computed('_imgWidth', 'handleSize', 'scale', function () {
      return this.get('scale') ? this.get('_imgWidth') + this.get('handleSize') * 2 + 'px' : '100%';
    }),

    _svgHeight: Ember.computed('_imgHeight', 'handleSize', 'scale', function () {
      return this.get('scale') ? this.get('_imgHeight') + this.get('handleSize') * 2 + 'px' : '200px';
    }),

    _imgWidth: Ember.computed('img', 'scale', function () {
      return Math.round(this.get('img.width') * this.get('scale'));
    }),

    _imgHeight: Ember.computed('img', 'scale', function () {
      return Math.round(this.get('img.height') * this.get('scale'));
    }),

    _cx: Ember.computed('left', 'width', 'handleSize', function () {
      var width = this.get('width');
      return width ? Math.round(this.get('left') + width / 2 + this.get('handleSize')) : null;
    }),

    _cy: Ember.computed('top', 'height', 'handleSize', function () {
      var height = this.get('height');
      return height ? Math.round(this.get('top') + height / 2 + this.get('handleSize')) : null;
    }),

    _radius: Ember.computed('width', 'height', function () {
      var diameter = Math.min(this.get('width'), this.get('height'));
      return diameter / 2;
    }),

    _boundsLeft: Ember.computed('left', 'handleSize', function () {
      return this.get('left') + this.get('handleSize');
    }),

    _boundsTop: Ember.computed('top', 'handleSize', function () {
      return this.get('top') + this.get('handleSize');
    }),

    _boundsRight: Ember.computed('_boundsLeft', 'width', function () {
      return this.get('_boundsLeft') + this.get('width');
    }),

    _boundsBottom: Ember.computed('_boundsTop', 'height', function () {
      return this.get('_boundsTop') + this.get('height');
    }),

    _handleLeft: Ember.computed('left', 'handleSize', function () {
      return this.get('left') + Math.round(this.get('handleSize') / 2);
    }),

    _handleTop: Ember.computed('top', 'handleSize', function () {
      return this.get('top') + Math.round(this.get('handleSize') / 2);
    }),

    _handleRight: Ember.computed('_handleLeft', 'width', function () {
      return this.get('_handleLeft') + this.get('width');
    }),

    _handleBottom: Ember.computed('_handleTop', 'height', function () {
      return this.get('_handleTop') + this.get('height');
    }),

    imgObserver: Ember.observer('img', function () {
      var _this2 = this;

      Ember.run.later(function () {
        if (!(_this2.isDestroying || _this2.isDestroyed)) {
          _this2.setBoundsFromImg();
        }
      });
    }),

    aspectRatioObserver: Ember.observer('aspectRatio', function () {
      var _this3 = this;

      Ember.run.later(function () {
        if (!(_this3.isDestroying || _this3.isDestroyed)) {
          _this3.setBoundsFromImg();
        }
      });
    }),

    calculateScale: function calculateScale() {
      var img = this.get('img');
      var currentWidth = this.$().parent().width();
      var scale = img && currentWidth ? (currentWidth - this.get('handleSize') * 2) / img.width : null;
      this.actions.scaleChanged.call(this, scale);
      return scale;
    },
    setBoundsFromImg: function setBoundsFromImg() {
      if (this.get('dragFrom')) {
        //debugger;
      }
      var scale = this.calculateScale();
      if (!scale) {
        return;
      }
      var img = this.get('img');
      var left = 0;
      var top = 0;
      var width = Math.round(img.width * scale);
      var height = Math.round(img.height * scale);
      var aspectRatio = this.get('aspectRatio');
      if (aspectRatio) {
        var widthFromHeight = Math.round(height * aspectRatio);
        var heightFromWidth = Math.round(width / aspectRatio);
        if (heightFromWidth > height) {
          left = Math.round((width - widthFromHeight) / 2);
          width = widthFromHeight;
        } else {
          top = Math.round((height - heightFromWidth) / 2);
          height = heightFromWidth;
        }
      }
      this.actions.leftChanged.call(this, left);
      this.actions.topChanged.call(this, top);
      this.actions.widthChanged.call(this, width);
      this.actions.heightChanged.call(this, height);
    },
    touchStart: function touchStart(event) {
      var target = Ember.$(event.target);
      if (target.hasClass('tl') || target.hasClass('tr') || target.hasClass('bl') || target.hasClass('br')) {
        this.mouseDown(event);
      }
    },
    mouseDown: function mouseDown(event) {
      if (!this.get('editable')) {
        return;
      }
      var screenX = event.screenX;
      var screenY = event.screenY;
      if (event.touches && event.touches.length) {
        var touch = event.touches[0];
        screenX = touch.screenX;
        screenY = touch.screenY;
      }
      event.stopPropagation();
      event.preventDefault();
      var target = Ember.$(event.target);
      if (target.hasClass('tl')) {
        this._beginDrag(screenX, screenY, TL);
      } else if (target.hasClass('tr')) {
        this._beginDrag(screenX, screenY, TR);
      } else if (target.hasClass('bl')) {
        this._beginDrag(screenX, screenY, BL);
      } else if (target.hasClass('br')) {
        this._beginDrag(screenX, screenY, BR);
      } else {
        this._beginDrag(screenX, screenY, CENTER);
      }
    },
    _beginDrag: function _beginDrag(x, y, mode) {
      if (!this.get('editable')) {
        return;
      }
      this.set('dragFrom', {
        screenX: x,
        screenY: y,
        left: this.get('left'),
        top: this.get('top'),
        right: this.get('left') + this.get('width'),
        bottom: this.get('top') + this.get('height'),
        mode: mode
      });
      Ember.$(window).on('mousemove', this._drag).on('touchmove', this._drag).on('mouseup', this._endDrag).on('touchend', this._endDrag).on('touchcancel', this._endDrag);
    },
    _drag: function _drag(event) {
      event.preventDefault();
      var screenX = event.screenX;
      var screenY = event.screenY;
      if (event.touches && event.touches.length) {
        var touch = event.touches[0];
        screenX = touch.screenX;
        screenY = touch.screenY;
      }

      var dragFrom = this.get('dragFrom');

      var dx = screenX - dragFrom.screenX;
      var dy = screenY - dragFrom.screenY;

      var left = dragFrom.left;
      var top = dragFrom.top;
      var right = dragFrom.right;
      var bottom = dragFrom.bottom;

      if (dragFrom.mode == CENTER) {
        if (left + dx < 0) {
          dx = -left;
        }
        if (top + dy < 0) {
          dy = -top;
        }
        if (right + dx > this.get('_imgWidth')) {
          dx = this.get('_imgWidth') - right;
        }
        if (bottom + dy > this.get('_imgHeight')) {
          dy = this.get('_imgHeight') - bottom;
        }
        left += dx;
        right += dx;
        top += dy;
        bottom += dy;

        this.actions.leftChanged.call(this, left);
        this.actions.topChanged.call(this, top);
        this.actions.widthChanged.call(this, right - left);
        this.actions.heightChanged.call(this, bottom - top);

        return;
      }

      if (dragFrom.mode & R) {
        right += dx;
      } else {
        // left
        left += dx;
      }
      if (left > right) {
        dragFrom.mode ^= R;
        var width = dragFrom.right - dragFrom.left;
        if (dragFrom.mode & R) {
          dragFrom.screenX += width;
          dragFrom.left = right;
        } else {
          dragFrom.screenX -= width;
          dragFrom.right = left;
        }
        var tmp = left;
        left = right;
        right = tmp;
      }

      if (dragFrom.mode & B) {
        bottom += dy;
      } else {
        // left
        top += dy;
      }
      if (top > bottom) {
        dragFrom.mode ^= B;
        var height = dragFrom.bottom - dragFrom.top;
        if (dragFrom.mode & B) {
          dragFrom.screenY += height;
          dragFrom.top = bottom;
        } else {
          dragFrom.screenY -= height;
          dragFrom.bottom = top;
        }
        var _tmp = top;
        top = bottom;
        bottom = _tmp;
      }

      left = Math.max(left, 0);
      top = Math.max(top, 0);
      right = Math.min(right, this.get('_imgWidth'));
      bottom = Math.min(bottom, this.get('_imgHeight'));

      var aspectRatio = this.get('aspectRatio');

      if (aspectRatio) {
        var _width = right - left;
        var _height = bottom - top;
        var widthFromHeight = Math.round(_height * aspectRatio);
        var heightFromWidth = Math.round(_width / aspectRatio);
        if (heightFromWidth > _height) {
          var _dx = _width - widthFromHeight;
          if (dragFrom.mode & R) {
            right -= _dx;
          } else {
            left += _dx;
          }
        } else {
          var _dy = _height - heightFromWidth;
          if (dragFrom.mode & B) {
            bottom -= _dy;
          } else {
            top += _dy;
          }
        }
      }

      this.actions.leftChanged.call(this, left);
      this.actions.topChanged.call(this, top);
      this.actions.widthChanged.call(this, right - left);
      this.actions.heightChanged.call(this, bottom - top);
    },
    _endDrag: function _endDrag(event) {
      var _this4 = this;

      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      Ember.$(window).off('mousemove', this._drag).off('touchmove', this._drag).off('mouseup', this._endDrag).off('touchend', this._endDrag).off('touchcancel', this._endDrag);
      Ember.run.later(function () {
        if (!(_this4.isDestroying || _this4.isDestroyed)) {
          _this4.set('dragFrom', null);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._endDrag();
      this._drag = this._endDrag = null;
    },
    click: function click(event) {
      if (!this.get('editable')) {
        return;
      }
      var target = Ember.$(event.target);
      if (this.get('img') && !target.hasClass('draggable') && !this.get('dragFrom')) {
        var clientX = event.offsetX;
        var clientY = event.offsetY;
        if (event.touches && event.touches.length) {
          var touch = event.touches[0];
          clientX = touch.clientX;
          clientY = touch.clientY;
        }
        var width = this.get('width');
        var height = this.get('height');
        var left = Math.min(Math.max(0, Math.round(clientX - width / 2 - this.get('handleSize'))), this.get('_imgWidth') - width);
        var top = Math.min(Math.max(0, Math.round(clientY - height / 2 - this.get('handleSize'))), this.get('_imgHeight') - height);

        this.actions.leftChanged.call(this, left);
        this.actions.topChanged.call(this, top);
      }
    },


    actions: {
      scaleChanged: _ddau.default.attr('scale'),
      leftChanged: _ddau.default.attr('left'),
      topChanged: _ddau.default.attr('top'),
      widthChanged: _ddau.default.attr('width'),
      heightChanged: _ddau.default.attr('height')
    }

  });
});