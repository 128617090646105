define('es-frontend/utils/absolute-position-of', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = absolutePositionOf;
  function absolutePositionOf(element) {
    var $element = Ember.$(element);
    var ret = {
      top: 0,
      left: 0
      /* eslint-disable no-constant-condition */
    };while (true) {
      var tagName = $element.prop('tagName');
      if (tagName == 'BODY' || tagName == 'HTML') {
        return ret;
      }
      var position = $element.position();
      ret.top += position.top;
      ret.left += position.left;
      $element = $element.offsetParent();
    }
    /* eslint-enable no-constant-condition */
  }
});