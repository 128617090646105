define('es-frontend/utils/strings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    initials: function initials(str) {
      str = str || '';
      var tokens = str.split(/\s+/g);
      var firstInitial = tokens[0].substring(0, 1) || '';
      var lastInitial = tokens.length > 1 ? tokens[1].substring(0, 1) : '';
      return firstInitial + lastInitial;
    },
    capitalize: function capitalize(str) {
      var tokens = (str || '').split(/\s+/g);
      for (var t = 0; t < tokens.length; t++) {
        var token = tokens[t];
        if (token.length) {
          tokens[t] = token.substring(0, 1).toUpperCase() + token.substring(1);
        }
      }
      return tokens.join(' ');
    },
    limit: function limit(str, len) {
      if (!str || str.length <= len) {
        return str;
      }
      return str.substring(0, len - 3) + '...';
    }
  };
});