define('es-frontend/controllers/client/queue/history', ['exports', 'moment', 'es-frontend/utils/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var VISIBLE_DATE = _constants.default.VISIBLE_DATE;


  var NUM_DAYS = 7;

  exports.default = Ember.Controller.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    numDays: NUM_DAYS,
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    start: (0, _moment.default)().add(-NUM_DAYS, 'days'),
    end: (0, _moment.default)().add(1, 'days'),
    queued_items: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    loading: false,

    queuedItemsByDay: Ember.computed('queued_items', function () {
      var ret = [];
      var prevDay;
      var dayItems;
      var today = (0, _moment.default)().format('dddd, MMMM D');
      this.get('queued_items').forEach(function (item) {
        var day = (0, _moment.default)(item.get('posted_at')).format('dddd, MMMM D');
        if (prevDay != day) {
          prevDay = day;
          if (day == today) {
            day = 'Today - ' + day;
          }
          ret.push(dayItems = {
            day: day,
            items: []
          });
        }
        dayItems.items.push(item);
      });
      return ret;
    }),

    actions: {
      load: function load() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          var start = _this.get('start');
          var end = _this.get('end');
          if (end < (0, _moment.default)().add(-3, 'months')) {
            resolve(true);
            return;
          }
          _this.store.query('queued-item', {
            start_time: start.format(VISIBLE_DATE),
            end_time: end.format(VISIBLE_DATE)
          }).then(function (queued_items) {
            var numDays = _this.get('numDays');
            _this.setProperties({
              start: start.clone().add(-numDays, 'days'),
              end: start
            });
            var ids = {};
            var newArray = _this.get('queued_items').slice();
            var initialLength = newArray.length;
            newArray.forEach(function (queued_item) {
              ids[queued_item.id] = 1;
            });
            queued_items.forEach(function (queued_item) {
              if (queued_item.get('posted_at') && !ids[queued_item.id]) {
                newArray.push(queued_item);
              }
            });
            if (newArray.length == initialLength) {
              _this.set('numDays', _this.get('numDays') * 2);
              resolve();
              return;
            }
            newArray.sort(function (a, b) {
              var aValue = new Date(a.get('posted_at')).getTime();
              var bValue = new Date(b.get('posted_at')).getTime();
              return bValue - aValue; // inverse order for history
            });
            _this.set('queued_items', newArray);
            resolve();
          }, reject);
        });
      }
    }
  });
});