define('es-frontend/routes/signin/signup', ['exports', 'es-frontend/utils/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    model: function model() {
      var _this = this;

      var qp = (0, _queryParams.default)();
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('ajaxService').ajax({
          userPath: false,
          url: 'users/user_lookup.json',
          data: {
            verification_code: qp.verification_code,
            user_id: qp.user_id
          }
        }).then(resolve, function (xhr, result, msg) {
          if (msg == 'Not Found') {
            xhr.responseJSON = {
              error: 'Could not find specified user'
            };
          }
          reject.apply(this, arguments);
        });
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      Ember.run.later(function () {
        if (_this2.get('router.url') == '/signin/signup') {
          if (model.signup_completed) {
            // probably not logged in here...
            var search = location.search;
            var start = search.indexOf('verification_code=');
            if (start >= 0) {
              //search.substring(0, index);
              var end = search.indexOf('&', start);
              if (end < 0) {
                end = search.length;
              }
              start--;
              search = search.substring(0, start) + search.substring(end);
            }
            location.replace(location.pathname + search + '#/signin/login');
            return;
          }
          switch (model.signup_step) {
            case 3:
            case 2:
              return _this2.transitionTo('signin.signup.user-tags');
            case 1:
              return _this2.transitionTo('signin.signup.user-details');
            default:
              return _this2.transitionTo('signin.signup.set-password');
          }
        }
      });
    }
  });
});