define('es-frontend/services/status-parser', ['exports', 'es-frontend/utils/is-valid-url'], function (exports, _isValidUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    embed: Ember.inject.service('embed'),

    parse: function parse(txt) {
      var _this = this;

      txt = txt ? txt.replace('Http://', 'http://').replace('Https://', 'https://') : txt;
      return new Ember.RSVP.Promise(function (resolve) {
        _this.get('embed').embed(txt).then(function (embedResult) {
          // We have a url or embed code!
          resolve({
            url: embedResult.url,
            embedded: !!embedResult.embedCode,
            message: ''
          });
        }, function () {
          var tokens = txt.split(/\s/);
          _this._checkTokens(tokens, txt).then(resolve, function () {
            //look for any token with an internal dot...
            for (var t = tokens.length; t-- > 0;) {
              var token = tokens[t];
              var index = token.indexOf('.');
              if (index > 0 && index + 1 < token.length) {
                tokens[t] = "http://" + token;
              } else {
                tokens.splice(t, 1);
              }
            }

            _this._checkTokens(tokens, txt).then(resolve, function () {
              //We have a status update -> no url.
              resolve({
                url: null,
                embedded: false,
                message: txt
              });
            });
          });
        });
      });
    },
    _checkTokens: function _checkTokens(tokens, txt) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var index = 0;
        var embed = _this2.get('embed');
        function iterate() {
          while (index < tokens.length) {
            var token = tokens[index++];
            if ((0, _isValidUrl.default)(token)) {
              embed.embed(token).then(function (embedResult) {
                //We have a status update -> no url.
                resolve({
                  url: embedResult.url,
                  embedded: !!embedResult.embedCode,
                  message: txt
                });
              }, iterate);
              return;
            }
          }
          reject();
        }
        iterate();
      });
    }
  });
});