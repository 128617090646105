define('es-frontend/components/es-tabs', ['exports', 'es-frontend/utils/ddau'], function (exports, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    numTabs: 0,
    selected: null,
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    tabClassNameBindings: ['selected', 'selected:bg1'],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    actions: {
      selectedChanged: _ddau.default.attr('selected')
    }
  });
});