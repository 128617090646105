define('es-frontend/components/es-tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['es-tab'],
    classNameBindings: ['selected', 'selected:bg1'],
    attributeBindings: ['tabIndex:data-tab-index', 'value:data-tab-value'],

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var tabs = this.get('tabs');
      if (tabs) {
        var tabIndex = tabs.incrementProperty('numTabs');
        this.set('tabIndex', tabIndex);
        if (!this.get('value')) {
          this.set('value', tabIndex);
        }
        Ember.run.later(function () {
          if (tabs.get('selected') == null) {
            tabs.actions.selectedChanged.call(tabs, _this.get('value'));
          }
        });
      }
    },


    selected: Ember.computed('tabs.selected', function () {
      return this.get('value') == this.get('tabs.selected');
    }),

    click: function click() {
      var tabs = this.get('tabs');
      if (tabs) {
        tabs.actions.selectedChanged.call(tabs, this.get('value'));
      }
    }
  });
});