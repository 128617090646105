define('es-frontend/routes/client/article', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    model: function model(params) {
      var clientModel = this.modelFor('client');
      return Ember.RSVP.hash({
        article: this.get('ajaxService').ajax({
          url: '/contents/' + params.id + '.json',
          method: 'GET'
        }),
        user: clientModel.get('user'),
        customer: clientModel.get('customer'),
        client: clientModel.get('client'),
        features: clientModel.get('features')
      });
    }
  });
});