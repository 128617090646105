define('es-frontend/routes/client/manage-streams', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    subscribableService: Ember.inject.service('subscribable'),

    model: function model() {
      var client = this.modelFor('client');
      return Ember.RSVP.hash({
        subscribables: client.get('customer.user_stream_management') ? this.get('subscribableService').findAll() : [],
        curated_topics: client.get('customer.show_curated_streams') ? this.get('store').findAll('curated-topic') : [],
        personal_streams: client.get('personal_streams'),
        client: client
      });
    }
  });
});