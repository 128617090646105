define('es-frontend/controllers/client/streams/stream', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    queryService: Ember.inject.service('query'),
    toast: Ember.inject.service('toast'),
    id: null,
    q: Ember.computed.alias('queryService.q'),
    page: null,
    per_page: 7,
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    articles: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    loading: false,
    done: false,

    load: function load() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this.get('ajaxService').ajax({
          url: '/contents.json',
          data: {
            cs: _this.id || 'all',
            type: _this.id && _this.id != 'all' ? 'recommended' : 'all',
            q: _this.get('q'),
            page: _this.page,
            per_page: _this.per_page
          }
        }).then(function (loadedArticles) {
          var articles = _this.articles.slice();
          articles.push.apply(articles, loadedArticles);
          var done = loadedArticles.length < _this.per_page;
          _this.setProperties({
            articles: articles,
            done: done
          });
          resolve(done);
        }, function (error) {
          if (error && error.status == 404) {
            _this.get('toast').error('Oops!!! Either this stream does not exist, or you don\'t have access to it. Please contact your administrator to request access.');
            return;
          }
          reject(error);
        });
      });
    },


    actions: {
      loadNext: function loadNext() {
        if (this.loading || this.done) {
          return false;
        }
        this.incrementProperty('page');
        return this.load();
      },
      initialLoad: function initialLoad(id, q, user, customer, client, features) {
        this.setProperties({
          id: id,
          q: q,
          user: user,
          customer: customer,
          client: client,
          features: features,
          page: 0,
          articles: [],
          loading: false,
          done: false
        });
        return this.load();
      },
      clearQuery: function clearQuery() {
        this.set('q', '');
      }
    }
  });
});