define('es-frontend/controllers/client/help', ['exports', 'es-frontend/utils/asset-url'], function (exports, _assetUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    //walkthroughModel: storageFor('es-walkthrough'),
    router: Ember.inject.service('router'),
    profileSrc: (0, _assetUrl.default)() + "/images/es-icon.png",

    actions: {
      //restartWalkthrough(){
      //this.set('walkthroughModel.visible', true);
      //this.get('router').transitionTo('client.streams');
      //}
    }
  });
});