define('es-frontend/models/queued-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    user_commentary: _emberData.default.attr('string'),
    image_url: _emberData.default.attr('string'),
    post_at: _emberData.default.attr('string'),
    posted_at: _emberData.default.attr('string'),
    engagements: _emberData.default.attr('number'),
    clicks: _emberData.default.attr('number'),
    shares: _emberData.default.attr('number'),
    error_message: _emberData.default.attr('string'),
    social_queue_id: _emberData.default.attr('number'),
    mutable: _emberData.default.attr('boolean')
  });
});