define('es-frontend/utils/ensure-visible', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = ensureVisible;


  function offsetY(child, parent) {
    var ret = child.position().top;
    var offsetParent = child.offsetParent();
    if (offsetParent[0] == parent[0]) {
      return ret + offsetParent.scrollTop();
    } else if (!Ember.$.contains(parent[0], offsetParent[0])) {
      return ret - parent.position().top + parent.scrollTop();
    } else {
      return ret + offsetY(offsetParent, parent);
    }
  }

  function ensureVisible(element, padding, time) {
    padding = padding || 20;
    time = time || 'fast';
    function process(current) {
      var parent = current.parent();
      while (parent.length && parent.prop('tagName') != 'BODY' && (parent.css('overflowY') == 'visible' || parent.css('overflowY') == 'hidden')) {
        parent = parent.parent();
      }
      if (!parent.length) {
        return;
      }
      var top = offsetY(current, parent);
      var isBody = parent[0] == document.body;
      var min = Math.max(top - padding, 0);
      var max = Math.min(top + current.outerHeight() + padding, parent[0].scrollHeight);
      var scrollMin = parent.scrollTop();
      var scrollMax = scrollMin + (isBody ? Ember.$(window) : parent).height();
      if (min < scrollMin) {
        (isBody ? Ember.$(document.scrollingElement) : parent).animate({ scrollTop: min }, time);
      } else if (max > scrollMax) {
        (isBody ? Ember.$(document.scrollingElement) : parent).animate({ scrollTop: max - (isBody ? Ember.$(window) : parent).height() }, time);
      }
      if (parent.prop('tagName') != 'BODY') {
        process(parent);
      }
    }
    process(Ember.$(element));
  }
});