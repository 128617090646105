define('es-frontend/controllers/client/article', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    store: Ember.inject.service('store'),
    widthService: Ember.inject.service('width'),

    actions: {
      loadSummary: function loadSummary() {
        var _this = this;

        return Ember.RSVP.hash({
          summary: this.get('store').query('leaderboard-summary', { user_client_id: this.get('model.client.id') }),
          social_queues: this.get('store').findAll('social-queue')
        }).then(function (hash) {
          var summary = hash.summary;
          var userSummary = summary.findBy('user_id', parseInt(_this.get('model.user.id')));
          _this.set('userSummary', userSummary);
          _this.set('summary', summary);
          _this.set('social_queues', hash.social_queues);
        });
      }
    }
  });
});