define('es-frontend/components/analytics-item', ['exports', 'moment', 'es-frontend/utils/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var VISIBLE_DATE = _constants.default.VISIBLE_DATE;
  exports.default = Ember.Component.extend({
    classNames: ['analytics-item', 'queued-item', 'col'],
    widthService: Ember.inject.service('width'),

    title: Ember.computed('activity.queued_post.{title,summary,user_commentary}', function () {
      //If there is no title, summary, user_commentary or url, then this must be a
      //photo share.
      var queued_post = this.get('activity.queued_post');
      var ret = queued_post.title || queued_post.summary || queued_post.user_commentary;
      if (!ret && (queued_post.image_post || queued_post.photo_post)) {
        ret = "Shared a photo on " + (0, _moment.default)(new Date(queued_post.updated_at)).format(VISIBLE_DATE);
      }
      if (!ret) {
        ret = queued_post.url;
      }
      return ret;
    }),

    posted_url: Ember.computed('activity.queued_post.posted_url', function () {
      var ret = this.get('activity.queued_post.posted_url');
      if (ret && ret.startsWith('www.linkedin.com')) {
        // linkedin hack
        ret = 'https://' + ret;
      }
      return ret;
    })
  });
});