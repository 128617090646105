define('es-frontend/adapters/terms', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    findRecord: function findRecord(store, type, id) {
      return this.ajax('/terms.json', 'GET', {
        data: { customer_id: id },
        userPath: false
      });
    }
  });
});