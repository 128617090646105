define('es-frontend/components/edit-profile', ['exports', 'es-frontend/utils/is-valid-email', 'es-frontend/utils/crop-img'], function (exports, _isValidEmail, _cropImg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IMG_SIZE = 256;

  exports.default = Ember.Component.extend({
    classNames: ['edit-profile', 'col', 'pad', 'bg1'],
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    subscribeTo: ['All Email', 'Nothing'],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    touched: false,

    selectedTimezone: Ember.computed('timezones', 'user.timezone', function () {
      var timezone = this.get('user.timezone');
      if (!timezone) {
        return null;
      }
      var ret = this.get('timezones').find(function (tz) {
        return tz.value == timezone;
      });
      if (!ret) {
        ret = {
          value: timezone,
          title: timezone
        };
      }
      return ret;
    }),

    nameValid: Ember.computed('user.name', function () {
      return !!this.get('user.name');
    }),

    positionValid: Ember.computed('user.position', function () {
      return !!this.get('user.position');
    }),

    emailValid: Ember.computed('user.email', function () {
      var email = this.get('user.email');
      return email && (0, _isValidEmail.default)(email);
    }),

    currentPasswordValid: Ember.computed('user.{changePassword,current_password}', function () {
      if (!this.get('user.changePassword')) {
        return true;
      }
      return !!this.get('user.current_password');
    }),

    newPasswordValid: Ember.computed('user.{changePassword,password,password_confirmation}', function () {
      if (!this.get('user.changePassword')) {
        return true;
      }
      var password = this.get('user.password');
      if (!password) {
        return false;
      }
      return password == this.get('user.password_confirmation');
    }),

    actions: {
      setTimezone: function setTimezone(timezone) {
        this.set('user.timezone', timezone.value);
      },
      newPasswordTouched: function newPasswordTouched() {
        this.set('newPasswordTouches', (this.get('newPasswordTouches') || 0) + 1);
      },
      doClose: function doClose() {
        this.get('doClose')();
      },
      updateImg: function updateImg(imgURL, img, left, top, width, height, scale) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          var dataURL = (0, _cropImg.default)(img, left, top, width, height, scale, IMG_SIZE, IMG_SIZE);
          _this.get('user').setProperties({
            profile_image_url: dataURL,
            profile_image: dataURL
          });

          resolve();
        });
      },
      doUpdate: function doUpdate() {
        if (this.get('nameValid') && this.get('positionValid') && this.get('emailValid') && this.get('currentPasswordValid') && this.get('newPasswordValid')) {
          this.get('doUpdate')(this.get('user'));
        } else {
          this.set('touched', true);
          this.get('toast').error('Could not save profile due to errors in form');
        }
      }
    }
  });
});