define('es-frontend/services/subscribable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {

    store: Ember.inject.service('store'),
    ajaxService: Ember.inject.service('es-ajax'),
    segment: Ember.inject.service('segment'),

    findAll: function findAll() {
      return this.get('store').findAll('subscribable');
    },
    subscribe: function subscribe(subscribable) {
      var _this = this;

      return this.toggleSubscription(subscribable).then(function () {
        _this.get('segment').track('subscribe', {
          content_stream_id: subscribable.id
        });
      });
    },
    unsubscribe: function unsubscribe(subscribable) {
      var _this2 = this;

      return this.toggleSubscription(subscribable).then(function () {
        _this2.get('segment').track('unsubscribe', {
          content_stream_id: subscribable.id
        });
      });
    },
    toggleSubscription: function toggleSubscription(subscribable) {
      var _this3 = this;

      //debugger;
      return this.get('ajaxService').ajax({
        method: 'PATCH',
        url: '/approved_streams/' + subscribable.id + '.json',
        data: {
          manage: subscribable.get('manage')
        }
      }).then(function (s) {

        subscribable.set('manage', s.manage);
        var result;
        if (s.manage == 'blocked') {
          _this3.get('store').unloadRecord(subscribable);
          result = subscribable;
        } else {
          result = _this3.get('store').push({
            data: [{
              id: subscribable.id,
              type: 'subscribable',
              attributes: subscribable.toJSON()
            }]
          })[0];
        }

        _this3.trigger('subscribableChange', result);
      });
    }
  });
});