define('es-frontend/routes/signin/index', ['exports', 'es-frontend/utils/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    //If nothing is specified, we replace current with client
    beforeModel: function beforeModel() {
      var params = (0, _queryParams.default)();
      this.replaceWith(params.verification_code ? 'signin.signup' : 'signin.login');
    }
  });
});