define('es-frontend/components/manage-curated-topic', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['curated-topic', 'row', 'txt7'],

    actions: {
      toggle: function toggle() {
        this.get('toggle')(this.get('curatedTopic'));
      }
    }
  });
});