define('es-frontend/components/help-faq', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['help-faq', 'row'],

    contentClass: Ember.computed('open', function () {
      return 'help-faq-content ' + (this.get('open') ? 'open' : 'closed');
    })
  });
});