define('es-frontend/routes/client/streams/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    //If nothing is specified, we replace current with frontend
    beforeModel: function beforeModel() {
      var _this = this;

      return this.get('store').findAll('subscription').then(function (subscriptions) {
        var content_stream_id = subscriptions.get('firstObject.content_stream_id');
        if (content_stream_id) {
          _this.replaceWith('client.streams.stream', content_stream_id);
        }
      });
    }
  });
});