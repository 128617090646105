define('es-frontend/components/menu-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['menu-item', 'col'],
    attributeBindings: ['title'],
    router: Ember.inject.service('router'),
    canToggle: true,
    activeClass: 'active txt2 bg1',
    size: 15,

    profileImgInitialsClass: Ember.computed('active', function () {
      return this.get('active') ? 'primary-inverse' : 'txt7-inverse';
    }),

    iconClass: Ember.computed('active', function () {
      return this.get('active') ? 'primary' : 'txt7';
    }),

    linkClass: Ember.computed('open', 'active', function () {
      var ret = ['row', 'item'];
      if (this.get('open')) {
        ret.push('open');
      }
      if (this.get('active')) {
        ret.push(this.get('activeClass'));
      } else {
        ret.push('txt4');
      }
      return ret.join(' ');
    }),

    linkHref: Ember.computed('href', 'route', 'models', 'queryParams', function () {
      var href = this.get('href');
      if (href) {
        return href;
      }
      var route = this.get('route');
      if (!route) {
        return null;
      }
      var args = [route];
      if (this.get('models')) {
        args.push(this.get('models'));
      }
      if (this.get('queryParams')) {
        args.push({ queryParams: this.get('queryParams') });
      }
      var _router = this.get('router._router');
      return _router.generate.apply(_router, args);
    }),

    click: function click(event) {
      if (event && event.target && event.target.tagName != "BUTTON") {
        Ember.$(window).scrollTop(0);
      }
      this.set('numAlerts', 0);
    },


    active: Ember.computed('router.currentURL', 'route', function () {
      if (!this.get('router.currentRouteName').startsWith(this.get('route'))) {
        return false;
      }
      var models = this.get('models');
      var currentUrl = this.get('router.currentURL').split('?')[0];
      return models ? currentUrl.endsWith(models) : true;
    }),

    activeObserver: Ember.observer('active', function () {
      var _this = this;

      if (this.get('active')) {
        Ember.run.later(function () {
          if (_this.isDestroying || _this.isDestroyed) {
            return;
          }
          _this.set('numAlerts', 0);
        }, 3000);
      }
    }),

    actions: {
      toggleOpen: function toggleOpen(hasBlock) {
        if (hasBlock && this.get('canToggle')) {
          this.toggleProperty('open');
        }
      }
    }
  });
});