define('es-frontend/components/img-select', ['exports', 'es-frontend/utils/absolute-position-of', 'es-frontend/utils/ddau', 'es-frontend/utils/read-img'], function (exports, _absolutePositionOf, _ddau, _readImg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var OFFSET = 0.2;

  exports.default = Ember.Component.extend({
    classNames: ['img-select', 'col'],
    imgs: null,
    width: 180,
    height: 180,
    allowUpload: true,
    _direction: null,
    aspectRatio: 0,
    adjustImg: null,

    init: function init() {
      this._super.apply(this, arguments);
      this._imgClicked = this._imgClicked.bind(this);
    },


    index: Ember.computed('imgs', 'img', function () {
      var imgs = this.get('imgs');
      var img = this.get('img');
      if (!(img && imgs)) {
        return null;
      }
      var ret = imgs.findIndex(function (_img) {
        return _img.src == img.src;
      });
      return ret;
    }),

    /* eslint-disable ember/no-on-calls-in-components */
    imgsObserver: Ember.on('init', Ember.observer('imgs', function () {
      var _this = this;

      Ember.run.later(function () {
        var index = _this.get('index');
        if (index == null) {
          _this.actions.imgChanged.call(_this, _this.get('imgs.firstObject'));
        }
      });
    })),
    /* eslint-enable ember/no-on-calls-in-components */

    indexTxt: Ember.computed('index', 'imgs', function () {
      var index = this.get('index');
      if (index == null) {
        return null;
      }
      return index + 1 + ' of ' + this.get('imgs.length');
    }),

    mainStyle: Ember.computed('width', 'height', function () {
      var width = this.get('width');
      var height = this.get('height');
      return 'width:' + width + 'px;height:' + height + 'px;';
    }),

    imgObserver: Ember.observer('img', function () {
      this._updateView();
    }),

    _updateView: function _updateView() {
      var $main = this.$('.img-select-main');
      if ($main) {

        var position = (0, _absolutePositionOf.default)($main);
        var direction = this.get('_direction');

        //set an outgoing from the existing (if present)
        var existing = $main.find('.img-select-img');
        if (existing.length) {
          this._destroyImg(existing, direction, position);
        }

        //Kill off any existing incoming
        var incoming = this.get('_incoming');
        if (incoming) {
          incoming.stop().remove();
        }

        //create a new incoming image
        var img = this.get('img');
        if (img) {
          this._createImg(img, direction, position, $main);
        }

        this.set('direction', null);
      }
    },
    _imgClicked: function _imgClicked() {
      this.setProperties({
        showAdjustImg: true,
        adjustImg: this.get('img')
      });
    },
    didInsertElement: function didInsertElement() {
      this._updateView();
    },
    _createImg: function _createImg(img, direction, position, $main) {
      var _this2 = this;

      var width = this.get('width');
      var height = this.get('height');

      var defineWidth = !img.width || width * img.height / img.width <= height;

      var $img = Ember.$('<img/>').attr('src', img.src).css(defineWidth ? 'width' : 'height', '100%').click(this._imgClicked);

      var initial = {
        position: 'fixed',
        opacity: 0
      };
      var final = {
        width: width,
        height: height,
        left: position.left,
        top: position.top,
        opacity: 1
      };
      if (direction) {
        initial.top = position.top - height * 0.1;
        initial.width = width * 1.2;
        initial.height = height * 1.2;
        if (direction == 'left') {
          initial.left = position.left - width * OFFSET;
        } else {
          initial.left = position.left + width * OFFSET;
        }
      } else {
        initial.top = position.top;
        initial.left = position.left;
        initial.width = width;
        initial.height = height;
      }

      var $container = Ember.$('<div/>').addClass('img-select-img').addClass(defineWidth ? 'col-center' : 'row-center').css(initial).append($img).appendTo(document.body);
      $container.animate(final, function () {
        $container.css({
          position: 'static',
          left: 0,
          top: 0
        }).detach().appendTo($main.empty());
        if (_this2.isDestroying || _this2.isDestroyed) {
          return;
        }
        _this2.set('incoming', null);
      });
      this.set('incoming', $container);

      return $container;
    },
    _destroyImg: function _destroyImg($img, direction, position) {
      $img.detach().css({
        position: 'fixed',
        left: position.left,
        top: position.top,
        opacity: 1 }).appendTo(document.body);
      var anim = {
        opacity: 0
      };
      if (direction) {
        anim.width = this.get('width') * 1.2;
        anim.height = this.get('height') * 1.2;
        anim.top = position.top - this.get('height') * 0.1;
        if (direction == 'left') {
          anim.left = position.left + this.get('width') * OFFSET;
        } else if (direction == 'right') {
          anim.left = position.left - this.get('width') * OFFSET;
        }
      }
      $img.animate(anim, function () {
        Ember.$(this).remove();
      });
    },


    actions: {
      imgChanged: _ddau.default.attr('img'),
      imgsChanged: _ddau.default.attr('imgs'),

      next: function next() {
        var index = this.get('index');
        if (index == null) {
          return;
        }
        index++;
        var imgs = this.get('imgs');
        if (index >= imgs.length) {
          index = 0;
        }
        this.set('_direction', 'right');
        this.actions.imgChanged.call(this, imgs[index]);
      },
      prev: function prev() {
        var index = this.get('index');
        if (index == null) {
          return;
        }
        index--;
        var imgs = this.get('imgs');
        if (index < 0) {
          index = imgs.length - 1;
        }
        this.set('_direction', 'left');
        this.actions.imgChanged.call(this, imgs[index]);
      },
      addImg: function addImg(event) {
        var _this3 = this;

        (0, _readImg.default)(event.currentTarget).then(function (img) {
          _this3.actions.addImgObj.call(_this3, img);
        }, function (msg) {
          throw msg;
        });
      },
      addImgObj: function addImgObj(img) {
        this.set('showAdjustImg', false);
        var imgs = (this.get('imgs') || []).slice();
        imgs.push(img);
        this.actions.imgsChanged.call(this, imgs);
        this.actions.imgChanged.call(this, img);
      }
    }
  });
});