define('es-frontend/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    profile_image_url: _emberData.default.attr('string'),
    profile_image: _emberData.default.attr('string'), // used for upload
    timezone: _emberData.default.attr('string'),
    recieve_emails: _emberData.default.attr('boolean'), // WARNING: Recieve is spelled wrong in user on server!!!
    customer_slug: _emberData.default.attr('string'),
    enterprise_admin: _emberData.default.attr('boolean'),
    enterprise_group_admin: _emberData.default.attr('boolean'),
    signup_completed: _emberData.default.attr('boolean'),
    main_contact: _emberData.default.attr('string'),
    position: _emberData.default.attr('string'),
    is_admin: Ember.computed.or('enterprise_admin', 'enterprise_group_admin'),
    current_password: _emberData.default.attr('string'), // used for upload
    password: _emberData.default.attr('string'), // used for upload
    password_confirmation: _emberData.default.attr('string'), // used for upload
    influencer: _emberData.default.attr('boolean')
  });
});