define('es-frontend/services/reaction', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    addReaction: function addReaction(reactable_type, reactable_id, reaction) {
      return this.get('ajaxService').ajax({
        url: '/reactions.json',
        method: 'POST',
        data: {
          reaction: {
            reactable_type: reactable_type,
            reactable_id: reactable_id,
            status: reaction
          }
        }
      });
    },

    removeReaction: function removeReaction(reactable_type, reactable_id, id) {
      return this.get('ajaxService').ajax({
        url: '/reactions/' + id + '.json',
        method: 'DELETE'
      });
    }
  });
});