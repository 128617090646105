define('es-frontend/components/leaderboard-summary-social', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    social_queue: Ember.computed('social_queues', 'service', function () {
      return (this.get('social_queues') || []).findBy('service', this.get('service'));
    })
  });
});