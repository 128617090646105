define('es-frontend/components/share-panel', ['exports', 'ember-local-storage', 'es-frontend/utils/get-error-msg', 'es-frontend/utils/strings', 'es-frontend/components/abstract-share', 'es-frontend/utils/twitter-text', 'moment', 'es-frontend/utils/constants'], function (exports, _emberLocalStorage, _getErrorMsg, _strings, _abstractShare, _twitterText, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SERVER_DATE = _constants.default.SERVER_DATE;
  exports.default = _abstractShare.default.extend({

    classNames: ['share-panel'],
    store: Ember.inject.service('store'),
    scraper: Ember.inject.service('scraper'),
    ajaxService: Ember.inject.service('es-ajax'),
    facebookService: Ember.inject.service('facebook'),
    xingService: Ember.inject.service('xing'),
    persistent: (0, _emberLocalStorage.storageFor)('share-panel'),
    segment: Ember.inject.service('segment'),
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    selectedSocialQueueMsgs: [],
    selectedSubscriptions: [],
    subscriptions: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    sharing: false,
    skipConnect: Ember.computed.alias('persistent.skipConnect'),
    mutable: true,
    sharedToEmail: false,

    /* eslint-disable ember/no-on-calls-in-components */
    urlObserver: Ember.on('init', Ember.observer('url', function () {
      var _this = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      // deal with the change
      var url = this.get('url');
      if (!url) {
        this.setProperties({
          subscriptions: [],
          facebook: false,
          xing: false,
          scraperError: false
        });
      }
      if (!url || this.get('defaultMeta')) {
        this.set('meta', this.get('defaultMeta'));
        return;
      }
      this.get('scraper').fetch(url).then(function (meta) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        Ember.set(meta, 'title', _strings.default.limit(meta.title, 255));
        Ember.set(meta, 'description', _strings.default.limit(meta.description, 255));
        _this.set('meta', meta);
        if (meta.description && url.startsWith('https://twitter.com/') && url.indexOf('/status/') > 0) {
          _this.set('twitterMsg', meta.description);
        }
      }, function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.setProperties({
          scraperError: true,
          meta: _this.get('defaultMeta')
        });
      });
    })),
    /* eslint-enable ember/no-on-calls-in-components */

    //share to social
    processSocials: function processSocials(promises) {
      var _this2 = this;

      this.get('selectedSocialQueueMsgs').forEach(function (socialQueueMsg) {
        var social_queue = socialQueueMsg.social_queue;
        var post = {
          url: _this2.get('url'),
          title: _this2.get('meta.title'),
          summary: _this2.get('meta.description'),
          curated_article_id: _this2.get('article.id')
        };
        var img = (_this2.get('img') || {}).src || null;
        if (social_queue.get('service') == 'twitter' && !_this2.get('features.twitter_img_enabled') || !(post.url || '').indexOf('https://share.vidyard.com/')) {
          img = null;
        }

        if (img && img.startsWith('data:')) {
          post.uploaded_image = img;
        } else {
          if (img) {
            img = img.replace('/large/', '/original/');
          }
          post.large_image_url = post.image_url = img;
        }
        post.is_status_update = post.url == null;
        post.user_commentary = socialQueueMsg.msg || _this2.get('meta.title');
        post.post_at = _this2.get('post_at');

        var create = _this2.get('ajaxService').ajax({
          url: '/v1/social_queues/' + social_queue.id + '/queued_posts.json',
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(post)
        });

        if (!(_this2.get('post_at') || _this2.get('addToQueue'))) {
          promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
            create.then(function (queued_post) {
              _this2.get('ajaxService').ajax({
                url: '/v1/social_queues/' + social_queue.id + '/queued_posts/' + queued_post.id + '/deliver.json',
                method: 'POST',
                contentType: 'application/json'
              }).then(resolve, function (result) {
                if (result) {
                  if (result && result.status == 422) {
                    result = 'You must reconnect your ' + _strings.default.capitalize(social_queue.get('service') || '') + ' account before posting content. (Posts to other selected accounts may have been successful)';
                    social_queue.set('invalid_token', true);
                  } else if (result.payload && result.payload.error_message) {
                    result = result.payload.error_message;
                  }
                }
                reject(result);
              });
            }, reject);
          }));
        } else {
          promises.push(create);
        }
      });
    },
    processSuggestions: function processSuggestions(promises) {
      var _this3 = this;

      var selectedSubscriptions = this.get('selectedSubscriptions');
      if (!selectedSubscriptions.length) {
        return;
      }

      var suggested_stream_ids = selectedSubscriptions.map(function (subscription) {
        return parseInt(subscription.get('content_stream_id'));
      });
      var data = {
        url: this.get('url'),
        title: this.get('meta.title'),
        summary: this.get('meta.description'),
        user_thoughts: this.get('suggestMsg'),
        suggested_stream_ids: suggested_stream_ids,
        enterprise_customer_id: this.get('customer.id')
      };

      var img = this.get('img');
      if (img) {
        if (img.src.startsWith('data:')) {
          data.image = img.src;
        } else {
          data.large_image_url = img.src;
        }
      }

      promises.push(this.get('ajaxService').ajax({
        url: '/suggested_articles.json',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data)
      }).then(function () {
        _this3.get('segment').track('user_submitted', {
          url: _this3.get('url'),
          suggested_stream_ids: suggested_stream_ids
        });
      }));
    },
    processFacebook: function processFacebook(promises) {
      var _this4 = this;

      if (!this.get('facebook')) {
        return;
      }
      promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
        if (!_this4.get('url')) {
          _this4.get('facebookService').share(null, ((_this4.get('facebookMsg') || '') + ' ' + (_this4.get('long_disclosure') || '')).trim()).then(function () {
            _this4.get('segment').track('Share', {
              user_email: _this4.get('user.email'),
              action: 'FacebookShare',
              url: null
            });
            resolve();
          }, reject);
          return;
        }
        var large_image_url = (_this4.get('img') || {}).src || null;
        if (large_image_url) {
          large_image_url = large_image_url.replace('/large/', '/original/');
        }
        _this4.get('ajaxService').ajax({
          method: 'POST',
          url: '/other_shares',
          contentType: 'application/json',
          data: JSON.stringify({
            image_url: large_image_url,
            large_image_url: large_image_url,
            post_at: (0, _moment.default)().add(1, 'hours').format(SERVER_DATE),
            url: _this4.get('url'),
            title: _this4.get('meta.title'),
            summary: _strings.default.limit(_this4.get('meta.description'), 255),
            network: 'facebook',
            curated_article_id: _this4.get('article.id')
          })
        }).then(function (results) {
          _this4.get('facebookService').share(results.url, ((_this4.get('facebookMsg') || '') + ' ' + (_this4.get('long_disclosure') || '')).trim()).then(function () {
            _this4.get('segment').track('Share', {
              user_email: _this4.get('user.email'),
              action: 'FacebookShare',
              url: _this4.get('url')
            });
            resolve();
          }, reject);
        }, reject);
      }));
    },
    processXing: function processXing(promises) {
      var _this5 = this;

      if (!this.get('xing')) {
        return;
      }
      promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
        var large_image_url = (_this5.get('img') || {}).src || null;
        if (large_image_url) {
          large_image_url = large_image_url.replace('/large/', '/original/');
        }
        _this5.get('ajaxService').ajax({
          method: 'POST',
          url: '/other_shares',
          contentType: 'application/json',
          data: JSON.stringify({
            image_url: large_image_url,
            large_image_url: large_image_url,
            post_at: (0, _moment.default)().add(1, 'hours').format(SERVER_DATE),
            url: _this5.get('url'),
            title: _this5.get('meta.title'),
            summary: _strings.default.limit(_this5.get('meta.description'), 255),
            network: 'xing',
            curated_article_id: _this5.get('article.id')
          })
        }).then(function (results) {
          _this5.get('xingService').share(results.url).then(function () {
            _this5.get('segment').track('Share', {
              user_email: _this5.get('user.email'),
              action: 'XingShare',
              url: _this5.get('url')
            });
            resolve();
          }, reject);
        }, reject);
      }));
    },


    hasOutput: Ember.computed.or('selectedSocialQueueMsgs.length', 'selectedSubscriptions.length', 'facebook', 'xing'),

    reconnections: Ember.computed('social_queues', function () {
      var ret = [];
      this.get('social_queues').forEach(function (queue) {
        if (queue.get('invalid_token') && queue.get('service') != 'xing') {
          ret.push({
            service: queue.get('service'),
            profile_name: queue.get('profile_name')
          });
        }
      });
      return ret;
    }),

    _socialQueuesLoaded: function _socialQueuesLoaded(social_queues) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.set('social_queues', social_queues);
    },
    _otherShare: function _otherShare(network) {
      var large_image_url = (this.get('img') || {}).src || null;
      return this.get('ajaxService').ajax({
        method: 'POST',
        url: '/other_shares',
        contentType: 'application/json',
        data: JSON.stringify({
          image_url: large_image_url,
          large_image_url: large_image_url,
          post_at: (0, _moment.default)().add(1, 'hours').format(SERVER_DATE),
          url: this.get('url'),
          title: this.get('meta.title'),
          summary: this.get('meta.description'),
          network: network,
          curated_article_id: this.get('article.id')
        })
      });
    },


    aspectRatio: Ember.computed('selectedSocialQueueMsgs', function () {
      var selectedSocialQueueMsgs = this.get('selectedSocialQueueMsgs');
      for (var s = 0; s < selectedSocialQueueMsgs.length; s++) {
        if (selectedSocialQueueMsgs[s].service == 'linkedin') {
          return 1.86335;
        }
      }
      return 0;
    }),

    actions: {
      loadSocialQueues: function loadSocialQueues() {
        var _this6 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          var social_queues = _this6.get('social_queues');
          if (social_queues) {
            _this6._socialQueuesLoaded(social_queues);
            resolve(social_queues);
            return;
          }
          return _this6.get('store').findAll('social_queue').then(function (social_queues) {
            _this6._socialQueuesLoaded(social_queues);
            resolve(social_queues);
          }, reject);
        });
      },
      share: function share() {
        var _this7 = this;

        //validate...
        if (!this.get('hasOutput')) {
          this.get('toast').error('Please select a network, additional option or stream.');
          return;
        }
        if (this.get('facebook')) {
          var fbMsgLength = this.get('facebookMsg.length') || 0;
          var longDisclosureLength = this.get('long_disclosure.length') || 0;
          var spacerLength = fbMsgLength && longDisclosureLength ? 1 : 0;
          if (fbMsgLength + longDisclosureLength + spacerLength > 1300) {
            this.get('toast').error('Facebook message is too long');
            return;
          }
        }
        if (this.get('xing')) {
          var xingMsgLength = this.get('xingMsg.length') || 0;
          var _longDisclosureLength = this.get('long_disclosure.length') || 0;
          var _spacerLength = xingMsgLength && _longDisclosureLength ? 1 : 0;
          if (xingMsgLength + _longDisclosureLength + _spacerLength > 1300) {
            this.get('toast').error('Xing message is too long');
            return;
          }
        }
        if (this.get('selectedSubscriptions.length') && this.get('suggestMsg.length') > 255) {
          this.get('toast').error('Message for suggested content is too long');
          return;
        }
        var selectedSocialQueueMsgs = this.get('selectedSocialQueueMsgs');
        for (var s = 0; s < selectedSocialQueueMsgs.length; s++) {
          if (selectedSocialQueueMsgs[s].social_queue.get('invalid_token')) {
            this.get('toast').error('You must reconnect your ' + _strings.default.capitalize(selectedSocialQueueMsgs[s].service) + ' account before posting content.');
            this.get('store').findAll('social-queue', { reload: true }).then(function (social_queues) {
              _this7.set('social_queues', social_queues.toArray());
            });
            return;
          }
          if (selectedSocialQueueMsgs[s].service == 'twitter') {
            if (_twitterText.default.getTweetLength(selectedSocialQueueMsgs[s].msg || '') > selectedSocialQueueMsgs[s].maxLength) {
              this.get('toast').error('Message for ' + selectedSocialQueueMsgs[s].social_queue.get('profile_name') + ' is too long');
              return;
            }
          } else if ((selectedSocialQueueMsgs[s].msg || '').length > 1300) {
            this.get('toast').error('Message for ' + selectedSocialQueueMsgs[s].social_queue.get('profile_name') + ' is too long');
            return;
          }
          if (!(selectedSocialQueueMsgs[s].msg || this.get('meta.title'))) {
            this.get('toast').error('Message for ' + selectedSocialQueueMsgs[s].social_queue.get('profile_name') + ' cannot be blank');
            return;
          }
        }
        var expiration_date = this.get('article.expiration_date');
        var post_at = this.get('post_at');
        if (expiration_date && post_at && (0, _moment.default)(expiration_date).isBefore((0, _moment.default)(post_at))) {
          this.get('toast').error('The scheduled time is after the time at which the associated article is set to expire - Perhaps you would like to share now instead?');
          return;
        }

        var promises = [];

        //share to social
        this.processSocials(promises);

        //Share to streams
        this.processSuggestions(promises);

        //share to additionals
        this.processFacebook(promises);

        //share to xing
        this.processXing(promises);

        this.set('sharing', true);
        var beforeShare = this.get('beforeShare');
        if (beforeShare) {
          beforeShare();
        }

        Ember.RSVP.all(promises).then(function () {
          if (_this7.get('post_at')) {
            _this7.get('toast').info('Content Scheduled');
          } else if (_this7.get('addToQueue')) {
            _this7.get('toast').info('Content Queued');
          } else {
            _this7.get('toast').info('Content Shared');
          }

          _this7.get('segment').track('share_success', {
            article_id: _this7.get('article.id'),
            scheduled: !!_this7.get('post_at'),
            queued: !!_this7.get('addToQueue'),
            now: !(_this7.get('post_at') || _this7.get('addToQueue')),
            email: _this7.get('sharedToEmail'),
            facebook: !!_this7.get('facebook'),
            linkedin: !!_this7.get('selectedSocialQueueMsgs').find(function (sqm) {
              return sqm.social_queue.service == 'linkedin';
            }),
            twitter: !!_this7.get('selectedSocialQueueMsgs').find(function (sqm) {
              return sqm.social_queue.service == 'twitter';
            }),
            share_copy_edited: !!_this7.get('selectedSocialQueueMsgs').find(function (sqm) {
              return sqm.msg !== _this7.get('meta.title');
            }),
            share_copy_hashtag: !!_this7.get('selectedSocialQueueMsgs').find(function (sqm) {
              return (sqm.msg || '').indexOf('#') >= 0;
            }),
            share_copy_handle: !!_this7.get('selectedSocialQueueMsgs').find(function (sqm) {
              return (sqm.msg || '').indexOf('@') >= 0;
            })
          });

          var onShare = _this7.get('onShare');
          if (_this7.isDestroying || _this7.isDestroyed) {
            if (onShare) {
              onShare();
            }
            return;
          }
          _this7.setProperties({
            url: null,
            meta: null,
            message: null,
            sharing: false
          });
          if (onShare) {
            onShare();
          }
        }, function (error) {
          _this7.get('toast').error((0, _getErrorMsg.default)(error) || 'Unknown Error');
          if (!(_this7.isDestroying || _this7.isDestroyed)) {
            _this7.set('sharing', false);
          }
        });
      },
      callback: function callback() {
        return encodeURIComponent('?hash=' + encodeURIComponent('/client/share?url=' + encodeURIComponent(this.get('url'))));
      },
      shareToEmail: function shareToEmail() {
        var _this8 = this;

        this._otherShare('email').then(function (post) {
          var link = ['mailto:?subject=', encodeURIComponent(_this8.get('meta.title') || 'Something you might be interested in...'), '&body='];
          if (_this8.get('meta.description')) {
            link.push(encodeURIComponent(_this8.get('meta.description')), encodeURIComponent('...'), '%0D%0A%0D%0A');
          }
          if (_this8.get('url')) {
            link.push(encodeURIComponent(post.url));
          }
          if (_this8.get('client.salesforce_bcc_email')) {
            link.push('&bcc=', encodeURIComponent(_this8.get('client.salesforce_bcc_email')));
          }
          window.location.href = link.join('');
          _this8.get('segment').track('email_share', {
            url: post.url
          });
          _this8.set('sharedToEmail', true);
        });
      },
      setPostAt: function setPostAt(post_at) {
        this.setProperties({
          post_at: post_at,
          dateTimePickerOpen: false
        });
      },
      openDateTimePicker: function openDateTimePicker() {
        if (this.get('selectedSocialQueueMsgs.length')) {
          this.toggleProperty('dateTimePickerOpen');
        }
      },
      setAddToQueue: function setAddToQueue() {
        if (this.get('selectedSocialQueueMsgs.length')) {
          this.toggleProperty('addToQueue');
        }
      }
    }

  });
});