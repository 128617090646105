define('es-frontend/components/share-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    mutable: true,
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    subscriptions: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    segment: Ember.inject.service('segment'),

    /* eslint-disable ember/no-on-calls-in-components */
    urlObserver: Ember.on('init', Ember.observer('message', function () {
      var message = this.get('message');
      if (!message) {
        return;
      }
      this.setProperties({
        twitterMsg: message,
        facebookMsg: message,
        linkedinMsg: message,
        xingMsg: message,
        suggestMsg: message
      });
    })),
    /* eslint-enable ember/no-on-calls-in-components */

    beforeShare: function beforeShare() {
      this.toggleProperty('open');
    },


    openObserver: Ember.observer('open', function () {
      var _this = this;

      if (!this.get('open')) {
        return;
      }
      Ember.run.later(function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        /* eslint-disable ember/jquery-ember-run */
        Ember.$('.underlay').click(function () {
          _this.set('open', false);
        });
        /* eslint-enable ember/jquery-ember-run */
      });
    }),

    actions: {
      beforeShare: function beforeShare() {
        this.get('beforeShare').call(this);
      },
      close: function close() {
        this.set("open", false);
        this.get('segment').track('cancel_share');
      },
      onShare: function onShare() {
        if (this.get('onShare')) {
          this.get('onShare')();
        }
      }
    }
  });
});