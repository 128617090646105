define('es-frontend/utils/my-reactions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = myReactions;
  function myReactions(attr) {
    return Ember.computed(attr, function () {
      var reactions = this.get(attr);
      var ret = [];
      if (!reactions) {
        return ret;
      }
      var user = this.get('user');
      for (var r = 0; r < reactions.length; r++) {
        var reaction = reactions[r];
        if (reaction.user_id == user.id) {
          ret.push(reaction);
        }
      }
      return ret;
    });
  }
});