define('es-frontend/components/vidyard-popup', ['exports', 'es-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VIDYARD_SRC = "https://app.vidyard.com/v1/embed.js"; /* eslint-disable no-console */
  /* eslint-disable no-undef */
  exports.default = Ember.Component.extend({

    _openObserver: Ember.observer('open', function () {
      if (this.get('open')) {
        var script = document.querySelector('script[src="' + VIDYARD_SRC + '"]');
        if (script) {
          Ember.run.later(this._onOpen.bind(this), 400);
        } else {
          console.log('Loading Vidyard');
          script = document.createElement('script');
          script.async = true;
          script.src = VIDYARD_SRC;
          script.addEventListener('load', this._onOpen.bind(this));
          document.querySelector('head').appendChild(script);
        }
      }
    }),

    _onOpen: function _onOpen() {
      console.log('Opened');
      var library = Vidyard.goVideo.createLibrary(document.querySelector('.vidyard-library'), {
        clientId: _environment.default.vidyard.clientId //,
        //recipientEmail: user.email
      });
      library.on('player:created', this._onSelectVideo.bind(this));
      this.set('library', library);
    },
    _onSelectVideo: function _onSelectVideo(response) {
      var imgs = [];
      var _this = this;

      function setProps() {
        this.setProperties({
          sharePopupOpen: true,
          url: response.embed_codes.sharing_page,
          message: response.player.viral_sharing_message,
          defaultMeta: {
            title: response.player.name,
            description: response.player.description,
            imgs: imgs
          }
        });
      }

      if (response.embed_codes.thumbnail) {
        var thumbnail = new Image();
        thumbnail.onload = function () {
          imgs.push({
            src: thumbnail.src,
            width: thumbnail.naturalWidth,
            height: thumbnail.naturalHeight
          });
          setProps.call(_this);
        };
        thumbnail.onerror = function () {
          setProps.call(_this);
        };
        thumbnail.src = 'https:' + response.embed_codes.thumbnail.split('?')[0];
      } else {
        setProps.call(_this);
      }
    },


    actions: {
      onShare: function onShare() {
        this.set('open', false);
      }
    }
  });
});