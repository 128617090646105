define('es-frontend/controllers/client/manage-streams', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    subscribableService: Ember.inject.service('subscribable'),
    ajaxService: Ember.inject.service('es-ajax'),
    toast: Ember.inject.service('toast'),
    segment: Ember.inject.service('segment'),

    state: 'initial',
    personal_streams: null,
    personal_streams_loaded: Ember.computed('personal_streams', function () {
      return !!this.get('personal_streams');
    }),

    loadSources: function loadSources(personal_stream) {
      return this.get('ajaxService').ajax({
        url: '/sources.json',
        method: 'GET',
        data: {
          cs: personal_stream.id
        }
      }).then(function (sources) {
        Ember.set(personal_stream, 'sources', sources);
      });
    },


    showAllCuratedTopics: false,

    pagedCuratedTopics: Ember.computed('model.curated_topics', 'showAllCuratedTopics', function () {
      var topics = this.get('model.curated_topics');
      return !topics || this.get('showAllCuratedTopics') ? topics : topics.slice(0, 10);
    }),

    personal_stream_to_segment: function personal_stream_to_segment(stream) {
      var includes_twitter_handle = !!stream.sources.find(function (source) {
        return source.feed_url.startsWith('@');
      });
      var includes_twitter_hashtag = !!stream.sources.find(function (source) {
        return source.feed_url.startsWith('#');
      });
      var includes_rss_feed = !!stream.sources.find(function (source) {
        return source.feed_url.startsWith('http');
      });
      var inclues_keyword = !stream.sources.find(function (source) {
        return source.feed_url.startsWith('@') || source.feed_url.startsWith('#') || source.feed_url.startsWith('http');
      });
      return {
        personal_stream_id: stream.id,
        includes_twitter_handle: includes_twitter_handle,
        includes_twitter_hashtag: includes_twitter_hashtag,
        includes_rss_feed: includes_rss_feed,
        inclues_keyword: inclues_keyword
      };
    },


    actions: {
      subscribeSubscribable: function subscribeSubscribable(subscribable) {
        return this.get('subscribableService').subscribe(subscribable);
      },
      unsubscribeSubscribable: function unsubscribeSubscribable(subscribable) {
        return this.get('subscribableService').unsubscribe(subscribable);
      },
      loadPersonal: function loadPersonal() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {

          if (_this.get('state') == 'initial') {
            _this.set('state', 'loadPersonal');
            resolve();
            return;
          }

          var personal_streams = _this.get('model.personal_streams');
          var promises = [];

          personal_streams.forEach(function (personal_stream) {
            promises.push(_this.loadSources(personal_stream));
          });

          Ember.RSVP.all(promises).then(function () {
            _this.set('personal_streams', personal_streams);
            resolve();
          }, reject);
        });
      },
      toggleCuratedTopic: function toggleCuratedTopic(curatedTopic) {
        var _this2 = this;

        curatedTopic.toggleProperty('subscribed');
        curatedTopic.save().then(function () {
          _this2.set('model.client.curated_topics', _this2.get('model.curated_topics').filterBy('subscribed', true));
        });
      },
      createPersonalStream: function createPersonalStream() {
        var _this3 = this;

        return this.get('ajaxService').ajax({
          url: '/content_streams.json',
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            name: 'New Stream'
          })
        }).then(function (content_stream) {
          content_stream.sources = [];
          var streams = (_this3.personal_streams || []).slice();
          streams.push(content_stream);
          _this3.set('personal_streams', streams);
          _this3.set('model.client.personal_streams', streams);
          _this3.get('segment').track('delete_personal_stream', _this3.personal_stream_to_segment(content_stream));
        });
      },
      updatePersonalStream: function updatePersonalStream(personal_stream) {
        var _this4 = this;

        return this.get('ajaxService').ajax({
          url: '/content_streams/' + personal_stream.id + '.json',
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify(personal_stream)
        }).then(function () {
          var streams = _this4.get('personal_streams').slice();
          var index = streams.findIndex(function (stream) {
            return stream.id == personal_stream.id;
          });
          streams[index] = personal_stream;
          _this4.set('personal_streams', streams);
          _this4.set('model.client.personal_streams', streams);
          _this4.get('segment').track('update_personal_stream', _this4.personal_stream_to_segment(personal_stream));
        });
      },
      deletePersonalStream: function deletePersonalStream(personal_stream) {
        var _this5 = this;

        return this.get('ajaxService').ajax({
          url: '/content_streams/' + personal_stream.id + '.json',
          method: 'DELETE'
        }).then(function () {
          var personal_streams = _this5.get('personal_streams').slice();
          personal_streams.splice(personal_streams.indexOf(personal_stream), 1);
          _this5.set('personal_streams', personal_streams);
          _this5.set('model.client.personal_streams', personal_streams);
          _this5.get('segment').track('delete_personal_stream', _this5.personal_stream_to_segment(personal_stream));
        });
      },
      addSource: function addSource(personal_stream, url) {
        var _this6 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          var rss = url[0] !== '#' && url[0] !== '@' && url.substring(0, 4) === 'http';
          var toast = _this6.get('toast');
          _this6.get('ajaxService').ajax({
            url: '/sources.json',
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify({
              cs: personal_stream.id,
              rss: rss,
              source_feed_url: url
            })
          }).then(function () {
            _this6.loadSources(personal_stream).then(function () {
              _this6.set('personal_streams', _this6.get('personal_streams').slice());
              _this6.get('segment').track('add_source', _this6.personal_stream_to_segment(personal_stream));
              resolve(personal_stream);
            }, reject);
          }, function (rs) {
            if (rs.status == 400) {
              toast.error('Could not load data from the specified Address!');
            } else {
              reject.apply(this, arguments);
            }
          });
        });
      },
      inserted: function inserted() {
        this.actions.loadPersonal.call(this);
        this.actions.loadPersonal.call(this);
      }
    }
  });
});