define("es-frontend/utils/no-nulls", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = noNulls;
  function noNulls(obj) {
    Object.keys(obj).forEach(function (key) {
      return obj[key] == null && delete obj[key];
    });
    return obj;
  }
});