define('es-frontend/components/edit-comment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['edit-comment', 'col'],
    service: Ember.inject.service('edit-comment'),
    active: false,
    submitText: 'Comment',
    placeholder: 'Add Comment',
    commentService: Ember.inject.service('comment'),

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('active')) {
        this.set('service.activeId', this.elementId);
      }
    },


    //Exists to be passed to taggable-area (This will refer to that component!)
    suggestionKey: function suggestionKey(suggestion) {
      return toKey(suggestion.value);
    },
    suggestionValue: function suggestionValue(suggestion) {
      return '@[' + suggestion.value + '](' + suggestion.uid + '):';
    },


    parsedValue: Ember.computed('value', function () {
      var value = this.get('value');
      if (!value) {
        return '';
      }
      var parsedValue = [];
      var index = 0;
      /* eslint-disable no-constant-condition */
      while (true) {
        var nameStart = value.indexOf('@[', index);
        var nameEnd = value.indexOf('](', nameStart);
        var idEnd = value.indexOf(')', nameEnd);
        if (nameStart < 0 || nameEnd < 0 || idEnd < 0) {
          parsedValue.push(value.substring(index, value.length));
          return parsedValue.join('');
        }
        parsedValue.push(value.substring(index, nameStart), toKey(value.substring(nameStart + 2, nameEnd).trim()));
        index = idEnd + 1;
        if (value.charAt(index) == ':') {
          index++;
        }
      }
      /* eslint-enable no-constant-condition */
    }),

    parsedKeys: Ember.computed('value', function () {
      var value = this.get('value');
      var keys = {};
      if (!value) {
        var prevParsedKeys = this.get('_parsedKeys');
        if (JSON.stringify(keys) == JSON.stringify(prevParsedKeys)) {
          // hack for object compare
          return prevParsedKeys;
        }
        this.set('_parsedKeys', keys);
        return keys;
      }
      var index = 0;
      /* eslint-disable no-constant-condition */
      while (true) {
        var nameStart = value.indexOf('@[', index);
        var nameEnd = value.indexOf('](', nameStart);
        var idEnd = value.indexOf(')', nameEnd);
        if (nameStart < 0 || nameEnd < 0 || idEnd < 0) {
          var _prevParsedKeys = this.get('_parsedKeys');
          if (JSON.stringify(keys) == JSON.stringify(_prevParsedKeys)) {
            // hack for object compare
            return _prevParsedKeys;
          }
          this.set('_parsedKeys', keys);
          return keys;
        }
        if (value.charAt(idEnd + 1) == ':') {
          idEnd++;
        }
        var key = toKey(value.substring(nameStart + 2, nameEnd).trim());
        keys[key] = value.substring(nameStart, idEnd + 1);
        index = idEnd + 1;
      }
      /* eslint-enable no-constant-condition */
    }),

    /* eslint-disable ember/no-on-calls-in-components */
    activeObserver: Ember.on('init', Ember.observer('active', function () {
      var _this = this;

      Ember.run.later(function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        if (_this.get('active')) {
          _this.set('service.activeId', _this.elementId);
        }
      });
    })),
    /* eslint-enable ember/no-on-calls-in-components */

    activeIdObserver: Ember.observer('service.activeId', function () {
      var _this2 = this;

      Ember.run.later(function () {
        if (_this2.isDestroying || _this2.isDestroyed) {
          return;
        }
        if (_this2.get('active') && _this2.get('service.activeId') != _this2.elementId) {
          _this2.actions.cancel.call(_this2);
        }
      });
    }),

    actions: {
      cancel: function cancel() {
        this.setProperties({
          active: false,
          value: null
        });
      },
      postComment: function postComment() {
        var value = this.get('value');
        if (value) {
          this.get('postComment')(value, this.get('parent_id'));
          this.actions.cancel.apply(this);
        }
      },
      search: function search(query) {
        return this.get('commentService').tagSearch(query);
      },
      stateChanged: function stateChanged(parsedKeys, parsedValue) {
        var _this3 = this;

        Ember.run.later(function () {
          var value = [];
          var index = 0;
          /* eslint-disable no-constant-condition */
          while (true) {
            var start = parsedValue.indexOf('@', index);
            if (start < 0) {
              value.push(parsedValue.substring(index, parsedValue.length));
              _this3.set('value', value.join(''));
              return;
            }
            var end = parsedValue.substring(start).search(/\s/);
            end = end < 0 ? parsedValue.length : end + start;
            var key = parsedValue.substring(start, end);
            value.push(parsedValue.substring(index, start), parsedKeys[key] || key);
            index = end;
          }
          /* eslint-enable no-constant-condition */
        });
      }
    }
  });


  function toKey(str) {
    return '@' + str.replace(/\s/g, '_');
  }
});