define('es-frontend/adapters/leaderboard-summary', ['exports', 'es-frontend/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    esAjax: Ember.inject.service('es-ajax'),

    query: function query(store, modelClass, _query) {
      return this.get('esAjax').ajax({
        url: '/leaderboard_v2/summary.json',
        data: _query
      });
    }
  });
});