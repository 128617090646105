define('es-frontend/routes/signin/signup/user-tags', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('ajaxService').ajax({
          userPath: false,
          url: 'users/user_tags.json',
          data: {
            customer_id: _this.modelFor('signin.signup').customer_id
          }
        }).then(function (categories) {
          var categoryObjects = [];
          categories.forEach(function (category) {
            category.tag_values = category.tag_values.sortBy('name');
            categoryObjects.push(Ember.Object.create(category));
          });
          resolve({
            customer: _this.modelFor('signin'),
            user: _this.modelFor('signin.signup'),
            categories: categoryObjects
          });
        }, reject);
      });
    },
    afterModel: function afterModel(model) {
      var _this2 = this;

      Ember.run.later(function () {
        if (!model.categories.length) {
          _this2.controllerFor('signin.signup.user-tags').send('saveTags');
        }
      });
    }
  });
});