define('es-frontend/components/linkedin-pages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['linkedin-pages'],
    ajaxService: Ember.inject.service('es-ajax'),

    actions: {
      load: function load() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          _this.get('ajaxService').ajax({
            url: '/oauth_access_tokens/' + _this.get('pagesSocialQueue.oauth_access_token_id') + '/refresh'
          }).then(function (pages) {
            _this.set('pages', pages);
            resolve(true);
          }, function (result) {
            if (result && result.payload && result.payload.error) {
              result = result.payload.error;
              _this.set('pagesSocialQueue.invalid_token', true);
            }
            reject(result);
          });
        });
      },
      connect: function connect(page) {
        var _this2 = this;

        this.get('ajaxService').ajax({
          url: '/oauth_access_tokens/' + page.oauth_access_token_id + '/page_and_groups/' + page.id + '/connect',
          method: 'POST',
          data: page
        }).then(function () {
          _this2.get('onConnect')();
        });
      }
    }
  });
});