define('es-frontend/helpers/ago', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ago = ago;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function ago(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        date = _ref2[0];

    if (!date) {
      return '';
    }
    if (typeof date == 'string') {
      date = new Date(date);
    }
    date = (0, _moment.default)(date);

    var now = (0, _moment.default)();
    var end = (0, _moment.default)().add(1, 'days').toArray();
    end = (0, _moment.default)([end[0], end[1], end[2]]);

    var daysDiff = end.diff(date, 'days', true);
    if (daysDiff >= 7 || daysDiff <= -7) {
      if (date.get('year') == now.get('year')) {
        return date.format('MMM Do');
      } else {
        return date.format('MMM Do YYYY');
      }
    } else if (daysDiff >= 2) {
      return Math.floor(daysDiff) + ' days ago';
    } else if (daysDiff >= 1) {
      return 'Yesterday at ' + date.format('h:mm A');
    } else if (daysDiff <= -1) {
      return Math.floor(Math.abs(daysDiff) + 1) + ' days from now';
    } else if (daysDiff < 0) {
      return 'Tomorrow at ' + date.format('h:mm A');
    } else {
      return 'Today at ' + date.format('h:mm A');
    }
  }

  exports.default = Ember.Helper.helper(ago);
});