define('es-frontend/storages/share-select', ['exports', 'ember-local-storage/local/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _object.default.extend();

  Storage.reopenClass({
    initialState: function initialState() {
      return {
        facebook: false,
        xing: false,
        email: false,
        selectedSocialQueueIds: [],
        selectedSubscriptionIds: []
      };
    }
  });

  exports.default = Storage;
});