define('es-frontend/components/es-checkbox', ['exports', 'es-frontend/utils/ddau'], function (exports, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['es-checkbox'],
    classNameBindings: ['checked', 'outlineClassNames'],
    attributeBindings: ['style', 'type'],
    outlineClassNames: 'txt6',
    iconClassNames: 'primary',
    size: null,
    checked: false,
    type: 'button',

    style: Ember.computed('size', function () {
      var size = this.get('size');
      return size ? 'width:' + size + 'px;height:' + size + 'px;font-size:' + size + 'px;' : '';
    }),

    click: function click() {
      this.actions.checkedChanged.call(this, !this.get('checked'));
    },


    actions: {
      checkedChanged: _ddau.default.attr('checked')
    }
  });
});