define('es-frontend/components/es-select', ['exports', 'es-frontend/utils/absolute-position-of', 'es-frontend/utils/ddau'], function (exports, _absolutePositionOf, _ddau) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['es-select', 'col'],
    classNameBindings: ['open'],
    placeholderClass: 'placeholder txt7',
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    options: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    optionClass: 'option fill faded-inverse-focus',
    value: null,
    placeholder: null,
    popupClass: 'es-select-options popup bg1',
    rightBtnClass: 'right-btn col-center txt7',
    canDelete: true,
    modal: false,
    icon: 'arrow_down',

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this._onFocus = this._onFocus.bind(this);
      this._windowClick = this._windowClick.bind(this);
      Ember.run.later(function () {
        if (_this.get('selected') && _this.get('value')) {
          _this.get('selected')(_this.get('value'));
        }
      });
    },


    selectedButtonClass: Ember.computed('value', function () {
      var ret = ['selected-btn option fill'];
      if (this.get('value') == null) {
        ret.push(this.get('placeholderClass'));
      }
      return ret.join(' ');
    }),

    valueOrPlaceholder: Ember.computed('value', function () {
      var value = this.get('value');
      return value == null ? this.get('placeholder') : value;
    }),

    didInsertElement: function didInsertElement() {
      this.$('.selected-btn,.right-btn').on('focus', this._onFocus);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('click', this._windowClick);
      this._onFocus = this._windowClick = null;
    },
    _onFocus: function _onFocus() {
      var _this2 = this;

      if (this.get('open')) {
        return;
      }
      Ember.run.later(function () {
        Ember.$(window).on('click', _this2._windowClick);
        _this2.actions.openChanged.call(_this2, true);
      }, 100);
    },
    _windowClick: function _windowClick(event) {
      var $targets = Ember.$(event.target).parents().add(event.target);
      if ($targets.is(this.$()) || $targets.is(Ember.$('#' + this.elementId + 'Popup'))) {
        return;
      }
      Ember.$(window).off('click', this._windowClick);
      this.actions.openChanged.call(this, false);
    },
    _layoutPopup: function _layoutPopup(popupElement) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var anchor = this.$();
      var position = (0, _absolutePositionOf.default)(anchor);
      popupElement.css({
        left: position.left,
        top: position.top + anchor.outerHeight(),
        width: anchor.outerWidth()
      });
    },


    actions: {
      openChanged: _ddau.default.attr('open'),
      valueChanged: _ddau.default.attr('value'),
      openSelection: function openSelection() {
        this.actions.openChanged.call(this, true);
      },
      clearSelection: function clearSelection() {
        if (!this.get('open')) {
          return this._onFocus();
        }
        if (this.get('canDelete')) {
          var value = this.get('value');
          this.actions.valueChanged.call(this, null);
          if (value && this.get('deselected')) {
            this.get('deselected')(value);
          }
        }
        Ember.$(window).off('click', this._windowClick);
        this.actions.openChanged.call(this, false);
      },
      setSelection: function setSelection(option) {
        var oldValue = this.get('value');
        this.actions.valueChanged.call(this, option);
        if (oldValue && this.get('deselected')) {
          this.get('deselected')(oldValue);
        }
        if (option && this.get('selected')) {
          this.get('selected')(option);
        }
        this.actions.openChanged.call(this, false);
      },
      _layoutPopup: function _layoutPopup(popupElement) {
        this._layoutPopup(popupElement);
      }
    }
  });
});