define('es-frontend/components/reactions-breakdown-dialog', ['exports', 'es-frontend/utils/reaction-types'], function (exports, _reactionTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var types = ['share', 'comment'];
  types.push.apply(types, _reactionTypes.default);

  exports.default = Ember.Component.extend({
    selectedTab: 'all',
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    tabsClassNames: ['es-tabs', 'row', 'panel'],
    tabClassNameBindings: ['selected', 'selected:bg1:bg3'],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */

    reaction_summary: Ember.computed('reactions', 'shared_by', 'comments', 'comments_count', function () {
      var ret = {
        share: this.get('shared_by.length') || 0,
        comment: this.get('comments_count')
      };
      this.get('reactions').forEach(function (reaction) {
        var status = reaction.status;
        ret[status] = (ret[status] || 0) + 1;
      });
      return ret;
    }),

    summaryTypes: Ember.computed('reaction_summary', function () {
      var reaction_summary = this.get('reaction_summary');
      var ret = [];
      (this.get('shared_by') ? types : _reactionTypes.default).forEach(function (reactionType) {
        ret.push({
          reaction: reactionType,
          count: reaction_summary[reactionType] || 0
        });
      });
      return ret;
    }),

    userReactions: Ember.computed('shared_by', 'reactions', function () {
      var ret = [];
      var byUserId = {};
      var shared_by = this.get('shared_by');
      if (shared_by) {
        shared_by.forEach(function (share) {
          var user = {
            user_id: share.id,
            user_name: share.name,
            profile_image_url: share.profile_pic_url,
            reactions: ['share']
          };
          ret.push(user);
          byUserId[user.user_id] = user;
        });
      }
      this._addComments(this.get('comments') || [], ret, byUserId);
      this.get('reactions').forEach(function (reaction) {
        var user = byUserId[reaction.user_id];
        if (!user) {
          user = {
            user_id: reaction.user_id,
            user_name: reaction.user_name,
            profile_image_url: reaction.user_profile_image_url,
            reactions: []
          };
          ret.push(user);
          byUserId[user.user_id] = user;
        }
        user.reactions.push(reaction.status);
      });
      ret.forEach(function (user) {
        var reactions = [];
        types.forEach(function (reactionType) {
          if (user.reactions.indexOf(reactionType) >= 0) {
            reactions.push(reactionType);
          }
        });
        user.reactions = reactions;
      });
      return ret.sortBy('user_name');
    }),

    _addComments: function _addComments(comments, users, byUserId) {
      var _this = this;

      comments.forEach(function (comment) {
        var user = byUserId[comment.user.id];
        if (!user) {
          user = {
            user_id: comment.user.id,
            user_name: comment.user.name,
            profile_image_url: comment.user.profile_image_url,
            reactions: ['comment']
          };
          users.push(user);
          byUserId[user.user_id] = user;
        } else if (user.reactions.indexOf('comment') < 0) {
          user.reactions.push('comment');
        }
        if (comment.comments) {
          _this._addComments(comment.comments, users, byUserId);
        }
      });
    },


    filteredReactions: Ember.computed('selectedTab', 'userReactions', function () {
      var status = this.get('selectedTab');
      if (status == 'all') {
        return this.get('userReactions');
      }
      var ret = [];
      this.get('userReactions').forEach(function (userReaction) {
        if (userReaction.reactions.indexOf(status) >= 0) {
          ret.push(userReaction);
        }
      });
      return ret;
    })
  });
});