define('es-frontend/components/queued-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['queued-item', 'col'],
    widthService: Ember.inject.service('width'),

    social_queue: Ember.computed('queued_item', 'social_queues', function () {
      return this.get('social_queues').findBy('id', '' + this.get('queued_item.social_queue_id'));
    }),

    actions: {
      txtFocusOut: function txtFocusOut() {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (_this.get('queued_item.hasDirtyAttributes')) {
            var sharing = _this.get('queued_item').save();
            _this.set('sharing', sharing);
            sharing.then(function () {
              _this.get('toast').info('Post Updated');
              _this.set('sharing', null);
              resolve();
            }, reject);
          } else {
            resolve();
          }
        });
      },
      hiliteText: function hiliteText() {
        this.$('textarea').focus();
      },
      deleteRecord: function deleteRecord() {
        this.get('deleteRecord')(this.get('queued_item'));
      },
      shareNow: function shareNow() {
        var _this2 = this;

        var sharing = this.get('sharing');
        if (sharing) {
          sharing.then(function () {
            _this2.get('shareNow')(_this2.get('queued_item'));
          });
        } else {
          this.get('shareNow')(this.get('queued_item'));
        }
      }
    }
  });
});