define('es-frontend/controllers/signin/signup/user-details', ['exports', 'es-frontend/utils/crop-img'], function (exports, _cropImg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var IMG_SIZE = 256;

  exports.default = Ember.Controller.extend({

    ajaxService: Ember.inject.service('es-ajax'),
    nameValid: null,
    segment: Ember.inject.service('segment'),

    nameObserver: Ember.observer('model.user.name', function () {
      this.setProperties({
        nameValid: this.get('nameValid') == null || !!this.get('model.user.name'),
        errorMsg: null
      });
    }),

    nameClass: Ember.computed('nameValid', function () {
      return 'input-container col' + (this.get('nameValid') ? '' : ' error');
    }),

    deriveTimezone: function deriveTimezone() {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        return 'Eastern Time (US & Canada)';
      }
    },


    actions: {
      saveImg: function saveImg(imgURL, img, left, top, width, height, scale) {
        var _this = this;

        var dataURL = (0, _cropImg.default)(img, left, top, width, height, scale, IMG_SIZE, IMG_SIZE);

        return new Ember.RSVP.Promise(function (resolve) {
          var userId = _this.get('model.user.id');
          _this.get('ajaxService').ajax({
            method: 'PUT',
            dataType: 'json',
            userPath: false,
            url: 'users/' + userId + '.json',
            data: {
              user: {
                signup_step: 2,
                verification_code: _this.get('model.user.verification_code'),
                profile_image: dataURL
              }
            }
          }).then(function () {
            resolve(dataURL);
          });
        });
      },
      saveDetails: function saveDetails() {
        var _this2 = this;

        var name = this.get('model.user.name');
        if (!name) {
          this.setProperties({
            errorMsg: 'Name must not be blank!',
            nameValid: false
          });
          return;
        }

        var userId = this.get('model.user.id');
        this.get('ajaxService').ajax({
          method: 'PUT',
          userPath: false,
          url: 'users/' + userId + '.json',
          data: {
            user: {
              signup_step: 2,
              verification_code: this.get('model.user.verification_code'),
              name: this.get('model.user.name'),
              position: this.get('model.user.position'),
              timezone: this.deriveTimezone() || this.get('model.user.timezone')
            }
          }
        }).then(function () {
          _this2.get('segment').track('signin_signup_user_details_success');
          _this2.transitionToRoute('signin.signup.user-tags');
        });
      }
    }
  });
});