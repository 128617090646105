define('es-frontend/routes/signin/signup/set-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    model: function model() {
      var _this = this;

      var customer = this.modelFor('signin');
      var user = this.modelFor('signin.signup');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('ajaxService').ajax({
          userPath: false,
          url: 'terms.json?customer_id=' + user.customer_id
        }).then(function (terms) {
          resolve({
            customer: customer,
            user: user,
            terms: terms
          });
        }, reject);
      });
    },
    activate: function activate() {
      Ember.run.later(function () {
        Ember.$('input.password').focus();
      });
    }
  });
});