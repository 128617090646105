define('es-frontend/controllers/signin/sso', ['exports', 'es-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({

    email: Ember.computed.alias('model.user_email'),
    emailValid: null,
    ajaxService: Ember.inject.service('es-ajax'),
    router: Ember.inject.service('router'),
    errorMsg: null,
    ssoErrorMsg: null,
    token: null,

    emailObserver: Ember.observer('email', function () {
      this.setProperties({
        emailValid: this.get('emailValid') == null || !!this.get('email'),
        errorMsg: null,
        passwordDisabled: true
      });
    }),

    tokenObserver: Ember.observer('token', function () {
      if (!this.get('token')) {
        this.set('ssoErrorMsg', null);
      }
    }),

    emailClass: Ember.computed('emailValid', function () {
      var ret = ['input-container', 'col'];
      if (!this.get('emailValid')) {
        ret.push('error');
      }
      return ret.join(' ');
    }),

    actions: {
      beginSSO: function beginSSO() {

        var email = this.get('email');
        if (!email) {
          this.setProperties({
            errorMsg: 'Email must not be blank!',
            emailValid: false
          });
          return;
        }

        var buffer = new Uint8Array(32);
        window.crypto.getRandomValues(buffer);
        // var token = btoa(String.fromCharCode.apply(null, buffer));
        var token = [].concat(_toConsumableArray(buffer)).map(function (x) {
          return x.toString(36);
        }).join('');
        this.set('token', token);

        var url = _environment.default.APP.restRootURL + 'begin_sso?email=' + encodeURIComponent(email) + '&token=' + token;
        window.open(url);
      },
      tryFinishSSO: function tryFinishSSO() {
        var _this = this;

        this.set('ssoErrorMsg', null);
        this.get('ajaxService').ajax({
          method: 'GET',
          userPath: false,
          url: 'session_from_token.json',
          data: {
            token: this.get('token')
          }
        }).then(function () {
          _this.set('token', null);
          _this.get('router').transitionTo('client');
        }, function (status) {
          _this.setProperties({
            ssoErrorMsg: status && status.payload ? status.payload : 'Unknown Error'
          });
        });
      }
    }

  });
});