define('es-frontend/models/user-notification', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    notification_type: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    message_body: _emberData.default.attr('string'),
    message_title: _emberData.default.attr('string'),
    referenced_article_id: _emberData.default.attr('number'),
    referenced_article_title: _emberData.default.attr('string'),
    user: _emberData.default.attr(),
    title: Ember.computed('notification_type', 'message_title', 'user.name', function () {
      return this.get(this.get('notification_type') == 'ARTICLE_COMMENT' ? 'user.name' : 'message_title');
    }),
    body: Ember.computed('notification_type', 'message_body', 'referenced_article_title', function () {
      if (this.get('notification_type') == 'ARTICLE_COMMENT') {
        return 'Tagged you in "' + this.get('referenced_article_title') + '"';
      } else {
        return this.get('message_body');
      }
    })
  });
});