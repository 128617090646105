define('es-frontend/instance-initializers/segment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var router = appInstance.lookup('router:main');
    var segment = appInstance.lookup('service:segment');

    router.on('didTransition', function () {
      var hash = location.hash;
      var path = '/';
      var search = '';
      if (hash) {
        var index = hash.indexOf('?');
        path = hash.substring(1, index < 0 ? hash.length : index);
        search = index < 0 ? '' : hash.substring(index);
      }
      segment.page(router.get('currentRouteName').replace(/\./g, '_').replace(/-/g, '_'), {
        path: path,
        search: search
      });
    });

    router.on('didTransition', function () {
      //can get user from client model here if present
      //let applicationRoute = appInstance.lookup('route:application');
      //if (applicationRoute && typeof applicationRoute.identifyUser === 'function') {
      //  applicationRoute.identifyUser();
      //}
    });

    if (window.Intercom) {
      window.Intercom('onShow', function () {
        segment.track('intercom_show');
      });
    }
  }

  exports.default = {
    initialize: initialize
  };
});