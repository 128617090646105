define('es-frontend/routes/client/queue/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        social_queues: this.get('store').findAll('social-queue'),
        current_user: this.modelFor('client').get('user')
      });
    }
  });
});