define('es-frontend/components/top-bar', ['exports', 'es-frontend/utils/is-ios-app'], function (exports, _isIosApp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['top-bar', 'col'],
    taskService: Ember.inject.service('tasks'),
    widthService: Ember.inject.service('width'),
    queryService: Ember.inject.service('query'),
    ajaxService: Ember.inject.service('es-ajax'),
    router: Ember.inject.service('router'),
    segment: Ember.inject.service('segment'),
    menuOpen: false,
    profileMenuOpen: false,
    photoSharePopupOpen: false,
    vidyardPopupOpen: false,
    notificationsPopupOpen: false,
    statusPopupOpen: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.get('router.currentRouteName'); // hack - observer does not work unless this is done here...
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var pubnub = this.get('pubnub');
      if (pubnub) {
        var listener = {
          message: function message(_message) {
            var msg = _message.message;
            if (msg.notification_type == 'ARTICLE_COMMENT') {
              _this.incrementProperty('stats.notifications_count');
              _this.get('toast').info(msg.user.name + ' Tagged You in a comment on : ' + msg.referenced_article_title);
            }
          }
        };
        this.set('listener', listener);
        pubnub.addListener(listener);
      }
    },
    willDestroyElement: function willDestroyElement() {
      var pubnub = this.get('pubnub');
      if (pubnub) {
        pubnub.removeListener(this.get('listener'));
      }
    },
    didRender: function didRender() {
      this.$('.customer-icon').off('error', this._imgError).on('error', this._imgError);
    },
    _imgError: function _imgError() {
      var $img = Ember.$(this);
      $img.parent().html('<h1>' + this.title + '</h1>');
    },


    routerObserver: Ember.observer('router.currentRouteName', function () {
      var routeName = this.get('router.currentRouteName');
      if (!routeName.startsWith('client.streams')) {
        this.set('queryService.q', '');
      }
    }),

    isIOSApp: Ember.computed(function () {
      return (0, _isIosApp.default)();
    }),

    actions: {
      logout: function logout() {
        this.get('segment').reset();
        location.href = this.get('ajaxService.restRootPath') + 'logout';
      }
    }
  });
});