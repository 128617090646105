define("es-frontend/utils/flatten-nested", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = flattenNested;
  function flattenNested(array, nestAttr, ret) {
    if (!array) {
      return ret;
    }
    for (var i = 0; i < array.length; i++) {
      var obj = array[i];
      ret.push(obj);
      flattenNested(obj[nestAttr], nestAttr, ret);
    }

    return ret;
  }
});