define('es-frontend/components/single-article', ['exports', 'es-frontend/utils/reaction-types', 'es-frontend/utils/my-reactions', 'moment', 'es-frontend/utils/constants'], function (exports, _reactionTypes, _myReactions, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SERVER_DATE = _constants.default.SERVER_DATE;
  exports.default = Ember.Component.extend({
    classNames: ['single-article', 'col', 'bg1'],
    router: Ember.inject.service('router'),
    widthService: Ember.inject.service('width'),
    myReactions: (0, _myReactions.default)('article.reactions'),
    reactionService: Ember.inject.service('reaction'),
    embedService: Ember.inject.service('embed'),
    ajaxService: Ember.inject.service('es-ajax'),
    segment: Ember.inject.service('segment'),
    compressed: true,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var article = this.get('article');
      if (article && article.embedded) {
        var embedService = this.get('embedService');
        embedService.embed(article.url, true).then(function (embed) {
          embed.embedCode(_this.$('.embed'));
        });
      }
    },
    didRender: function didRender() {
      var _this2 = this;

      this.$('.img-link img').on('error', function () {
        if (!(_this2.destroying || _this2.destroyed)) {
          var img = _this2.$('.img-link');
          if (img) {
            img.remove();
          }
        }
      });
    },


    reactionSummary: Ember.computed('article.reaction_summary', function () {
      var summary = this.get('article.reaction_summary');
      var ret = [];
      if (!summary) {
        return ret;
      }
      for (var r = _reactionTypes.default.length; r-- > 0;) {
        // backwards due to CSS
        var reactionType = _reactionTypes.default[r];
        var count = summary[reactionType];
        if (count) {
          ret.push({
            status: reactionType,
            count: count
          });
        }
      }
      return ret;
    }),

    reactionUser: Ember.computed('article.{reactions,shared_by,comments}', function () {
      var ret = null;
      var userId = this.get('user.id');
      this.get('article.reactions').forEach(function (user) {
        if (!ret || user.user_id == userId) {
          ret = {
            id: user.user_id,
            name: user.user_name,
            profile_image_url: user.user_profile_image_url
          };
        }
      });
      this.get('article.shared_by').forEach(function (user) {
        if (!ret || user.id == userId) {
          ret = user;
        }
      });
      this.get('article.comments').forEach(function (comment) {
        var user = comment.user;
        if (!ret || user.id == userId) {
          ret = user;
        }
      });
      return ret;
    }),

    numOthers: Ember.computed('article.{reactions,shared_by}', function () {
      var users = {};
      this.get('article.reactions').forEach(function (user) {
        users[user.user_id] = user;
      });
      this.get('article.shared_by').forEach(function (user) {
        users[user.id] = user;
      });
      this.get('article.comments').forEach(function (comment) {
        var user = comment.user;
        users[user.id] = user;
      });
      var numOthers = Object.keys(users).length - 1;
      return numOthers;
    }),

    title: Ember.computed('article.title', function () {
      var title = this.get('article.title');
      if (this.get('article.embedded')) {
        var index;
        if ((index = title.indexOf(' on Instagram: ')) > 0) {
          title = title.substring(0, index) + ' on Instagram';
        } else if ((index = title.indexOf(' on LinkedIn: ')) > 0) {
          title = title.substring(0, index) + ' on LinkedIn';
        }
      }
      return title;
    }),

    summary: Ember.computed('article.summary', function () {
      var summary = this.get('article.summary');
      if (!summary || summary.endsWith('...')) {
        return summary;
      }
      return summary + '...';
    }),

    defaultMeta: Ember.computed('article', function () {
      var imgs = [];
      var img = this.$('.article-img')[0];
      if (img) {
        imgs.push({
          src: img.src,
          width: img.naturalWidth,
          height: img.naturalHeight
        });
      } else if (this.get('article.embedded') && this.get('article.large_image_url')) {
        imgs.push({
          src: this.get('article.large_image_url')
        });
      }
      return {
        title: this.get('article.title'),
        description: this.get('article.summary'),
        imgs: imgs
      };
    }),

    url: Ember.computed('article', function () {
      return this.get('article.type') == 'CuratedArticle' ? this.get('article.url') : null;
    }),

    shareUrl: Ember.computed('article', function () {
      switch (this.get('article.type')) {
        case 'CuratedArticle':
          return this.get('article.url');
        case 'ImagePost':
          var url = this.get('article.large_image_url');
          if (url) {
            url = url.replace('/large/', '/original/');
          }
          return url;
        default:
          return null;
      }
    }),

    showPermalink: function showPermalink() {
      var _this3 = this;

      this.get('segment').track('Permalink', {
        user_email: this.get('user.email'),
        action: 'Permalink',
        userStream: false,
        source: this.get('article.source'),
        contentId: this.get('article.id'),
        contentTitle: this.get('article.title'),
        contentUrl: this.get('article.url'),
        isEmbed: this.get('article.embedded'),
        isBranded: true, // all these are branded content
        stream: this.get('article.content_stream_id')
      });
      this.set('showLink', true);
      Ember.run.later(function () {
        try {
          // this.$() does not work because popup
          Ember.$('.permalink').focus()[0].select();
          if (document.execCommand('Copy')) {
            _this3.get('toast').info('Link Copied to Clipboard');
          }
        } catch (e) {
          //If the copy failed it just means the user has to do it for themselves - the text is still visible
        }
      }, 200);
    },


    _shareObserver: Ember.observer('showShare', function () {
      if (this.get('showShare')) {
        this.get('segment').track(this.get('article.embedded') ? 'clicked_to_engage' : 'clicked_to_share', {
          source: this.get('article.source'),
          article_id: this.get('article.id'),
          article_title: this.get('article.title'),
          article_url: this.get('article.url'),
          embedded: this.get('article.embedded'),
          branded: true, // all these are branded content
          stream: this.get('article.content_stream_id')
        });
      }
    }),

    actions: {
      permalink: function permalink() {
        var _this4 = this;

        if (this.get('permalink')) {
          this.showPermalink();
          return;
        }
        this.get('ajaxService').ajax({
          method: 'POST',
          url: '/other_shares',
          contentType: 'application/json',
          data: JSON.stringify({
            image_url: this.get('artile.large_image_url'),
            large_image_url: this.get('artile.large_image_url'),
            post_at: (0, _moment.default)().add(1, 'hours').format(SERVER_DATE),
            url: this.get('article.url'),
            title: this.get('artile.title'),
            summary: this.get('article.summary'),
            network: "permalink"
          })
        }).then(function (post) {
          _this4.set('permalink', post.url);
          _this4.showPermalink();
        });
      },
      addReaction: function addReaction(reactionType) {
        var _this5 = this;

        this.get('reactionService').addReaction('CuratedArticle', this.get('article.id'), reactionType).then(function (reaction) {
          var reactions = [];
          var reaction_summary = Ember.$.extend({}, _this5.get('article.reaction_summary'));
          (_this5.get('article.reactions') || []).forEach(function (r) {
            if (r.user_id == reaction.user_id) {
              reaction_summary[r.status]--;
            } else {
              reactions.push(r);
            }
          });
          reactions.push(reaction);
          _this5.set('article.reactions', reactions);
          reaction_summary[reaction.status]++;
          _this5.set('article.reaction_summary', reaction_summary);
          _this5.get('segment').track('add_reaction', {
            reaction_type: reactionType,
            article_id: _this5.get('article.id')
          });
        });
      },
      removeReaction: function removeReaction(reaction) {
        var _this6 = this;

        this.get('reactionService').removeReaction('CuratedArticle', this.get('article.id'), reaction.id).then(function () {
          var reactions = _this6.get('article.reactions').slice();
          reactions.splice(reactions.findIndex(function (obj) {
            return obj.id == reaction.id;
          }), 1);
          _this6.set('article.reactions', reactions);
          var reaction_summary = Ember.$.extend({}, _this6.get('article.reaction_summary'));
          reaction_summary[reaction.status]--;
          _this6.set('article.reaction_summary', reaction_summary);
        });
      },
      beginShare: function beginShare() {
        if (this.get('widthService.gtSmall')) {
          this.set('showShare', true);
          return;
        }
        if (this.get('article.type') == 'CuratedArticle') {
          this.get('router').transitionTo('client.share', { queryParams: { curated_article_id: this.get('article.id'), url: this.get('article.url') } });
        } else {
          this.get('router').transitionTo('client.share', { queryParams: { url: this.get('article.url') } });
        }
      },
      onShare: function onShare() {
        this.set('article.shared', true);
      },
      linkClicked: function linkClicked() {
        this.get('segment').track('click', {
          url: this.get('article.url'),
          article_id: this.get('article.id')
        });
      }
    }
  });
});