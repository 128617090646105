define('es-frontend/routes/error-msg', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    errorMsg: Ember.inject.service('error-msg'),

    model: function model() {
      return this.get('errorMsg');
    },
    beforeModel: function beforeModel() {
      if (!this.get('errorMsg.msg')) {
        this.get('router').transitionTo('application'); // no error message - go back to root
      }
    }
  });
});