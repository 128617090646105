define('es-frontend/components/status-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    statusParser: Ember.inject.service('status-parser'),
    segment: Ember.inject.service('segment'),
    position: 'bottom',
    anchor: null,
    open: false,
    status: null,

    openObserver: Ember.observer('open', function () {
      var _this = this;

      Ember.run.later(function () {
        var $text = Ember.$('#' + _this.elementId + 'text');
        $text.focus();
        _this.actions.onKey.call(_this, { currentTarget: $text[0] });
        _this.get('segment').track(_this.get('open') ? 'begin_user_created_share' : 'cancel_share');
      }, 400);
    }),

    actions: {
      parseStatus: function parseStatus() {
        var _this2 = this;

        this.get('statusParser').parse(this.get('text') || '').then(function (status) {

          var resolve = function () {
            this.setProperties({
              status: status,
              open: false,
              sharePopupOpen: true
            });
          }.bind(_this2);

          if (status.url) {
            resolve();
          } else {
            _this2.get('store').findAll('social_queue').then(function (social_queues) {
              if (social_queues.get('length')) {
                resolve();
              } else {
                _this2.get('toast').error('No URL found in content.');
              }
            });
          }
        }, function (error) {
          throw error;
        });
      },
      onShare: function onShare() {
        this.setProperties({
          status: null,
          text: null
        });
      },
      onKey: function onKey(event) {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        var $text = this.$(event.currentTarget);
        $text.height('auto');
        $text.height(Math.min($text[0].scrollHeight, 400));
      }
    }
  });
});