define('es-frontend/routes/client/streams/stream', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      q: {
        refreshModel: true,
        replace: false
      }
    },

    segment: Ember.inject.service('segment'),

    model: function model(params) {
      var parentModel = this.modelFor('client/streams');
      var selectedStream = parentModel.get('streams').find(function (obj) {
        return obj.id == params.id;
      });
      var company_default = parentModel.get('streams').find(function (obj) {
        return obj.route === "client.streams.stream";
      }) == selectedStream;
      document.title = (selectedStream ? selectedStream.title : 'Articles') + ' - EveryoneSocial';
      parentModel.set('selectedStream', selectedStream);
      parentModel.set('selectedStreamId', params.id);
      var controller = this.controllerFor('client/streams/stream');
      var clientModel = this.modelFor('client');
      this.get('segment').track('content_stream_viewed', {
        content_stream_id: params.id,
        company_default: company_default,
        company: true,
        third_party: false,
        personal: false
      });
      return controller.actions.initialLoad.call(controller, params.id, params.q, clientModel.user, clientModel.customer, clientModel.client, clientModel.features);
    }
  });
});