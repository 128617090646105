define('es-frontend/components/share-select', ['exports', 'es-frontend/utils/absolute-position-of', 'es-frontend/utils/sort-social-queues', 'ember-local-storage'], function (exports, _absolutePositionOf, _sortSocialQueues, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['share-select', 'col'],
    persistent: (0, _emberLocalStorage.storageFor)('share-select'),
    ajaxService: Ember.inject.service('es-ajax'),
    /* eslint-disable ember/avoid-leaking-state-in-ember-objects */
    social_queues: [],
    subscriptions: [],
    selectedSocialQueueMsgs: [],
    selectedSubscriptions: [],
    /* eslint-enable ember/avoid-leaking-state-in-ember-objects */
    facebook: false,
    xing: false,
    mutable: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.later(function () {
        if (_this.isDestroying || _this.isDestroyed) {
          return;
        }
        _this._social_queues_updated();
        _this._subscriptions_updated();
        //this.setProperties(this.get('persistent').getProperties('facebook') && this.get('allowFacebook'));
        if (!_this.get('allowXing')) {
          _this.set('xing', false);
        }
        if (!(_this.get('selectedSocialQueueMsgs.length') || _this.get('selectedSubscriptions.length') || _this.get('facebook') || _this.get('xing'))) {
          var selectedSocialQueueMsgs = [];
          var twitter = false;
          _this.get('social_queues').forEach(function (social_queue) {
            var service = social_queue.get('service');
            if (service == 'facebook') {
              _this.set('facebook', true);
              return;
            } else if (service == 'xing') {
              _this.set('xing', true);
              return;
            } else if (service == 'twitter') {
              if (twitter) {
                return;
              }
              twitter = true;
            }
            selectedSocialQueueMsgs.push(_this._socialQueueMsg(social_queue));
          });
          if (selectedSocialQueueMsgs.length) {
            (0, _sortSocialQueues.default)(selectedSocialQueueMsgs);
            _this.set('selectedSocialQueueMsgs', selectedSocialQueueMsgs);
          } else {
            _this.set('facebook', true);
            _this.set('xing', true);
          }
        }
      });
    },


    flagsObserver: Ember.observer('facebook', function () {
      this.get('persistent').setProperties(this.getProperties('facebook'));
    }),

    flagsXingObserver: Ember.observer('xing', function () {
      this.get('persistent').setProperties(this.getProperties('xing'));
    }),

    twitterMsgObserver: Ember.observer('twitterMsg', function () {
      var twitterMsg = this.get('twitterMsg');
      this.get('selectedSocialQueueMsgs').forEach(function (msg) {
        if (msg.service == 'twitter' && !msg.msg) {
          Ember.set(msg, 'msg', twitterMsg);
        }
      });
    }),

    _socialQueueMsg: function _socialQueueMsg(social_queue) {
      var msg = '';
      var maxLength = 1300 - (this.get('long_disclosure.length') || 0);
      var service = social_queue.get('service');
      if (service == 'twitter') {
        msg = this.get('twitterMsg');
        var shortDisclosureLength = this.get('short_disclosure.length');
        maxLength = 280 - 25 - (shortDisclosureLength ? shortDisclosureLength + 1 : 0);
      } else if (service == 'linkedin') {
        msg = this.get('linkedinMsg');
      } else if (service == 'xing') {
        msg = this.get('xingMsg');
      }
      return {
        social_queue: social_queue,
        service: service,
        maxLength: maxLength,
        msg: msg // TODO: Copy from existing?
      };
    },


    _socialQueuesObserver: Ember.observer('social_queues', function () {
      this._social_queues_updated();
    }),

    _social_queues_updated: function _social_queues_updated() {
      var _this2 = this;

      var social_queues = this.get('social_queues');
      var selectedSocialQueueMsgs = [];
      (this.get('persistent.selectedSocialQueueIds') || []).forEach(function (id) {
        var social_queue = social_queues.findBy('id', id);
        if (social_queue) {
          selectedSocialQueueMsgs.push(_this2._socialQueueMsg(social_queue));
        }
      });
      (0, _sortSocialQueues.default)(selectedSocialQueueMsgs);
      this.set('selectedSocialQueueMsgs', selectedSocialQueueMsgs);
    },


    _selectedSocialQueueMsgsObserver: Ember.observer('selectedSocialQueueMsgs', function () {
      var selectedSocialQueueIds = [];
      (this.get('selectedSocialQueueMsgs') || []).forEach(function (selectedSocialQueueMsg) {
        selectedSocialQueueIds.push(selectedSocialQueueMsg.social_queue.id);
      });
      this.set('persistent.selectedSocialQueueIds', selectedSocialQueueIds);
    }),

    subscriptionsObserver: Ember.observer('subscriptions', function () {
      this._subscriptions_updated();
    }),

    _subscriptions_updated: function _subscriptions_updated() {
      var selectedSubscriptions = [];
      var selectedSubscriptionIds = this.get('persistent.selectedSubscriptionIds') || [];
      (this.get('subscriptions') || []).forEach(function (subscription) {
        if (selectedSubscriptionIds.indexOf(subscription.id) >= 0) {
          selectedSubscriptions.push(subscription);
        }
      });
      this.set('selectedSubscriptions', selectedSubscriptions);
    },


    _selectedSubscriptionsObserver: Ember.observer('selectedSubscriptions', function () {
      var selectedSubscriptionIds = [];
      (this.get('selectedSubscriptions') || []).forEach(function (subscription) {
        selectedSubscriptionIds.push(subscription.id);
      });
      this.set('persistent.selectedSubscriptionIds', selectedSubscriptionIds);
    }),

    sortedSocialQueues: Ember.computed('social_queues', function () {
      var ret = this.get('social_queues').filter(function (social_queue) {
        var service = social_queue.get('service');
        return service != 'facebook' && service != 'xing';
      });
      (0, _sortSocialQueues.default)(ret);
      return ret;
    }),

    facebookQueue: Ember.computed('social_queues', function () {
      return this.get('social_queues').findBy('service', 'facebook');
    }),

    facebookQueueName: Ember.computed('facebookQueue', function () {
      return this.get('facebookQueue.name') || 'Facebook';
    }),

    facebookMaxLength: Ember.computed('long_disclosure', function () {
      var longDisclosureLength = this.get('long_disclosure.length');
      return 1300 - (longDisclosureLength ? longDisclosureLength + 1 : 0);
    }),

    xingMaxLength: Ember.computed('long_disclosure', function () {
      var longDisclosureLength = this.get('long_disclosure.length');
      return 1300 - (longDisclosureLength ? longDisclosureLength + 1 : 0);
    }),

    actions: {
      toggleSocialQueue: function toggleSocialQueue(social_queue) {
        if (social_queue.get('service') == 'facebook') {
          this.toggleProperty('facebook');
          return;
        } else if (social_queue.get('service') == 'xing') {
          this.toggleProperty('xing');
          return;
        }
        var selectedSocialQueueMsgs = this.get('selectedSocialQueueMsgs').slice();
        var index = selectedSocialQueueMsgs.findIndex(function (socialQueueMsg) {
          return socialQueueMsg.social_queue == social_queue;
        });
        if (index < 0) {
          if (social_queue.get('service') == 'twitter') {
            // prevent duplicate twitter
            var twitterIndex = selectedSocialQueueMsgs.findIndex(function (socialQueueMsg) {
              return socialQueueMsg.social_queue.get('service') == 'twitter';
            });
            if (twitterIndex >= 0) {
              selectedSocialQueueMsgs.splice(twitterIndex, 1);
            }
          }
          selectedSocialQueueMsgs.push(this._socialQueueMsg(social_queue));
          (0, _sortSocialQueues.default)(selectedSocialQueueMsgs);
        } else {
          selectedSocialQueueMsgs.splice(index, 1);
        }
        this.setProperties({
          selectedSocialQueueMsgs: selectedSocialQueueMsgs,
          popupOpen: false
        });
      },
      toggleSubscription: function toggleSubscription(subscription) {
        var selectedSubscriptions = this.get('selectedSubscriptions').slice();
        var index = selectedSubscriptions.indexOf(subscription);
        if (index < 0) {
          selectedSubscriptions.push(subscription);
        } else {
          selectedSubscriptions.splice(index, 1);
        }
        this.setProperties({
          selectedSubscriptions: selectedSubscriptions,
          popupOpen: false
        });
      },
      layoutMoreOptions: function layoutMoreOptions(popupElement) {
        if (!popupElement || this.isDestroying || this.isDestroyed) {
          return;
        }
        var anchor = Ember.$('#' + this.elementId + 'MoreOptionsBtn');
        var position = (0, _absolutePositionOf.default)(anchor);
        popupElement.css({
          left: position.left + anchor.outerWidth() - popupElement.outerWidth(),
          top: position.top
        });
      },
      layoutOutputPopup: function layoutOutputPopup(popupElement) {
        var popupWidth = 375;
        var popupHeight = 500;
        popupElement.css({
          width: popupWidth,
          maxHeight: popupHeight
        });
        popupHeight = popupElement.outerHeight();
        var anchor = Ember.$('#' + this.elementId + 'OutputBtn');
        var position = (0, _absolutePositionOf.default)(anchor);
        if (window.innerHeight - position.top - popupHeight > 0) {
          // below
          popupElement.css({
            position: 'fixed',
            left: position.left,
            top: position.top,
            transform: ''
          });
          return;
        } else if (position.left + popupWidth < window.innerWidth) {
          //Above or below?
          if (position.top > popupHeight) {
            // above
            popupElement.css({
              position: 'fixed',
              left: position.left,
              top: position.top - popupHeight,
              transform: ''
            });
            return;
          }
        }
        popupElement.css({
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)'
        });
      },
      shareToEmail: function shareToEmail() {
        this.set('moreOptionsOpen', false);
        this.shareToEmail();
      },
      twitterUserSearch: function twitterUserSearch(socialQueueMsg, query) {
        var _this3 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (socialQueueMsg.social_queue.get('service') !== 'twitter') {
            return resolve([]);
          }
          _this3.get('ajaxService').ajax({
            url: '/twitter/user_search',
            data: {
              handle: socialQueueMsg.social_queue.get('nickname'),
              q: query
            }
          }).then(function (results) {
            resolve(results.users.map(function (user) {
              return user.screen_name;
            }));
          }, reject);
        });
      }
    }
  });
});