define('es-frontend/routes/client/leaderboard', ['exports', 'es-frontend/utils/constants', 'moment'], function (exports, _constants, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      leaderboardId: {
        refreshModel: false,
        replace: false
      },
      start: {
        refreshModel: false,
        replace: true
      },
      end: {
        refreshModel: false,
        replace: true
      },
      tab: {
        refreshModel: false,
        replace: true
      },
      query: {
        refreshModel: false,
        replace: true
      }
    },

    model: function model() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('store').findAll('leaderboard').then(function (leaderboards) {
          resolve(Ember.Object.create({
            leaderboards: leaderboards,
            user: _this.modelFor('client').get('user')
          }));
        }), reject;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (!controller.get('start')) {
        controller.set('start', (0, _moment.default)().add(-30, 'day').format(_constants.default.VISIBLE_DATE));
      }
      if (!controller.get('end')) {
        controller.set('end', (0, _moment.default)().format(_constants.default.VISIBLE_DATE));
      }
      var leaderboards = model.get('leaderboards');
      var selectedLeaderboard = leaderboards.findBy('id', controller.get('leaderboardId'));
      controller.setProperties({
        id: selectedLeaderboard ? selectedLeaderboard.id : null,
        selectedLeaderboard: selectedLeaderboard
      });
      this.controllerFor('client.leaderboard').activate();
    },
    deactivate: function deactivate() {
      this.controllerFor('client.leaderboard').deactivate();
    }
  });
});