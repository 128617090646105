define('es-frontend/components/leaderboard-metrics-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function attrValueAt(index) {
    return function () {
      var scorecard = this.get('scorecard');
      var attrNames = this.get('attrNamesArray');
      if (!(scorecard && attrNames)) {
        return null;
      }
      var attrName = attrNames[index];
      return attrName ? scorecard[attrName] : null;
    };
  }

  exports.default = Ember.Component.extend({
    tagName: 'tr',
    unit: 'Pts',
    attrNames: 'shares,clicks,likes,reshares,comments',
    attrNamesArray: Ember.computed('attrNames', function () {
      return this.attrNames.split(',');
    }),
    scorecard: null,
    service_name: null,
    shares: Ember.computed('scorecard', 'pattern', attrValueAt(0)),
    clicks: Ember.computed('scorecard', 'pattern', attrValueAt(1)),
    likes: Ember.computed('scorecard', 'pattern', attrValueAt(2)),
    reshares: Ember.computed('scorecard', 'pattern', attrValueAt(3)),
    comments: Ember.computed('scorecard', 'pattern', attrValueAt(4))
  });
});