define('es-frontend/routes/client/notifications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajaxService: Ember.inject.service('es-ajax'),

    model: function model() {
      var _this = this;

      var ret = Ember.RSVP.hash({
        notifications: this.get('store').findAll('user-notification')
      });

      ret.then(function () {
        _this.get('ajaxService').ajax({
          url: '/mark_as_read.json',
          method: 'POST',
          data: {
            type: 'notifications'
          }
        }).then(function () {
          _this.set('notifications_count', 0);
        });
      });

      return ret;
    }
  });
});