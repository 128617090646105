define('es-frontend/models/schedule', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    mon: _emberData.default.attr('boolean'),
    tue: _emberData.default.attr('boolean'),
    wed: _emberData.default.attr('boolean'),
    thu: _emberData.default.attr('boolean'),
    fri: _emberData.default.attr('boolean'),
    sat: _emberData.default.attr('boolean'),
    sun: _emberData.default.attr('boolean'),
    post_times: _emberData.default.attr()
  });
});